/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

function AjoutEvenement({ getSizeMobille }) {
    const [data, setData] = useState({
        titre: "",
        partie: [{ soustitre: "", photo: "", descriptions: "", lien: "" }],
    });


    const handleAddpartie = () => {
        setData({
            ...data,
            partie: [...data.partie, { soustitre: "", photo: "", descriptions: "", lien: "" }],
        });
    };

    const handleRemovepartie = (index) => {
        if (data.partie.length > 1) {
            const updatedpartie = [...data.partie];
            updatedpartie.splice(index, 1);
            setData({ ...data, partie: updatedpartie });
        }
    };

    const handlepartieChange = (index, key, value) => {
        const updatedpartie = [...data.partie];
        updatedpartie[index][key] = value;
        setData({ ...data, partie: updatedpartie });
    };


    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("titre", data.titre);
        data.partie.forEach((sousEvent, index) => {
            formData.append(`partie[${index}][soustitre]`, sousEvent.soustitre);
            formData.append(`partie[${index}][descriptions]`, sousEvent.descriptions);
            formData.append(`partie[${index}][lien]`, sousEvent.lien);
            if (sousEvent.photo !== "") {
                formData.append(`partie[${index}][photo]`, sousEvent.photo);
                formData.append(`partie[${index}][photoindex]`, index);
            }
        });

        // Ajout de l'en-tête Content-Type pour indiquer la présence de fichiers
        axios.post("https://irakoze.burundientempsreel.com/api/addevent", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log("Réponse du serveur :", response.data);
                toast.success("Événement enregistré avec succès !");
                setData({
                    titre: "",
                    partie: [{ soustitre: "", photo: "", descriptions: "", lien: "" }],
                });
            })
            .catch(error => {
                console.error("Erreur lors de l'envoi des données d'événement :", error);
                if (error.response && error.response.data) {
                    toast.error(error.response.data);
                } else {
                    toast.error("Une erreur est survenue lors de l'enregistrement de l'événement. Veuillez réessayer.");
                }
            });
    };




    return (
        <div className={`overflow-hidden`}>
            <div className="sm:p-4 p-2 min-h-screen">
                <div className="rounded-b-lg flex items-center h-10  w-full  justify-between sm:px-5 border-b border-gray-300">
                    <div className="max-sm:text-[10px]">Ajouter les événements</div>
                    <Link to="/AdminEvent/Evenement" className="bg-orange-500 max-sm:text-[10px] text-[17px] text-white hover:text-white focus:text-white rounded h-max w-max px-2 py-1">
                        Gestion des événements
                    </Link>
                </div>
                <div className="w-full my-4">
                    <div className="flex justify-start items-center flex-col">
                        <div className="md:text-3xl sm:p-4">Enregistrement des événements</div>
                        <div className={`w-full`}>
                            <div className="sm:p-4 p-2 min-h-screen">
                                <div className="rounded-lg p-2 border border-blue-500  w-[99%] ">
                                    <div className="w-full my-4 grid grid-cols-1">
                                        <input
                                            type="text"
                                            placeholder="Titre de l evenement"
                                            className="appearance-none bg-transparent outline-none focus:border focus:border-fuchsia-600 block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                            value={data.titre}
                                            onChange={(e) => setData({ ...data, titre: e.target.value })}

                                        />
                                        {data.partie.map((sousEvent, index) => (
                                            <div key={index} className="w-full  my-4 ">
                                                <form className="flex flex-col items-center">
                                                    <div className={`rounded-lg p-2 border border-blue-500 w-full sm:p-4`}>
                                                        <input
                                                            type="text"
                                                            placeholder="Sous-titre"
                                                            className="appearance-none bg-transparent outline-none focus:border focus:border-fuchsia-600 block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                            value={sousEvent.soustitre}
                                                            onChange={(e) => handlepartieChange(index, "soustitre", e.target.value)}

                                                        />
                                                        <div className="">
                                                            <label
                                                                className="appearance-none block w-full cursor-pointer bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                                htmlFor={`photo_${index}`}
                                                            >
                                                                photo de l'événement
                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                hidden
                                                                onChange={(e) => handlepartieChange(index, "photo", e.target.files[0])}
                                                                id={`photo_${index}`}
                                                            />
                                                        </div>
                                                        {data.partie[index].photo && (
                                                            <img
                                                                src={URL.createObjectURL(data.partie[index].photo)}
                                                                alt="Image"
                                                                className="sm:w-[30em] object-contain sm:h-[30em] mb-2"
                                                            />
                                                        )}
                                                        <input
                                                            type="text"
                                                            placeholder="Lien (YouTube uniquement)"
                                                            className="appearance-none outline-none focus:border focus:border-fuchsia-600 block bg-transparent w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                            value={sousEvent.lien}
                                                            onChange={(e) => handlepartieChange(index, "lien", e.target.value)}

                                                        />
                                                        <textarea
                                                            className="appearance-none block h-[7em] resize-none bg-transparent overflow-hidden outline-none focus:border focus:border-fuchsia-600 w-full bg-grey-lighter text-grey-darker border border-gray-400 rounded  p-2 mb-1"
                                                            value={sousEvent.descriptions}
                                                            onChange={(e) => handlepartieChange(index, "descriptions", e.target.value)}

                                                            placeholder="Déscription de l'événement..."

                                                        >
                                                        </textarea>
                                                        {index > 0 && (
                                                            <button
                                                                onClick={() => handleRemovepartie(index)}
                                                                className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                                                            >
                                                                Supprimer ce sous-événement
                                                            </button>
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        ))}

                                    </div>
                                    <div className="flex justify-between flex-wrap my-4">
                                        <button
                                            onClick={handleAddpartie}
                                            className="bg-blue-500 mt-3 text-white py-2 px-4 rounded hover:bg-blue-600"
                                        >
                                            Ajouter un sous-événement
                                        </button>
                                        <button
                                            onClick={handleSubmit}
                                            className="bg-green-500 mt-3 text-white py-2 px-4 rounded hover:bg-green-600"
                                        >
                                            Enregistrer l'événement
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AjoutEvenement;
