/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../Loading/Loading';
import { formatDate, chiffrement } from '../../../module';
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player';
import EmpyServices from '../EmpyServeices/EmpyServeices';
function Resource() {
    const [resource, setresource] = useState([]);
    const [isPending, setPending] = useState(true)


    useEffect(() => {
        axios
            .get('https://irakoze.burundientempsreel.com/api/getlastresource')
            .then((response) => {
                setresource(response.data);
                setTimeout(() => {
                    setPending(false)
                }, 100)
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);




    const [mobile1, setMobille1] = useState(window.innerWidth < 1178);
    const [mobile2, setMobille2] = useState(window.innerWidth < 627);
    const [mobile3, setMobille3] = useState(window.innerWidth < 443);
    const [mobile4, setMobille4] = useState(window.innerWidth < 504);
    useEffect(() => {
        const hundleSize = () => {
            setMobille1(window.innerWidth < 1178)
            setMobille2(window.innerWidth < 627)
            setMobille3(window.innerWidth < 443)
            setMobille4(window.innerWidth < 504)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    })

    const [loadingImages, setLoadingImages] = useState([]);

    const handleImageLoad = (index) => {

        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            updatedLoadingImages[index] = true;
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 50000);
            return updatedLoadingImages;
        });

    };



    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };


    return (
        <>
            <div className="  w-[97%]  bg-gray-50 rounded-lg  flex flex-col mt-[20px]  justify-center items-center ">
                {isPending && <Loading />}
                <h2 className='text-[35px] font-serif text-gray-700 px-4 p-1 my-2 rounded '>Resource</h2>
                <div className='h-[1px] mb-1  bg-white w-full'></div>
                <div className={`flex flex-wrap justify-left  gap-2 p-2`}>
                    {resource.length > 0 ? (
                        resource.map((res, index) => {
                            return (
                                <div className={`   bg-[#e4e4e4] border overflow-hidden ${mobile4 ? 'flex-auto' : ''} ${mobile2 ? 'w-[16em]' : 'w-[20em]'}  rounded-2xl bg-white     p-1`}>
                                    <div className='w-full flex justify-center '>
                                        <div className={`m-[2px] bg-white overflow-hidden ${mobile4 ? 'w-full min-h-[15em]' : ''} ${mobile2 ? 'h-[13em] w-[13em]' : mobile1 ? 'h-[18em] w-[18em]' : 'h-[18em] w-[18em]'}  relative rounded overflow-hidden `}>
                                            {res.file && (() => {
                                                const extension = res.file.split('.').pop();
                                                if (extension === 'pdf') {

                                                    return (
                                                        <div>PDF introuvable</div>
                                                    );
                                                } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                                                    return (

                                                        <>
                                                            <div key={res.id} className='relative border h-full w-full '>
                                                                <ReactPlayer
                                                                    url={`https://irakoze.burundientempsreel.com/uploads/resources/${res.file}`}
                                                                    controls
                                                                    className=" inline object-cover object-center w-full h-full"

                                                                />
                                                            </div>
                                                        </>


                                                    );
                                                } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                                                    return (
                                                        <div key={res.id} className='relative  h-full w-full '>
                                                            {loadingImages[index] ? (
                                                                <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                            ) :
                                                                (
                                                                    <img
                                                                        src={`https://irakoze.burundientempsreel.com/uploads/resources/${res.file}`}
                                                                        className="w-full h-full border-none object-cover"


                                                                    />

                                                                )}

                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })()}
                                        </div>
                                    </div>

                                    <div className="w-full mt-1 flex items-center flex-col p-3">
                                        <div className='font-semibold  w-full text-[20px] text-center'>{res.titre}</div>
                                        <span className='font-sans text-[13px] w-full text-orange-700'>Le {formatDate(res.createdAt)}</span>
                                        <div className={`min-h-[5em] w-full`}>
                                            <div className='w-full  text-left'>
                                                {res.description && res.description.length > 100
                                                    ? res.description.slice(0, 90) + '...'
                                                    : res.description}
                                            </div>

                                        </div>
                                        <div className={` ${mobile3 ? 'w-[80%] text-[14px]' : ' w-full text-[16px]'} h-max  flex justify-start`}>
                                            <Link to={`/Visit/Evenement/Detail/${chiffrement(((res.id).toString()))}`} className=' transition-all   p-2  mt-2 rounded'>En savoir plus</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                    ) : (<>
                        <div className='flex  flex-col items-center w-[50em] border border-orange-600 p-3 rounded-lg bg-slate-100 h-[50vh] widthsssssssss'>
                            <EmpyServices items="d'événement" />
                            <div className='text-[18px] font-sans text-gray-700'>
                                Les élèves impliqués dans l'organisation d'événements développent des compétences importantes telles que la gestion de projet, la communication, le travail d'équipe, la prise de décision et la résolution de problèmes.
                                <br /><br />
                                Les événements ajoutent de la diversité à l'expérience éducative en offrant aux élèves des opportunités d'apprentissage en dehors de la salle de classe. Ils complètent le programme académique et encouragent la curiosité intellectuelle.

                            </div>
                        </div>
                    </>)}
                </div>
                <Link to='/Visit/Ressource' className={`bg-orange-400 ${mobile3 ? 'w-[80%] text-[15px]' : 'w-[20em] text-[20px]'}  mb-4  py-2  text-center rounded-3xl cursor-pointer text-white  hover:border-orange-600 hover:bg-white transition-all hover:text-gray-700 border border-white ${resource.length <= 0 ? 'pointer-events-none opacity-60' : 'pointer-events-auto opacity-100'}`}>Plus d'événements</Link>
            </div>

        </>
    );
}

export default Resource;
