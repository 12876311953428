import React from 'react'
import EvenementComponent from '../../../component/Visitor/Evenement/EvenementComponent'
function EvenemntVisitorPage() {


    return (
        <EvenementComponent />
    )
}

export default EvenemntVisitorPage
