import React from 'react'
import DetailEventAdmin from './DetailEventAdmin'
function DetailEventAdminComponent() {
    return (
        <>
            <DetailEventAdmin />
        </>
    )
}

export default DetailEventAdminComponent
