/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-lone-blocks */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loading from '../../Loading/Loading';
import { toast } from 'react-toastify';
import { chiffrement, formatDate } from '../../../module';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types'
import EmpyServices from '../EmpyServeices/EmpyServeices';
function Resource() {
    const [Resources, setResources] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [totalElements, setTotalElements] = useState(0)
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate("")
    useEffect(() => {
        fetchData();
    }, [currentPage, pageSize]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get("https://irakoze.burundientempsreel.com/api/getallresources", {
                params: {
                    status: 1,
                    search: searchQuery,
                    page: currentPage,
                    size: pageSize,
                },
            });
            setResources(response.data.Resources);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalElements);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error.message);
            setLoading(false);

        }
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchData();
    };
    const handleCancelSearch = async () => {
        setCurrentPage(1);

        try {
            setLoading(true);
            const response = await axios.get("https://irakoze.burundientempsreel.com/api/getallresources", {
                params: {
                    status: 1,
                    search: "",
                    page: 1,
                    size: pageSize,
                },
            });
            setResources(response.data.Resources);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalElements);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error.message);
            setLoading(false);
        }
    };




    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setCurrentPage(1);
    };


    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} resources sur ${totalElements}`;
    };



    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        setCurrentDate(formattedDate);
    }, []);




    const [mobile1, setMobille1] = useState(window.innerWidth < 1178);
    const [mobile2, setMobille2] = useState(window.innerWidth < 627);
    const [mobile3, setMobille3] = useState(window.innerWidth < 443);
    const [mobile4, setMobille4] = useState(window.innerWidth < 504);
    useEffect(() => {
        const hundleSize = () => {
            setMobille1(window.innerWidth < 1178)
            setMobille2(window.innerWidth < 627)
            setMobille3(window.innerWidth < 443)
            setMobille4(window.innerWidth < 504)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    })






    const [loadingImages, setLoadingImages] = useState([]);

    const handleImageLoad = (index) => {
        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            updatedLoadingImages[index] = true;
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 50000);
            return updatedLoadingImages;
        });

    };





    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };

    return (
        <>

            <div className="w-full flex  mt-1 justify-center flex-col items-center ">
                <div className='p-2 w-full min-h-[20em] '>
                    <div className="  w-full   rounded-lg  flex flex-col mt-[20px]  justify-center items-center ">
                        <div className="flex items-center flex-wrap mt-4 sm:mt-0">
                            <div className="flex items-center  overflow-hidden  border border-gray-300  rounded-l-md">
                                <input
                                    type="text"
                                    placeholder="Rechercher..."
                                    className="p-2 mr-4  bg-transparent rounded-md focus:outline-none"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <div className='mr-0.5'>
                                    {searchQuery.trim() !== "" && (
                                        <div onClick={() => { setSearchQuery(""); handleCancelSearch(); }} className="flex text-white items-center rounded-full bg-red-700 cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi w-5 h-5 bi-x" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                        </div>
                                    )}

                                </div>
                            </div>
                            <button
                                className="px-4 py-2 ml-3 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none"
                                onClick={handleSearch}
                            >
                                Rechercher
                            </button>
                            <select
                                className="p-2 border bg-transparent border-gray-300 rounded-md focus:outline-none ml-4"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                            >
                                <option className="text-black" value="5">5 par page</option>
                                <option className="text-black" value="10">10 par page</option>
                                <option className="text-black" value="20">20 par page</option>
                            </select>
                        </div>
                        <div className='h-[1px] mb-1  bg-gray-300 w-full'></div>

                        {Resources.length > 0 ? (
                            <div className={`flex flex-wrap  justify-center  gap-2 p-4`}>
                                {Resources.map((event, index) => {
                                    return (

                                        <div key={index} className={`   bg-[#e4e4e4] overflow-hidden ${mobile4 ? 'flex-auto' : ''} ${mobile2 ? 'w-[16em]' : 'w-[20em]'} border border-orange-700 rounded-2xl bg-white     p-1`}>
                                            <div className='font-semibold  w-full text-[20px] text-center'>{event.titre}</div>
                                            <div className='w-full flex justify-center '>
                                                <div className={`m-[2px] bg-white overflow-hidden ${mobile4 ? 'w-full min-h-[15em]' : ''} ${mobile2 ? 'h-[13em] w-[13em]' : mobile1 ? 'h-[18em] w-[18em]' : 'h-[18em] w-[18em]'} border border-orange-600 relative rounded overflow-hidden `}>
                                                    {event.file && (() => {
                                                        const extension = event.file.split('.').pop();
                                                        if (extension === 'pdf') {

                                                            return (
                                                                <div>PDF introuvable</div>
                                                            );
                                                        } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                                                            return (

                                                                <>
                                                                    <div key={event.id} className='relative border h-full w-full '>
                                                                        <ReactPlayer
                                                                            url={`https://irakoze.burundientempsreel.com/uploads/resources/${event.file}`}
                                                                            controls
                                                                            className=" inline object-cover object-center w-full h-full"

                                                                        />
                                                                    </div>
                                                                </>


                                                            );
                                                        } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                                                            return (
                                                                <div key={event.id} className='relative  h-full w-full '>

                                                                    <img
                                                                        src={`https://irakoze.burundientempsreel.com/uploads/resources/${event.file}`}
                                                                        className="w-full h-full border-none object-contain"


                                                                    />



                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })()}
                                                </div>
                                            </div>

                                            <div className="w-full mt-1 flex items-center flex-col p-3">
                                                <span className='font-sans text-[13px] w-full text-orange-700'>Le {formatDate(event.createdAt)}</span>
                                                <div className={`min-h-[5em] w-full`}>
                                                    <div className='w-full  text-left'>
                                                        {event.description && event.description.length > 100
                                                            ? event.description.slice(0, 90) + '...'
                                                            : event.description}
                                                    </div>

                                                </div>
                                                <div className={` ${mobile3 ? 'w-[80%] text-[14px]' : ' w-full text-[16px]'} h-max text-white  flex justify-center`}>
                                                    <Link to={`/Visit/Ressource/Detail/${chiffrement(((event.id).toString()))}`} className='bg-orange-500 transition-all hover:bg-orange-600 text-white p-2  mt-2 rounded'>En savoir plus</Link>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                })}
                            </div>
                        ) : (
                            <div className='flex  flex-col items-center w-[50em] border border-orange-600 p-3 rounded-lg bg-slate-100 h-[50vh] widthsssssssss'>
                                <EmpyServices items="de ressource" />
                            </div>
                        )
                        }
                        {Resources.length > 0 && (
                            <>
                                <div className="w-full flex justify-center items-center">
                                    <div className="w-[25em] h-full flex justify-around items-center p-2">
                                        <button onClick={previousPage} disabled={currentPage === 1} className="transition-all flex cursor-pointer hover:p-2 rounded">
                                            <i className="bi bi-arrow-left-circle-fill"></i>
                                            <span>Précédent</span>
                                        </button>
                                        <button onClick={nextPage} disabled={currentPage === totalPages} className="transition-all flex cursor-pointer hover:p-2 rounded">
                                            <span>Suivant</span>
                                            <i className="bi bi-arrow-right-circle-fill"></i>
                                        </button>
                                    </div>
                                </div>

                                <div className="mt-4 flex items-center justify-center">
                                    <p className="text-gray-600">{getRange()}</p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div >
        </>
    );
}
export default Resource
