/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Loading from '../../../Loading/Loading';
import { FadeLoader } from 'react-spinners';
function EditorAnnonces() {
    const history = useNavigate();
    const [spinnerButton, SetSpinnerButton] = useState(false)
    const { id } = useParams();
    const [isTitre, SetisTitre] = useState('')
    const [animationClassTitre, setanimationClassTitre] = useState('');
    const [animationClassProfil, setanimationClassProfil] = useState('');
    const [isTextarea, SetisTextarea] = useState('')
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const [autoScrollHeight, setautoScrollHeight] = useState(50);
    const [valueTextarea, setvalueTextarea] = useState('');
    const [file, setFile] = useState(null);
    const [date, Setdate] = useState('');
    const [newfile, setNewFile] = useState(null)
    const [pending, setPending] = useState(true);
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setNewFile(selectedFile);
    };

    useEffect(() => {
        axios
            .get(`https://irakoze.burundientempsreel.com/api/getonanonce/${id}`)
            .then((response) => {

                SetisTextarea(response.data.description);
                setFile(response.data.file)
                SetisTitre(response.data.titre)
                Setdate(response.data.date)
                setTimeout(() => {
                    setPending(false)
                }, 100)
            })
            .catch((error) => {
                console.log(error);
                setTimeout(() => {
                    setPending(false);
                }, 100);
            }).finally(() => {
                SetSpinnerButton(false);

            });;;
    }, [id]);




    const handleSubmit = (e) => {
        e.preventDefault();
        if (isTitre.trim() == '') {
            toast.warning("Titre de l'événement est obligatoire", {
                autoClose: 2000
            });
            setanimationClassTitre('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setanimationClassTitre(' ')
            }, 3000)
            return;
        }
        else if (isTextarea.trim() === '') {
            toast.warning("  La déscription de l'événement est obligatore", {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassTextarea(' ')
            }, 3000)
            return;
        }
        SetSpinnerButton(true);
        if (newfile) {
            const formData = new FormData();
            formData.append("titre", isTitre);
            formData.append("description", isTextarea);
            formData.append("file", newfile);
            formData.append("date", date);
            axios
                .put(`https://irakoze.burundientempsreel.com/api/updateannonce/${id}`, formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                .then((response) => {
                    toast.success(response.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    });

                    setNewFile(null);
                    SetisTextarea('');
                    SetisTitre('');
                    history('/AdminAnnonce/Annonce');
                })
                .catch((error) => {

                    console.error(error.message);
                }).finally(() => {
                    SetSpinnerButton(false);
                });;;
        } else {
            axios

                .put(`https://irakoze.burundientempsreel.com/api/updateannonce/${id}`,
                    {
                        titre: isTitre,
                        description: isTextarea,
                        date: date
                    })
                .then((response) => {
                    toast.success(response.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    });
                    SetisTextarea('');
                    SetisTitre('');
                    Setdate('');
                    history('/AdminAnnonce/Annonce');
                })
                .catch((error) => {

                    console.error(error.message);
                }).finally(() => {
                    SetSpinnerButton(false);
                });;;
        }

    }

    const [mobile, Setmobile] = useState(window.innerWidth < 344.383939302)

    useEffect(() => {

        const hundleSize = () => {
            Setmobile(window.innerWidth < 344.383939302)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    })

    const [loading, SetLoading] = useState(true)
    return (
        <div className='h-max flex justify-center flex-col items-center'>
            {pending && <Loading />}
            <div className='w-full px-5 rounded-b-lg flex items-center justify-between border-b border-gray-300 flex-row'>
                <Link to='/AdminAnnonce/Annonce' className='bg-orange-500 my-2 hover:text-white hover:no-underline text-white focus:text-white text-[12px] focus:no-underline sm:text-[17px] rounded h-max w-max px-2 py-1'>
                    Retour
                </Link>
                <Link to='/AdminAnnonce/AjouterAnnonce' className='bg-orange-500 my-2 hover:text-white hover:no-underline text-white focus:text-white text-[12px] focus:no-underline sm:text-[17px] rounded h-max w-max px-2 py-1'>
                    Ajouter un annonce
                </Link>
            </div>
            <div className=" border border-orange-600 rounded-2xl m-3 p-2  w-[95%] h-max wimax ">
                <h2 className=' p-2 text-center  text-[25px] text-orange-500'>Modifier Annonce {id}</h2>
                <form onSubmit={handleSubmit}>
                    <div className=" transition-all flex flex-col mb-6  p-1">
                        <div className="flex w-full justify-between responsivLogin my-3">
                            <b className='text-red-500 text-[30px] '>⋆</b>
                            <div class="relative  w-full flex flex-col mr-2 ">
                                <label class=" sm:text-[20px] ">
                                    Titre de l'annonce
                                </label>
                                <input value={isTitre} onInput={(e) => SetisTitre(e.target.value)} className={` border rounded-lg outline-none py-2 px-3 bg-transparent focus:border-orange-600 ${animationClassTitre}`} placeholder="Titre de l'annonce" />
                            </div>
                        </div>
                        <div className="relative top-6">
                            <div className="inline-flex items-center mr-4 text-[18px] text-gray-500">Profil:</div>
                            <label htmlFor="profils" className={` transition-all p-1 bg-orange-400 hover:bg-orange-600 rounded cursor-pointer text-white ${animationClassProfil}`}>Selection le profile</label>
                            <input type="file" id='profils' name='file' hidden accept=".mp4, .jpg, .jpeg, .png,.mov,.avi" onChange={handleFileChange} />
                            <div className={`animeBorder p-1 transition-all relative ${mobile ? 'left-1 border-2' : 'left-20 border-4'} top-4  rounded  border-dotted border-orange-500 overflow-hidden w-[200px]  h-[200px] `}>

                                {newfile ? (() => {
                                    const extension = newfile.name.split('.').pop().toLowerCase();
                                    const fileUrl = URL.createObjectURL(newfile);
                                    if (extension === 'mp4') {
                                        return (
                                            <div className="file-container relative overflow-hidden">
                                                {loading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                }
                                                <ReactPlayer

                                                    onReady={() => {
                                                        setTimeout(() => {
                                                            SetLoading(false)
                                                        }, 1000)
                                                    }}
                                                    url={fileUrl} controls width="100%" height="100%" className="video-player inline" />
                                            </div>
                                        )
                                    } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
                                        return (
                                            <>
                                                {loading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                }
                                                <img

                                                    onLoad={() => {
                                                        setTimeout(() => {
                                                            SetLoading(false)
                                                        }, 1000)
                                                    }}
                                                    src={fileUrl} alt="Image" className=' w-full h-full rounded object-cover' />;

                                            </>
                                        )
                                    }
                                })() : (() => {
                                    if (file) {
                                        const extension = file.split('.').pop();
                                        if (extension === 'pdf') {
                                            console.log("C'est un fichier PDF.");
                                        } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {
                                            {
                                                return (
                                                    <>
                                                        {loading && <div className="absolute w-full h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                        }
                                                        <ReactPlayer style={{ width: "100%", height: "100%" }}
                                                            onReady={() => {
                                                                setTimeout(() => {
                                                                    SetLoading(false)
                                                                }, 1000)
                                                            }}
                                                            controls
                                                            url={`https://irakoze.burundientempsreel.com/uploads/annonces/${file}`}
                                                        />
                                                    </>

                                                )
                                            }
                                        } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
                                            return (
                                                <>
                                                    {loading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                    }
                                                    <img
                                                        onLoad={() => {
                                                            setTimeout(() => {
                                                                SetLoading(false)
                                                            }, 1000)
                                                        }}
                                                        src={`https://irakoze.burundientempsreel.com/uploads/annonces/${file}`}
                                                        className="w-full h-full object-cover"
                                                    />;
                                                </>)
                                        }
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                    <div className="relative h-full flex my-14 flex-col w-full">
                        <label className={` sm:text-[19px]`}>
                            La déscription de l'annonce
                        </label>
                        <textarea
                            value={isTextarea}
                            onChange={(e) => {
                                SetisTextarea(e.target.value);
                            }}
                            placeholder="La déscription de l'annonce"
                            className={` border focus:border-orange-500 p-2 bg-transparent rounded outline-none resize-y min-h-[15em] w-full ${animationClassTextarea}`} />
                    </div>

                    <div className="relative h-full w-full">
                        <div className='flex items-center'>
                            <p className='text-red-500 text-[30px]'>⋆</p>
                            <div>Date d'annonce</div>
                        </div>
                        {new Date(date).toLocaleString("en-US", {
                            dateStyle: "short",
                            timeStyle: "short",
                        })}
                        <input type="date" className="border focus:border-orange-600 outline-none p-2 w-full bg-transparent rounded-lg" onChange={(e) => { Setdate(e.target.value); }} />
                    </div>
                    <div className="flex justify-end items-center mt-2">

                        {spinnerButton ? (
                            <>
                                <div className="flex justify-end items-center mt-4 relative pointer-events-none opacity-80">
                                    <div className='absolute bg-transparent  pt-3  w-full h-full flex justify-center items-center z-50'>
                                        <FadeLoader
                                            color="rgb(255, 255, 255)"
                                            height={10}
                                            margin={-9}
                                            radius={100}
                                            speedMultiplier={1}
                                            width={1}
                                        /></div>
                                    <input type="submit" id="send" value="Modifier" class=" transition-all bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600"></input>
                                </div>
                            </>
                        ) : (
                            <>
                                <label htmlFor='sende' className="w-max cursor-pointer  flex justify-end p-1 bg-orange-600 rounded  px-2 py-1 text-white">
                                    <input type="submit" className='pointer-events-none' id='sende' value="Modifier" />
                                    <i title="Modifier" class="bi bi-send ml-2 "></i>
                                </label>

                            </>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditorAnnonces


