import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Button } from 'rsuite';

function ComptUser() {
    const [filterValue, setFilterValue] = useState('');
    const [mobile2, Setmonile2] = useState(window.innerWidth < 1008.9)
    const handleFilterChange = (event) => {
        setFilterValue(event.target.value.toLowerCase());
    };
    const history = useNavigate("");
    const [searchTerm, setSearchTerm] = useState("");
    const [Utilisateurs, setUtilisateurs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalElements, setTotalElements] = useState(0)


    const [etat, setEtat] = useState(1)
    const handleEtatChange = (etat) => {
        setEtat(etat);
    };
    const [rechercher, setRechercher] = useState(false)
    const [isrechercher, setisRechercher] = useState(0)
    function handleSearch() {
        setRechercher(true)
        setisRechercher(isrechercher + 1)
    }



    useEffect(() => {
        fetchUtilisateurs(etat, currentPage, pageSize, searchTerm, rechercher);
    }, [currentPage, pageSize, etat, isrechercher]);

    const fetchUtilisateurs = async (etat, page, size, searchTerm, rechercher) => {
        try {
            let url = `/users/getAll?role=${etat}&page=${page}&pageSize=${size}&searchTerm=${searchTerm}`;
            const response = await axios.get(`https://irakoze.burundientempsreel.com/api/${url}`);
            setUtilisateurs(response.data.utilisateurs);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalElements);

        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs :', error.message);
        }
    };



    const handleDesactveUser = (id) => {
        Swal.fire({
            title: "Êtes-vous sûr ?",
            text: "Êtes-vous sûr de vouloir désactiver cet utilisateur ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
            reverseButtons: true,
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(`https://irakoze.burundientempsreel.com/api/Updateuser/${id}/${0}`)
                    .then(() => {
                        toast.success("utilisateur désactivé avec succès.");
                        setUtilisateurs((prevState) => prevState.filter((user) => user.id !== id));
                    })
                    .catch((error) => {
                        console.error(error.message);
                        toast.error("Une erreur est survenue lors de la suppression de l'utilisateur");
                    });
            }
        });
    };

    const handleActiveInspecteur = (id) => {
        Swal.fire({
            title: "Êtes-vous sûr ?",
            text: "Êtes-vous sûr de vouloir activer cet utilisateur en tant qu'inspecteur ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
            reverseButtons: true,
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(`https://irakoze.burundientempsreel.com/api/Updateuser/${id}/${2}`)
                    .then(() => {
                        toast.success("Utilisateur activé avec succès.");
                        setUtilisateurs((prevState) => prevState.filter((user) => user.id !== id));
                    })
                    .catch((error) => {
                        console.error(error.message);
                        toast.error("Une erreur est survenue lors de l'activation de l'utilisateur");
                    });
            }
        });
    };

    const handleActiveAdmin = (id) => {
        Swal.fire({
            title: "Êtes-vous sûr ?",
            text: "Êtes-vous sûr de vouloir activer cet utilisateur en tant qu'administrateur ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
            reverseButtons: true,
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(`https://irakoze.burundientempsreel.com/api/Updateuser/${id}/${1}`)
                    .then(() => {
                        toast.success("Utilisateur activé avec succès.");
                        setUtilisateurs((prevState) => prevState.filter((user) => user.id !== id));
                    })
                    .catch((error) => {
                        console.error(error.message);
                        toast.error("Une erreur est survenue lors de l'activation de l'utilisateur");
                    });
            }
        });
    };
    function handleDelete(id, position) {
        Swal.fire({
            title: 'Confirmation',
            text: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'swal-confirm-button',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`https://irakoze.burundientempsreel.com/api/deleteUser/${id}`)
                    .then((response) => {
                        if (response.status === 200) {
                            setUtilisateurs((prevState) => prevState.filter((user) => user.id !== id));
                            toast.success('L\'utilisateur a été supprimé avec succès');
                        } else {
                            toast.error("Une erreur s'est produite lors de la suppression de l'utilisateur");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error("Une erreur s'est produite lors de la suppression de l'utilisateur");
                    });
            }
        });
    }





    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} utilisateurs sur ${totalElements}`;
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCancelSearch = () => {
        // Code pour annuler la recherche
        setSearchTerm('');
        setRechercher(false);
        setisRechercher(isrechercher + 1);
    };


    const [mobile, GetMobile] = useState(window.innerWidth < 442)
    useEffect(() => {
        const hundleSize = () => {
            GetMobile(window.innerWidth < 442)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        };
    }, []);

    return (
        <div className='w-full min-h-[87vh]'>
            <div className='w-full px-5 rounded-b-lg flex items-center justify-between border-b border-gray-300 flex-row'>
                <h2 className="sm:text-lg max-sm:hidden font-semibold">Comptes des Utilisateurs</h2>
                <Link to='/' className='bg-orange-500 text-white hover:no-underline focus:no-underline hover:text-white focus:text-white my-2 text-[13px] sm:text-[17px] rounded h-max w-max px-2 py-1'>
                    Aller à la page d'accueil
                </Link>
            </div>
            <div className="w-full overflow-hidden">

                <div className=" flex  my-1 items-center">
                    <p className="sm:text-xl">
                        Role des Utilisateurs:
                    </p>
                    <select
                        value={etat}
                        onChange={(e) => handleEtatChange(Number(e.target.value))}
                        className="ml-2  border border-gray-300 p-2.5 cursor-pointer bg-transparent rounded"
                    >
                        <option className='text-black' value={1}>Admin</option>
                        <option className='text-black' value={2}>Inspecteur</option>
                        <option className='text-black' value={0}>Non Actifs</option>

                    </select>
                </div>


                <div class={`sm:flex items-center ${mobile2 ? 'w-full ' : ''}  justify-end`}>
                    <div class="sm:flex items-center justify-end">
                        <div class="mx-2 z-10">
                            <div class="relative flex items-center border rounded-md px-1 border-gray-300">
                                <div class="flex items-center pointer-events-none">
                                    <svg
                                        class="w-6 h-6 "
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    name="search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    id="topbar-search"
                                    class=" border-none outline-none bg-transparent  sm:text-lg rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full  p-2"
                                    placeholder="Rechercher ..."
                                />
                                {searchTerm.trim() != "" && <div onClick={handleCancelSearch} class=" flex text-white items-center rounded-full bg-red-700 cursor-pointer ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi w-5 h-5 bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                    </svg>
                                </div>
                                }
                            </div>
                        </div>
                        <button
                            className={`mt-4 sm:mt-0 ${searchTerm.trim() == "" ? "opacity-50 pointer-events-none" : 'opacity-100 pointer-events-auto cursor-pointer'} max-sm:ml-2 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg mr-2`}
                            onClick={handleSearch}
                        >
                            <p className="text-lg font-medium leading-none text-white">
                                Rechercher
                            </p>
                        </button>
                    </div>
                </div>

                <div className="w-full flex flex-wrap p-2">
                    {Utilisateurs.length > 0 ? (
                        Utilisateurs.map((data, index) => (
                            <div key={index} className={` ${mobile ? 'px-2 w-full h-max py-1' : 'px-4 py-3'} m-2 border border-fuchsia-600 rounded-xl`}>
                                <div className={`flex ${mobile ? 'flex-col' : 'items-center '} space-x-4`}>
                                    <div className="flex-shrink-0 rounded-xl overflow-hidden">
                                        <img className="w-20 h-20" src={`https://irakoze.burundientempsreel.com/uploads/photosuser/${data.photo}`} alt="Avatar" />
                                    </div>
                                    <div>
                                        <p className="font-medium">{data.nom_complet}</p>
                                        <p className="text-gray-500">Tél: {data.tel}</p>
                                        <p className="text-gray-500">Email: {data.email}</p>
                                        <p className="text-gray-500">Genre: {data.genre}</p>
                                        <div className='flex mt-1'>
                                            {data.role === 0 ? (
                                                <>
                                                    <button onClick={() => handleActiveAdmin(data.id)} className='bg-blue-700 px-2 py-1 cursor-pointer rounded text-white mx-1'>Admin</button>
                                                    <button onClick={() => handleActiveInspecteur(data.id)} className='bg-blue-500 px-2 py-1 cursor-pointer rounded text-white mx-1'>Inspecteur</button>
                                                </>
                                            ) : (
                                                <button
                                                    onClick={() => handleDesactveUser(data.id)} className='bg-yellow-200 px-2 py-1 cursor-pointer rounded text-yellow-600 mx-1'> désactiver</button>
                                            )}

                                            <button onClick={() => handleDelete(data.id)} className='bg-red-200 px-2 py-1 cursor-pointer rounded text-red-600 mx-1'>Supprimer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className='w-full h-[60vh] flex justify-center items-center'>
                            <p className="text-center">Aucun Utilisateur ne correspond au <b><i>{searchTerm}</i></b></p>
                        </div>
                    )}
                </div>
                {Utilisateurs.length > 0 && (
                    <>
                        <div className="w-full flex justify-center items-center">
                            <div className="w-[25em] h-full flex justify-around items-center p-2">
                                <button onClick={previousPage} disabled={currentPage === 1} className="transition-all flex cursor-pointer hover:p-2 rounded">
                                    <i className="bi bi-arrow-left-circle-fill"></i>
                                    <span>Précédent</span>
                                </button>
                                <button onClick={nextPage} disabled={currentPage === totalPages} className="transition-all flex cursor-pointer hover:p-2 rounded">
                                    <span>Suivant</span>
                                    <i className="bi bi-arrow-right-circle-fill"></i>
                                </button>
                            </div>
                        </div>

                        <div className="mt-4 flex items-center justify-center">
                            <p className="text-gray-600">{getRange()}</p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default ComptUser;