/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../../Loading/Loading';

function TemoignageGeraAdmin() {
    const [temoignages, setTemoignages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isPending, setPending] = useState(true);
    const [pageSize, setpageSize] = useState(20)
    const [totalElements, settotalElements] = useState(0)
    useEffect(() => {
        axios
            .get(`https://irakoze.burundientempsreel.com/api/getalltemoignage?page=${currentPage}`)
            .then((response) => {
                const { totalItems, temoignages, totalPages } = response.data;
                setTemoignages(temoignages);
                setTotalPages(totalPages);
                settotalElements(totalItems);
                setTimeout(() => {
                    setPending(false);
                }, 100);
            })
            .catch((error) => {
                console.log(error.message);
            });
    }, [currentPage]);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} temoignage sur ${totalElements}`;
    };


    const transformDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = date.toLocaleDateString('fr-FR', { weekday: 'long' });
        const dayNumber = date.getDate();
        const month = date.toLocaleDateString('fr-FR', { month: 'long' });
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        hours = hours > 9 ? hours : '0' + hours;

        const time = `${hours}h${minutes}`;
        return (
            <>
                <p className='mx-2'>
                    {day}, Le {dayNumber} {month} {year}
                </p>
                <p className='ml-2'>
                    à {time}
                </p>
            </>
        );
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth < 626.383939302);
    const [isMobile1, setIsMobile1] = useState(window.innerWidth < 357.383939302);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 626.383939302);
            setIsMobile1(window.innerWidth < 357.383939302);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='w-full min-h-[87vh]'>
            {isPending && <Loading />}
            <div className='w-full px-5 rounded-b-lg flex items-center justify-end border-b border-gray-300 flex-row'>
                <Link to='/AdminTemoig/StandyBuy' className='bg-orange-500 text-white hover:no-underline focus:no-underline hover:text-white focus:text-white my-2 text-[13px] sm:text-[17px] rounded h-max w-max px-2 py-1'>
                    Gérer les témoignages
                </Link>
            </div>
            <div className="w-full h-max mb-5  p-1 flex flex-wrap justify-around">
                {temoignages.length > 0 ? (
                    temoignages.map((value) => (
                        <div key={value.id} className={`${isMobile1 ? 'w-[90%]' : 'w-[25em]'} border rounded-lg p-2 m-1`}>
                            <div className="transition-all w-full hover:text-orange-500 mt-3">
                                <div className='text-center font-serif p-1 w-full h-full sm:text-[30px]'>{value.titre}</div>
                            </div>
                            <Link to={`/AdminTemoig/Temoignage/Detail/${value.id}`} className='text-gray-500 hover:no-underline my-3 sm:text-[19px]'>
                                <div>
                                    {value.contenue && value.contenue.length > 300 ? (value.contenue.slice(0, 300) + '...') : value.contenue}
                                </div>
                            </Link>
                            <div className={`flex justify-between ${isMobile1 ? 'text-[10px]' : ''} p-1 mt-5 font-serif`}>
                                <span>Nom: {value.nom_complet}</span>
                                <span>{transformDate(value.createdAt)}</span>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={`${isMobile ? 'w-[98%] p-1' : 'p-32'} h-[70vh] rounded-2x flex justify-center items-center`}>
                        <div className={`${isMobile ? 'w-[95%]' : ''} flex flex-wrap justify-between items-center mx-auto bg-orange-600 rounded-md`}>
                            <div className='text-center py-20 px-2 border-dashed border-2 border-slate-200 rounded-xl dark:border-slate-100 w-full'>
                                <i className='bi bi-contact bx-lg mb-5 dark:text-white'></i>
                                <p className="text-xl mb-2 uppercase font-bold dark:text-white">Pas de témoignage disponible</p>
                                <span className="text-m text-slate-400 block mb-10 dark:text-slate-50">Commencez par la validation des témoignages disponibles.</span>
                                <Link to="/AdminTemoig/StandyBuy" className="bg-orange-400 rounded-full px-5 py-3 text-white hover:bg-orange-500 w-52">Valider</Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {temoignages.length > 0 && (
                <>
                    <div className="w-full flex justify-center items-center">
                        <div className="w-[25em] h-full flex justify-around items-center p-2">
                            <button onClick={previousPage} disabled={currentPage === 1} className="transition-all flex cursor-pointer hover:p-2 rounded">
                                <i className="bi bi-arrow-left-circle-fill"></i>
                                <span>Précédent</span>
                            </button>
                            <button onClick={nextPage} disabled={currentPage === totalPages} className="transition-all flex cursor-pointer hover:p-2 rounded">
                                <span>Suivant</span>
                                <i className="bi bi-arrow-right-circle-fill"></i>
                            </button>
                        </div>
                    </div>

                    <div className="mt-4 flex items-center justify-center">
                        <p className="text-gray-600">{getRange()}</p>
                    </div>
                </>
            )}
        </div>
    );
}

export default TemoignageGeraAdmin;