import React from 'react'
import ModifierMembres from './ModifierMembres'

function ModifierMembresComponent() {
  return (
    <div>
      <ModifierMembres />
    </div>
  )
}

export default ModifierMembresComponent
