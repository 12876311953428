import React from 'react'
import AddMoreRessource from './AddMoreRessource'

function AddMoreRessourceComponent() {
    return (
        <>
            <AddMoreRessource />
        </>
    )
}

export default AddMoreRessourceComponent