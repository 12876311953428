import React from 'react'
import AjoutEvenement from './AjoutEvenement'
function AjoutEvenemenComponent() {
    return (
        <>
            <AjoutEvenement />
        </>
    )
}

export default AjoutEvenemenComponent
