import React from 'react'
import DetailAnnonces from './DetailAnnonces'
function DetailAdminAnnoncesComponet() {
    return (
        <>
            <DetailAnnonces />
        </>
    )
}

export default DetailAdminAnnoncesComponet
