/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loading from '../../../Loading/Loading';
function StandByDetail() {
    const history = useNavigate();
    const { id } = useParams();
    const [temoignage, setTemoignage] = useState({});
    const [isPending, setPending] = useState(true)
    useEffect(() => {
        axios.get(`https://irakoze.burundientempsreel.com/api/getontemoignage/${id}`).then((response) => {
            setTemoignage(response.data);
            setTimeout(() => {
                setPending(false)
            }, 100)
        }).catch((err) => {
            console.log(err.message)
        });
    }, [])
    function transformDate(isoDate) {
        const date = new Date(isoDate);
        const frenchMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        const frenchDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
        const day = frenchDays[date.getDay()];
        const dayNumber = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        hours = hours > 9 ? hours : '0' + hours;

        let time = `${hours}h${minutes}`
        return (<>
            <p className='mx-2'>
                {day}, {'Le'} {dayNumber}/0{date.getMonth() + 1}/{year}
            </p>
            <p className='ml-2'>
                à {time}
            </p>
        </>
        )
    }

    function handleDelete(idd) {
        Swal.fire({
            title: 'Confirmation',
            text: 'Etes-vous sûr que vous voulez annulé?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'No',
            customClass: {
                confirmButton: 'swal-confirm-button',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`https://irakoze.burundientempsreel.com/api/deletetemoignage/${idd}`)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('le temoignage a été annulé avec succès');
                            history("/AdminTemoig/StandBuy");
                        } else {
                            toast.error("Une erreur s'est produite lors de la annulation du temoignage");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error(error.message)
                        //toast.error("Une erreur s'est produite lors de la annulation du temoignage");
                    });
            }
        });
    }

    function handleValide(idd) {
        Swal.fire({
            title: 'Confirmation',
            text: 'Etes-vous sûr que vous voulez Valider?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'No',
            customClass: {
                confirmButton: 'swal-confirm-button',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(`https://irakoze.burundientempsreel.com/api/validetemoignage/${idd}`)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('le temoignage a été Validé avec succès');
                            history("/AdminTemoig/StandyBuy");
                        } else {
                            toast.error("Une erreur s'est produite lors de la Validation du temoignage");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error("Une erreur s'est produite lors de la Validation du temoignage");
                    });
            }
        });
    }
    return (
        <div className=' flex min-h-[75vh] flex-col items-end'>
            {isPending && <Loading />}
            <div className='flex w-full justify-start pl-2 mt-1'>

                <Link to='/AdminTemoig/StandyBuy'>
                    Retour
                </Link>


            </div>
            <div className="w-full h-max mb-5 p-1 flex flex-wrap justify-around">
                <div className="w-[90%] p-2 m-1 rounded">
                    <div className="trasition-all flex w-full  mt-3">
                        <span className=' p-1  w-full h-full sm:text-[25px] '>{temoignage.titre}</span>
                    </div>
                    <p className=' sm:text-[19px] my-3 '>
                        {temoignage.contenue}
                        <br />
                        <div className='text-gray-400 mt-3 text-[17px]'> {temoignage.nom_complet} ,{transformDate(temoignage.createdAt)}</div>
                    </p>
                    <div className=" flex justify-end w-full p-1 mt-5 ">
                        <button onClick={() => handleValide(temoignage.id)} className='transition-all bg-green-400 hover:bg-green-600 text-white p-1 sm:text-[20px] mx-1 rounded'>Valider</button>
                        <button onClick={() => handleDelete(temoignage.id)} className='transition-all bg-red-400 hover:bg-red-600 text-white p-1 sm:text-[20px] mx-1 rounded'>Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StandByDetail
