import React from 'react'

import GrerRessourcesAdmin from './GrerRessourcesAdmin'
function GrerRessourcesAdminComponent() {
    return (
        <>

            <GrerRessourcesAdmin />
        </>
    )
}

export default GrerRessourcesAdminComponent
