/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../Loading/Loading';
import { FadeLoader } from 'react-spinners';
import ReactPlayer from 'react-player';
function EvenementComposeSousTitreDetailModifier() {
    const [spinnerButton, SetSpinnerButton] = useState(false)
    const history = useNavigate();
    const { id } = useParams();
    const [isTitre, SetisTitre] = useState('')
    const [isSousTitre, SetisSousTitre] = useState('')
    const [animationClassTitre, setanimationClassTitre] = useState('');
    const [animationClassisSousTitre, setanimationClassisSousTitre] = useState('');

    const [animationClassProfil, setanimationClassProfil] = useState('');
    const [isTextarea, SetisTextarea] = useState('')
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const [file, setFile] = useState(null);
    const [newfile, setNewFile] = useState(null)
    const [pending, setPending] = useState(true);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setNewFile(selectedFile);
    };

    const handleSubmit = (e) => {

        e.preventDefault();
        if (isTitre.trim() == '') {
            toast.warning("Titre de l'événement est obligatoire", {
                autoClose: 2000
            });
            setanimationClassTitre('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setanimationClassTitre(' ')
            }, 3000)
            return;
        }
        if (isSousTitre.trim() == '') {
            toast.warning("Titre de l'événement est obligatoire", {
                autoClose: 2000
            });
            setanimationClassisSousTitre('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setanimationClassisSousTitre(' ')
            }, 3000)
            return;
        }
        else if (isTextarea.trim() === '') {
            toast.warning("  La déscription de l'événement est obligatore", {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassTextarea(' ')
            }, 3000)
            return;
        }



    }

    const [mobile, Setmobile] = useState(window.innerWidth < 344.383939302)

    useEffect(() => {

        const hundleSize = () => {
            Setmobile(window.innerWidth < 344.383939302)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    })

    const [loading, SetLoading] = useState(true)

    return (
        <div className=' flex-col h-max flex justify-center items-center'>
            {pending && <Loading />}
            <div className='w-full px-5 rounded-b-lg flex items-center justify-between border-b border-gray-300 flex-row'>

                <Link to='/AdminEvent/Evenement' className='bg-orange-500 my-2 text-[17px] rounded h-max w-max px-2 py-1'>
                    Retour
                </Link>
                <Link to='/AdminEvent/AjoutEvenement' className='bg-orange-500 my-2 text-[17px] rounded h-max w-max px-2 py-1'>
                    Ajouter un événement
                </Link>

            </div>
            <div className="border border-orange-600 rounded-2xl m-3 p-4  w-[30em] h-max wimax ">
                <div className='flex items-center w-full justify-between'>
                    <Link to='/AdminEvent/EvenementComposeTitre/Detail'>
                        Retour
                    </Link>
                    <h2 className=' p-2 text-center  text-[25px] text-orange-500'>Modifier un événement</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className=" transition-all flex flex-col mb-6  p-1">
                        <div className="flex w-full justify-between  my-3">
                            <div class="relative h-11 w-full  mr-2 ">
                                <label class="text-[18px]">
                                    Titre de l'événement
                                </label>
                                <input
                                    placeholder="Titre de l'événement"
                                    value={isTitre}
                                    onChange={(e) => SetisTitre(e.target.value)}
                                    className={`border p-3 mt-2 font-normal  outline-none focus:border w-full focus:border-orange-600 bg-transparent rounded-lg ${animationClassTitre}`} />
                            </div>
                        </div>
                        <div className="flex w-full justify-between mt-10">
                            <div class="relative h-11 w-full  mr-2 ">
                                <label class="text-[18px]">
                                    Sous titre de l'événement
                                </label>
                                <input value={isSousTitre} onChange={(e) => SetisSousTitre(e.target.value)} className={`border p-3 mt-2 font-normal  outline-none focus:border w-full focus:border-orange-600 bg-transparent rounded-lg ${animationClassisSousTitre}`} placeholder=" Sous titre de l'événement" />
                            </div>
                        </div>
                        <div className="relative mt-10">
                            <div className="inline-flex items-center mr-4 text-[18px] text-gray-500">Profil:</div>
                            <label htmlFor="profils" className={` transition-all p-1 bg-orange-400 hover:bg-orange-600 rounded cursor-pointer text-white ${animationClassProfil}`}>Selection le profile</label>
                            <input type="file" id='profils' name='file' hidden accept=".mp4, .jpg, .jpeg, .png,.mov,.avi" onChange={handleFileChange} />
                            <div className={`animeBorder p-1 transition-all relative ${mobile ? 'left-1 border-2' : 'left-20 border-4'} top-4  rounded  border-dotted border-orange-500 relative overflow-hidden w-[200px]  h-[200px] `}>
                                {newfile ? (() => {
                                    if (!newfile) return null;
                                    const extension = newfile.name.split('.').pop().toLowerCase();
                                    const fileUrl = URL.createObjectURL(newfile);
                                    if (extension === 'mp4') {
                                        return (
                                            <>
                                                {loading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                }

                                                <div className="file-container">
                                                    <ReactPlayer url={fileUrl}
                                                        onReady={() => {
                                                            setTimeout(() => {
                                                                SetLoading(false)
                                                            }, 1000)
                                                        }} controls width="100%" height="100%" className="video-player inline" />
                                                </div>
                                            </>

                                        )
                                    } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
                                        return (<>
                                            {loading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                            }
                                            <img src={fileUrl}
                                                onLoad={() => {
                                                    setTimeout(() => {
                                                        SetLoading(false)
                                                    }, 1000)
                                                }}
                                                alt="Image" className=' w-full h-full rounded object-cover' />;

                                        </>)
                                    }
                                })() : (() => {
                                    if (file) {
                                        const extension = file.split('.').pop();
                                        if (extension === 'pdf') {
                                            console.log("C'est un fichier PDF.");
                                        } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {
                                            {
                                                return (
                                                    <>
                                                        {loading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                        }
                                                        <ReactPlayer
                                                            className='w-full h-full'
                                                            controls
                                                            onReady={() => {
                                                                setTimeout(() => {
                                                                    SetLoading(false)
                                                                }, 1000)
                                                            }}
                                                            url={`https://irakoze.burundientempsreel.com/uploads/evenements/${file}`}
                                                        />
                                                    </>
                                                )
                                            }
                                        } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
                                            return (

                                                <>
                                                    {loading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                    }
                                                    <img
                                                        src={`https://irakoze.burundientempsreel.com/uploads/evenements/${file}`}
                                                        className="w-full h-full object-cover"
                                                        onLoad={() => {
                                                            setTimeout(() => {
                                                                SetLoading(false)
                                                            }, 1000)
                                                        }}
                                                    />
                                                </>
                                            );
                                        }
                                    }
                                })()}

                            </div>
                        </div>
                    </div>
                    <div className="relative h-full w-full mt-[5em]">
                        <label className={` text-[19px]`}>
                            La déscription de l'événement
                        </label>
                        <textarea
                            className={` my-2 rounded-lg h-[18em] w-full resize-none p-2 outline-none focus:border border focus:border-orange-600 bg-transparent   ${animationClassTextarea}`}
                            value={isTextarea}
                            placeholder=" La déscription de l'événement"
                            onChange={(e) => {
                                SetisTextarea(e.target.value);
                            }}
                        ></textarea>
                    </div>

                    <div className="flex relative justify-end items-center">
                        {spinnerButton ? (
                            <>
                                <div className="flex justify-end items-center mt-4 relative pointer-events-none opacity-80">
                                    <div className='absolute bg-transparent  pt-3  w-full h-full flex justify-center items-center z-50'>
                                        <FadeLoader
                                            color="rgb(255, 255, 255)"
                                            height={10}
                                            margin={-9}
                                            radius={100}
                                            speedMultiplier={1}
                                            width={1}
                                        /></div>
                                    <input type="submit" id="send" value="Modifier" class=" transition-all bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600"></input>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="flex justify-end items-center mt-2">
                                    <label htmlFor='sende' className="w-max cursor-pointer  flex justify-end p-1 bg-orange-600 rounded  px-2 py-1 text-white">
                                        <input type="submit" className='pointer-events-none' id='sende' value="Modifier" />
                                        <i title="Modifier" class="bi bi-send ml-2 "></i>
                                    </label>
                                </div>

                            </>
                        )}

                    </div>



                </form>
            </div>
        </div>
    )
}

export default EvenementComposeSousTitreDetailModifier


