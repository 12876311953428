import React from 'react'
import AproposComponent from '../../../component/Visitor/Apropos/AproposComponent'
function AproposPage() {


    return (
        <AproposComponent />
    )
}

export default AproposPage
