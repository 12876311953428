
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loading from '../../../Loading/Loading';
import ReactPlayer from 'react-player';
import { FadeLoader } from 'react-spinners';
import { InView } from 'react-intersection-observer';
import { formatDate } from '../../../../module';

function EvenementInspecteur() {
    const url = window.location.pathname;
    const navigate = useNavigate()
    const [filterValue, setFilterValue] = useState('');
    const [mobile2, Setmonile2] = useState(window.innerWidth < 1008.9)
    const handleFilterChange = (event) => {
        setFilterValue(event.target.value.toLowerCase());
    };

    const [searchTerm, setSearchTerm] = useState("");
    const [data, setdata] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalElements, setTotalElements] = useState(0)


    const [rechercher, setRechercher] = useState(false)
    const [isrechercher, setisRechercher] = useState(0)
    function handleSearch() {
        setRechercher(true)
        setisRechercher(isrechercher + 1)
    }



    useEffect(() => {
        fetchdata(currentPage, pageSize, searchTerm, rechercher);
    }, [currentPage, pageSize, isrechercher]);

    const fetchdata = async (page, size, searchTerm, rechercher) => {
        try {

            const response = await axios.get(`https://irakoze.burundientempsreel.com/api/getallInvalideEvenent?page=${page}&pageSize=${size}&searchTerm=${searchTerm}`);
            setdata(response.data.events);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalElements);

        } catch (error) {
            console.error('Erreur lors de la récupération des data :', error.message);
        }
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} data sur ${totalElements}`;
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCancelSearch = () => {
        setSearchTerm('');
        setRechercher(false);
        setisRechercher(isrechercher + 1);
    };
    const valider = (id) => {
        // Appel à votre API pour mettre à jour la validité de l'événement avec l'ID donné
        Swal.fire({
            title: 'Confirmation',
            text: "Êtes-vous sûr de vouloir valider l'événement?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Valider',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'swal-confirm-button',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(`https://irakoze.burundientempsreel.com/api/changeEventValidity/${id}`, { valide: 1 })
                    .then((response) => {
                        if (response.status === 200) {
                            navigate("/InspectEvent/Evenement/valid")
                            toast.success('L\'événement a été validé avec succès');
                        } else {
                            toast.error("Une erreur s'est produite lors de la validation de l'événement");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error("Une erreur s'est produite lors de la validation de l'événement");
                    });
            }
        });
    };

    const [mobiles, SetMbiles] = useState(window.innerWidth < 400)

    useEffect(() => {
        const HundleSize = () => {
            SetMbiles(window.innerWidth < 400)
        }

        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])

    return (
        <div className='min-h-[85vh]'>
            <div className='text-[20px]  h-[7vh] flex justify-between items-end'>
                <Link className={`w-full ${mobiles ? 'text-[13px]' : ''} border-b-2 pl-2 ${(url == "/InspectEvent/Evenement" || url == "/event/Detail" || url == "/event/AddMore" || url == "/event/detailTitre" || url == "/event/edit") && "border-fuchsia-600 hover:text-fuchsia-600 focus:text-fuchsia-600 text-fuchsia-600"}`} to='/InspectEvent/Evenement'>Evénements invalide</Link>
                <Link className={`w-full ${mobiles ? 'text-[13px]' : ''} border-b-2 pl-2 ${(url == "/InspectEvent/Evenement/valid" || url == "/event/Detail" || url == "/event/AddMore" || url == "/event/detailTitre" || url == "/event/edit") && "border-fuchsia-600 hover:text-fuchsia-600 focus:text-fuchsia-600 text-fuchsia-600"}`} to='/InspectEvent/Evenement/valid'>Evénements valide</Link>
            </div>
            <div className="w-full overflow-hidden mt-4">
                <div class={`sm:flex items-center ${mobile2 ? 'w-full ' : ''}  justify-end`}>
                    <div class="sm:flex items-center justify-end">
                        <div class="mx-2 z-10">
                            <div class="relative flex items-center border rounded-md px-1 border-gray-300">
                                <div class="flex items-center pointer-events-none">
                                    <svg
                                        class="w-6 h-6 "
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    name="search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    id="topbar-search"
                                    class=" border-none outline-none bg-transparent  sm:text-lg rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full  p-2"
                                    placeholder="Rechercher ..."
                                />
                                {searchTerm.trim() != "" && <div onClick={handleCancelSearch} class=" flex text-white items-center rounded-full bg-red-700 cursor-pointer ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi w-5 h-5 bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                    </svg>
                                </div>
                                }
                            </div>
                        </div>
                        <button
                            className={`mt-4 sm:mt-0 ${searchTerm.trim() == "" ? "opacity-50 pointer-events-none" : 'opacity-100 pointer-events-auto cursor-pointer'} inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg mr-2`}
                            onClick={handleSearch}
                        >
                            <p className="text-lg font-medium leading-none text-white">
                                Rechercher
                            </p>
                        </button>
                    </div>
                </div>

                <div className="w-full flex flex-wrap p-2">

                    {data.length > 0 ? data.map((items, index) => (
                        <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-4">
                            <div className="max-w-sm p-6 border border-fuchsia-700 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                <Link to={`/InspectEvent/Evenement/valid/Detail/${items.id}`}>
                                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-600">{items.titre}</h5>
                                </Link>

                                <h5 className="mb-2 text-sm font-bold tracking-tight text-gray-500">
                                    Validation {items.valide === 0 ? <i className="bi bi-x-circle-fill text-red-500"></i> : <i className="bi bi-check-circle-fill text-green-500"></i>}
                                </h5>

                                <p className="mb-3 font-semibold text-gray-00 dark:text-gray-300">Validation {formatDate(items.createdAt)}</p>
                                <div className='flex gap-3 flex-wrap'>
                                    <Link to={`/InspectEvent/Evenement/valid/Detail/${items.id}`} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 hover:text-blue-300 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        En savoir plus
                                        <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                        </svg>
                                    </Link>
                                    <button onClick={() => valider(items.id)} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 hover:text-blue-300 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Valider
                                    </button>
                                </div>
                            </div>
                        </div>
                    )) : (
                        <div className='w-full h-[60vh] flex justify-center items-center'>
                            <p className="text-center">Aucun evenement ne correspond au <b><i>{searchTerm}</i></b></p>
                        </div>
                    )}

                </div>
                {data.length > 0 && (
                    <>
                        <div className="w-full flex justify-center items-center">
                            <div className="w-[25em] h-full flex justify-around items-center p-2">
                                <button onClick={previousPage} disabled={currentPage === 1} className="transition-all flex cursor-pointer hover:p-2 rounded">
                                    <i className="bi bi-arrow-left-circle-fill"></i>
                                    <span>Précédent</span>
                                </button>
                                <button onClick={nextPage} disabled={currentPage === totalPages} className="transition-all flex cursor-pointer hover:p-2 rounded">
                                    <span>Suivant</span>
                                    <i className="bi bi-arrow-right-circle-fill"></i>
                                </button>
                            </div>
                        </div>

                        <div className="mt-4 flex items-center justify-center">
                            <p className="text-gray-600">{getRange()}</p>
                        </div>
                    </>
                )}
            </div>

        </div>
    )
}

export default EvenementInspecteur