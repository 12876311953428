import React, { useRef, useState } from 'react'
import NavNarsAdminFonction from './NavNarsAdminFonction'
import { toast } from 'react-toastify';
import axios from 'axios';

function AjoutAdminFonction() {
    const [fonctions, setFonctions] = useState(['']);
    const inputRefs = useRef([]);

    // Fonction pour ajouter un nouveau champ de fonction
    const addFonctionField = () => {
        // Vérifier si le champ précédent est rempli avant d'ajouter un nouveau champ
        if (fonctions[fonctions.length - 1] && fonctions[fonctions.length - 1].trim() !== '') {
            setFonctions([...fonctions, '']);
        } else {
            toast.warning('Veuillez compléter le champ précédent avant d\'ajouter un nouveau champ.');
            // Mettre le focus sur le dernier champ vide
            if (inputRefs.current.length && inputRefs.current.length > 0) {
                inputRefs.current[inputRefs.current.length - 1].focus();
            }
        }
    };

    // Fonction pour mettre à jour la valeur d'un champ de fonction
    const handleFonctionChange = (index, value) => {
        const updatedFonctions = [...fonctions];
        updatedFonctions[index] = value;
        setFonctions(updatedFonctions);
    };

    // Fonction pour supprimer un champ de fonction
    const removeFonctionField = (index) => {
        const updatedFonctions = [...fonctions];
        updatedFonctions.splice(index, 1);
        setFonctions(updatedFonctions);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Valider le formulaire
        let isFormValid = true;
        fonctions.forEach((fonction, index) => {
            if (!fonction || fonction.trim() === '') {
                toast.warning(`Veuillez remplir le champ ${index + 1}`);
                if (inputRefs.current.length && inputRefs.current.length > 0) {
                    inputRefs.current[inputRefs.current.length - 1].focus();
                    isFormValid = false;
                    return;
                }
            }
        });

        if (isFormValid) {
            axios.post('https://irakoze.burundientempsreel.com/api/fonctions', fonctions)
                .then((response) => {
                    toast.success('Fonctions ajoutées avec succès!');
                    setFonctions([""])
                })
                .catch((error) => {
                    toast.error('Une erreur est survenue lors de l\'ajout des fonctions.');
                });

        }
    };

    return (
        <div>
            <div class=" min-h-[85vh] mx-auto">
                <NavNarsAdminFonction />
                <h1 class="sm:text-2xl text-[15px] font-bold mb-4 p-2">Ajouter des fonctions scolaires</h1>
                <div class="flex p-2 flex-wrap gap-4 w-full">
                    <form className="flex mb-4 flex-col  rounded p-2 w-full  border" onSubmit={handleSubmit}>
                        {fonctions.map((fonction, index) => (
                            <div key={index} className="flex w-full font-normal flex-col p-3 mb-1">
                                <label>{index + 1}.Fonction</label>
                                <div className='w-full '>
                                    <input
                                        type="text"
                                        className=" py-3 w-full px-4 mr-2 border bg-transparent outline-none focus:border-fuchsia-800  rounded"
                                        placeholder={`${index + 1} fonction`}
                                        value={fonction}
                                        onChange={(e) => handleFonctionChange(index, e.target.value)}
                                        ref={(input) => {
                                            inputRefs.current[index] = input;
                                        }}
                                    />
                                    {index > 0 &&
                                        <button
                                            type="button"
                                            className="bg-red-500 mt-1 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                                            onClick={() => removeFonctionField(index)}
                                        >
                                            Supprimer
                                        </button>
                                    }
                                </div>
                            </div>
                        ))}
                        <button
                            type="button"
                            className="bg-green-500 w-max hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                            onClick={addFonctionField}
                        >
                            Ajouter autre fonction
                        </button>
                        <div className='flex justify-end w-full'>
                            <button type="submit" className="bg-blue-500 w-max mt-1 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded ml-2">
                                Soumettre
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AjoutAdminFonction