export const datas = [
    { id: 1, nom: 'Informatique' },
    { id: 2, nom: 'Langue' },
    { id: 3, nom: 'Science' },
    { id: 4, nom: 'Mathématiques' },
    { id: 5, nom: 'Histoire' },
    { id: 6, nom: 'Art' },
    { id: 7, nom: 'Économie' },
    { id: 8, nom: 'Physique' },
    { id: 9, nom: 'Chimie' },
    { id: 10, nom: 'Géographie' }
];

export const classes = [
    { classe: "7", nombreTotal_Eleves: 30, nombresGarcon: 15, nombresFille: 15, result_publier: 80 },
    { classe: "9", nombreTotal_Eleves: 25, nombresGarcon: 12, nombresFille: 13, result_publier: 78 },
    { classe: "10", nombreTotal_Eleves: 28, nombresGarcon: 14, nombresFille: 14, result_publier: 71.5 },
    { classe: "8", nombreTotal_Eleves: 32, nombresGarcon: 16, nombresFille: 16 },
    { classe: "12", nombreTotal_Eleves: 27, nombresGarcon: 13, nombresFille: 14, result_publier: 90 },
    { classe: "7", nombreTotal_Eleves: 30, nombresGarcon: 15, nombresFille: 15, result_publier: 69.9 },
    { classe: "9", nombreTotal_Eleves: 25, nombresGarcon: 12, nombresFille: 13, result_publier: 78 },
    { classe: "10", nombreTotal_Eleves: 28, nombresGarcon: 14, nombresFille: 14, result_publier: 87 },
    { classe: "8", nombreTotal_Eleves: 32, nombresGarcon: 16, nombresFille: 16, result_publier: 92 },
    { classe: "12", nombreTotal_Eleves: 27, nombresGarcon: 13, nombresFille: 14, result_publier: 76 },
];