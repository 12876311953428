import React from 'react'

function Historique() {
    return (
        <div className='w-full h-max flex justify-center items-center'>
            <div className='w-[90%]'>
                <h2 className='sm:text-[30px] text-[19px]  text-center  px-4 p-1 my-2 rounded text-orange-400 '>Historique</h2>
                <p className='text-gray-600 sm:text-[20px] text-[15px]'>
                    C'était en 1955 que le Roi MWAMBUTSA s'adressa au royaume BELGIQUE pour lui demander de créer cette école au BURUNDI au lieu au RWANDA.
                    Il a accepté et l'a établie à Q2 NGAGARA, sous le nom de GROUPE SCOLAIRE OFFICIELLE LAIC.<br />
                    -En novembre 1959, elle devient une école professionnelle appelée ECOLE OFFICIELLE DE MONTEURS, et ce jusqu'en 1961.<br />
                    -De 1962 à 1964, elle est renommée ATHENEE DE NGAGARA.<br />
                    -Entre 1965 et 1986, elle prend le nom d'ECOLE NORMALE DE L'ETAT.<br />
                    -De 1987 à 2016, elle est connue sous le nom de LYCEE NGAGARA.<br />
                    -Depuis 2017 jusqu'à ce jour, elle porte le nom de LYCEE D'EXCELLENCE NGAGARA.
                </p>

            </div>
        </div>
    )
}

export default Historique
