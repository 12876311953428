/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../../Loading/Loading';
import { formatDate, chiffrement } from '../../../module';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types'
import EmpyServices from '../EmpyServeices/EmpyServeices';
import { Button, Modal } from 'rsuite';
import { FiFileText, FiImage } from 'react-icons/fi';
function Edication() {
    const [isPending, setPending] = useState(true)
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, settotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [rechercher, setRechercher] = useState(false)
    const [totalElements, setTotalElements] = useState(0)
    const [notes, setnotes] = useState([]);
    const [isrechercher, setisRechercher] = useState(0)
    const [notesDetail, GetnotesDetail] = useState({})
    const HundleClickElement = event => {
        GetnotesDetail(event)
    }
    useEffect(() => {
        const fetchnotes = async () => {
            try {
                const response = await axios.get(`https://irakoze.burundientempsreel.com/api/notes?page=${currentPage}`);
                setnotes(response.data.notes);
                GetnotesDetail(response.data.notes[0]);
                settotalPages(response.data.totalPages);
                setTotalElements(response.data.totalElements);
                setPending(false)
            } catch (error) {
                console.error('Erreur lors de la récupération des notes :', error);
                setPending(false)
            }
        };

        fetchnotes();
    }, [pageSize, currentPage, isrechercher]);








    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


















    const [mobile1, setMobille1] = useState(window.innerWidth < 1178);
    const [mobile2, setMobille2] = useState(window.innerWidth < 627);
    const [mobile3, setMobille3] = useState(window.innerWidth < 443);
    const [mobile4, setMobille4] = useState(window.innerWidth < 504);
    useEffect(() => {
        const hundleSize = () => {
            setMobille1(window.innerWidth < 1178)
            setMobille2(window.innerWidth < 627)
            setMobille3(window.innerWidth < 443)
            setMobille4(window.innerWidth < 504)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    })






    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };

    const [isFullScreen, setIsFullScreen] = useState(false);
    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };


    return (
        <>
            {isPending && <Loading />}
            <div className="w-full flex h-max  mt-1 justify-center flex-col items-center ">
                <div className='p-2 w-full min-h-max '>
                    <div className="  w-full   rounded-lg  flex flex-col mt-[20px]  justify-center items-center ">
                        <div className={`flex w-full ${mobile1 ? 'flex-col' : ''}  p-2`}>
                            <div className={`flex flex-col items-center   ${mobile1 ? 'w-full' : 'w-[85%] '}`}>
                                <div className="grid grid-cols-1 w-full gap-4">
                                    <div className=" w-full rounded-lg">
                                        <h2 className="font-bold text-3xl first-letter:uppercase mb-1">
                                            {notesDetail.classse && notesDetail.classse.nom}
                                        </h2>
                                        <h3 className="font-bold text-3xl first-letter:uppercase mb-1">
                                            {notesDetail.titre}
                                        </h3>
                                        <div className="flex justify-between mb-1">
                                            <div className="flex text-xs items-center">
                                                <span>{formatDate(notesDetail.createdAt)}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-1   md:col-span-2">
                                        {notesDetail.fichier && (
                                            <div className="flex  h-[95vh]  flex-col w-full items-center   rounded-lg">
                                                {notesDetail.fichier.toLowerCase().endsWith('.pdf') ? (
                                                    <div className="mt-2 relative p-1">
                                                        <a href={`https://irakoze.burundientempsreel.com/uploads/enseignement/${notesDetail.fichier}`} target="_blank" rel="noopener noreferrer">
                                                            <FiFileText className="absolute top-0 right-0 mr-2 mt-2 text-3xl text-yellow-500 cursor-pointer" />
                                                        </a>
                                                    </div>
                                                ) : notesDetail.fichier.toLowerCase().match(/\.(jpeg|jpg|png|gif)$/) ? (
                                                    <div className="mt-2 relative w-full h-full p-1">
                                                        <div onClick={toggleFullScreen} className="absolute top-0 text-white right-0 mr-2 mt-2 cursor-pointer">
                                                            <FiImage />
                                                        </div>
                                                        <img className={`object-cover w-full rounded-t-lg h-full  rounded-lg cursor-pointer `}
                                                            src={`https://irakoze.burundientempsreel.com/uploads/enseignement/${notesDetail.fichier}`}
                                                            alt="   " />
                                                    </div>
                                                ) : (
                                                    <span>Fichier non pris en charge</span>
                                                )}
                                                <Modal dialogClassName='paddBottom' overflow={false} size='99%' open={notesDetail.fichier.toLowerCase().match(/\.(jpeg|jpg|png|gif)$/) && isFullScreen} onClose={toggleFullScreen}>
                                                    <Modal.Header>
                                                        <Modal.Title className='uppercase text-[250px] u text-center'>{notesDetail.titre}</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <img className="w-full h-full object-cover" src={`https://irakoze.burundientempsreel.com/uploads/enseignement/${notesDetail.fichier}`} alt="     " />
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button onClick={toggleFullScreen} appearance="subtle">
                                                            Cancel
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                                <div className="flex bg-fuchsia-600 flex-col justify-between p-4">
                                                    <h5 className="mb-2 text-2xl font-bold tracking-tight">{notesDetail.titre}</h5>
                                                    <p className="mb-3 font-normal">{notesDetail.description}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>

                            <div className={`${mobile1 ? 'w-full' : 'w-[15%] '} `}>
                                <div className='flex text-gray-500 m-1'>Les notes les plus recent</div>
                                {notes.length > 0 ? (
                                    <>
                                        <div className={`flex  flex-wrap justify-start bg-white gap-2`}>
                                            {notes.map((event, index) => {
                                                return (
                                                    <div key={index} onClick={() => HundleClickElement(event)} className={` border m-2   overflow-hidden w-max    rounded-lg bg-white     p-1`}>
                                                        <div className="w-full hover:no-underline focus:no-underline  mt-1 flex items-center flex-col p-3 cursor-pointer">
                                                            <div className='font-semibold  w-full text-[20px] text-start'> {notesDetail.classse && notesDetail.classse.nom}</div>
                                                            <div className='font-semibold flex items-center  w-full text-[13px] text-start'>
                                                                <div>Type: image</div>
                                                                <div className='ml-4'>
                                                                    {notesDetail.titre}
                                                                </div>
                                                            </div>
                                                            <span className='font-sans text-[13px] w-full text-orange-700'>Le {formatDate(event.createdAt)}</span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        {notes.length > 0 && (
                                            <>
                                                <div className="w-full flex justify-center items-center">
                                                    <div className="w-[25em] h-full flex justify-around items-center p-2">
                                                        <button onClick={previousPage} disabled={currentPage === 1} className="transition-all flex cursor-pointer hover:p-2 rounded">
                                                            <i className="bi bi-arrow-left-circle-fill"></i>
                                                            <span>Précédent</span>
                                                        </button>
                                                        <button onClick={nextPage} disabled={currentPage === totalPages} className="transition-all flex cursor-pointer hover:p-2 rounded">
                                                            <span>Suivant</span>
                                                            <i className="bi bi-arrow-right-circle-fill"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>) : (
                                    <div className='flex  flex-col items-center w-[50em] border border-orange-600 p-3 rounded-lg bg-slate-100 h-[50vh] widthsssssssss'>
                                        <EmpyServices items="d'événement" />
                                    </div>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}
export default Edication
