import { AES, enc } from "crypto-js";
import CryptoJS from "crypto-js";

export function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
}
export function transformDate(isoDate) {
    const date = new Date(isoDate);
    const frenchMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    const frenchDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const day = frenchDays[date.getDay()];
    const dayNumber = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    hours = hours > 9 ? hours : '0' + hours;

    let time = `${hours}h${minutes}`;
    return (
        <div className=' flex'>
            <p className='mx-2 '>
                {day}, {'Le'} {dayNumber}/0{date.getMonth() + 1}/{year}  à {time}
            </p>
        </div>
    );
}




const aesKey = CryptoJS.enc.Utf8.parse("aeskeyaeskeyaeskeyaeskeyaeskey32");
const aesIv = CryptoJS.enc.Utf8.parse("0123456789abcdef");
const aesOptions = {
    iv: aesIv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};
export function chiffrement(data) {
    const ciphertext = CryptoJS.AES.encrypt(
        data,
        aesKey,
        aesOptions
    ).ciphertext.toString(); // 'c3a48990119bdfe40b6c32ec2aca8b93'
    return ciphertext;
}
export function dechiffrement(encryptedData) {
    const encoded = { ciphertext: CryptoJS.enc.Hex.parse(encryptedData) };
    const decodedText = CryptoJS.enc.Utf8.stringify(
        CryptoJS.AES.decrypt(encoded, aesKey, aesOptions)
    );
    return decodedText;
}
