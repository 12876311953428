import React, { useEffect, useState } from 'react';
import Select from 'react-select';// Assurez-vous que le chemin vers le fichier de données est correct
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Modal } from 'rsuite';
import { useRef } from 'react';

function Enseignant() {
    const [showFormAnneeScolaire, setShowFormAnneeScolaire] = useState(false);
    const [showFormClasse, setShowFormClasse] = useState(false);

    const [classes, setClasses] = useState([]);

    useEffect(() => {
        axios.get('https://irakoze.burundientempsreel.com/api/Allclasses')
            .then(response => {
                setClasses(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des classes :', error);
            });
    }, []);
    const toggleFormAnneeScolaire = () => {
        setShowFormAnneeScolaire(!showFormAnneeScolaire);
        setShowFormClasse(false); // Cacher le formulaire de classe si le formulaire d'année scolaire est activé
    };

    const toggleFormClasse = () => {
        setShowFormClasse(!showFormClasse);
        setShowFormAnneeScolaire(false); // Cacher le formulaire d'année scolaire si le formulaire de classe est activé
    };

    const hideAllForms = () => {
        setShowFormAnneeScolaire(false);
        setShowFormClasse(false);
    };

    const [formData, setFormData] = useState({
        nom: '',
        dateDebut: '',
        dateFin: '',
        slogan: ''
    });
    const [errors, setErrors] = useState({
        nom: false,
        dateDebut: false,
        dateFin: false,
        slogan: false
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const elemetRefNom = useRef(null)
    const elemetRefdateDebut = useRef(null)
    const elemetRefdatedateFin = useRef(null)
    const elemetRefdateslogan = useRef(null)
    const handleSubmitAnneeScolaire = (e) => {
        e.preventDefault();
        let formIsValid = true;
        setErrors({})
        if (formData.nom.trim() === '') {
            toast.info('Veuillez saisir un nom valide.')
            errors.nom = true;
            elemetRefNom.current.focus()
            formIsValid = false;

            return
        }
        if (formData.dateDebut.trim() === '') {
            toast.info('Veuillez saisir une date de début valide.')
            errors.dateDebut = true;
            elemetRefdateDebut.current.focus()
            formIsValid = false;
            return
        }
        if (formData.dateFin.trim() === '') {
            toast.info('Veuillez saisir une date de fin valide.')
            errors.dateFin = true;
            elemetRefdatedateFin.current.focus()
            formIsValid = false;
            return
        }
        if (formData.slogan.trim() === '') {
            toast.info('Veuillez saisir un slogan valide.')
            errors.slogan = true;
            elemetRefdateslogan.current.focus()
            formIsValid = false;
            return
        }
        setErrors(errors);

        // Vérification de la date de fin
        if (formData.dateDebut > formData.dateFin) {
            toast.info('La date de fin ne peut pas être antérieure à la date de début.');
            elemetRefdatedateFin.current.focus()
            return;
        }

        // Envoi des données au backend si la validation réussit
        if (formIsValid) {
            axios.post('https://irakoze.burundientempsreel.com/api/anneescolaires', formData)
                .then(response => {
                    toast.success('la création d\'une année scolaire a ete faite avec succees')
                    setFormData({
                        nom: '',
                        dateDebut: '',
                        dateFin: '',
                        slogan: ''
                    });
                })
                .catch(error => {
                    console.error('Erreur lors de la soumission du formulaire:', error);
                    toast.warn('Erreur lors de la soumission du formulaire')
                });
        }
    };

    const [anneesScolaires, setAnneesScolaires] = useState([]);
    const [selectedAnneeScolaire, setSelectedAnneeScolaire] = useState(null);

    useEffect(() => {
        // Récupérer toutes les années scolaires depuis l'API
        axios.get('https://irakoze.burundientempsreel.com/api/anneescolaires')
            .then(response => {
                // Mettre à jour le state avec les années scolaires récupérées
                setAnneesScolaires(response.data.map(annee => ({ value: annee.id, label: annee.nom })));
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des années scolaires :', error);
            });
    }, []); // Utilisation du tableau vide pour exécuter l'effet uniquement une fois lors du chargement initial

    const [formData1, setFormData1] = useState({
        nom: '',
        anneescolaireId: null
    });
    const handleInputChange = (e) => {
        setFormData1({ ...formData1, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedAnneeScolaire(selectedOption);
        setFormData1({ ...formData1, anneescolaireId: selectedOption.value });
    };


    const elemetRefNom1 = useRef(null)
    const elemetRefanneescolaireId = useRef(null)

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData1.nom) {
            toast.info('Le nom est requis');
            elemetRefNom1.current.focus()
            return;
        }
        if (!formData1.anneescolaireId) {
            toast.info('L\'année scolaire est requise');
            return;
        }
        // Envoi des données au backend
        axios.post('https://irakoze.burundientempsreel.com/api/classes', formData1)
            .then(response => {
                toast.success('Classe créée avec succès');
                // Réinitialiser le formulaire après l'envoi réussi
                setFormData1({ nom: '', anneescolaireId: null });
                setSelectedAnneeScolaire(null);
                setErrors({});
            })
            .catch(error => {
                console.error('Erreur lors de la création d\'une classe :', error);
            });
    };

    const [mobile3, Setmonile3] = useState(window.innerWidth < 318)
    const [mobile, GetMbile] = useState(window.innerWidth < 611)
    const [mobile1, GetMbile1] = useState(window.innerWidth < 515)
    const [mobile2, GetMbile2] = useState(window.innerWidth < 315)
    useEffect(() => {
        const HundleSize = () => {
            GetMbile(window.innerWidth < 611)
            GetMbile1(window.innerWidth < 515)
            Setmonile3(window.innerWidth < 315)
        }
        window.addEventListener('resize', HundleSize)
        return () => {
            window.removeEventListener('resize', HundleSize)
        };
    }, []);
    return (
        <div className='p-2 min-h-[85vh]'>
            <div className='sm:text-3xl w-full text-[15px] '>Liste des classes</div>
            <div className='flex w-full mb-5'>
                <div className={`flex w-full ${mobile1 ? 'flex-col' : 'justify-between'}`}>
                    <div className="w-full flex justify-center items-center mt-4">
                        <button onClick={toggleFormAnneeScolaire} className="border hover:text-white hover:no-underline text-white focus:text-white text-[12px] focus:no-underline sm:text-[17px] border-blue-100 px-4 py-1 bg-blue-400 hover:bg-blue-600 transition-all  rounded-md cursor-pointer">
                            Ajouter A/S
                        </button>
                    </div>
                    <div className="w-full flex justify-center items-center mt-4">
                        <button onClick={toggleFormClasse} className="border border-blue-100 hover:text-white hover:no-underline text-white focus:text-white text-[12px] focus:no-underline sm:text-[17px] px-4 py-1 bg-blue-400 hover:bg-blue-600 transition-all  rounded-md cursor-pointer">
                            Ajouter Classe
                        </button>
                    </div>
                </div>

                <div className={`flex w-full ${mobile1 ? 'flex-col' : 'justify-between'}`}>
                    <div className="w-full flex justify-center items-center mt-4">
                        <Link to='/AdminEnseignant/AnneesScolairesPage' className="border hover:text-white hover:no-underline text-white focus:text-white text-[12px] focus:no-underline sm:text-[17px] border-blue-100 px-4 py-1 bg-blue-400 hover:bg-blue-600 transition-all  rounded-md cursor-pointer">
                            Liste des A/S
                        </Link>
                    </div>
                    <div className="w-full flex justify-center items-center mt-4">
                        <Link to='/AdminEnseignant/ClassesPage' className="border border-blue-100 hover:text-white hover:no-underline text-white focus:text-white text-[12px] focus:no-underline sm:text-[17px] px-4 py-1 bg-blue-400 hover:bg-blue-600 transition-all  rounded-md cursor-pointer">
                            Liste des classes
                        </Link>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-wrap'>
                <Modal dialogClassName='paddBottom' overflow={false} size='lg' open={showFormAnneeScolaire} onClose={hideAllForms}>
                    <Modal.Header>
                        <Modal.Title className='uppercase text-[250px] u text-center'>Ajouter une année scolaire</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmitAnneeScolaire}>


                            <div class="w-full mb-5">
                                <label class="block  tracking-wide  text-lg  mb-2" for="grid-Title">
                                    Nom
                                </label>
                                <input
                                    class={`block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3 ${errors.nom ? 'vibrate' : ''}`}
                                    type='text'
                                    name='nom'
                                    ref={elemetRefNom}
                                    value={formData.nom}
                                    onChange={handleChange}
                                    placeholder="Nom"
                                />

                            </div>
                            <div class="w-full mb-5">
                                <label class="block  tracking-wide  text-lg  mb-2" for="grid-Title">
                                    Date de début
                                </label>
                                <input
                                    class={`block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3 ${errors.dateDebut ? 'vibrate' : ''}`}
                                    type='date'
                                    name='dateDebut'
                                    ref={elemetRefdateDebut}
                                    value={formData.dateDebut}
                                    onChange={handleChange}
                                    placeholder="Date de début"
                                />

                            </div>
                            <div class="w-full mb-5">
                                <label class="block  tracking-wide  text-lg  mb-2" for="grid-Title">
                                    Date de fin
                                </label>
                                <input
                                    class={`block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3 ${errors.dateFin ? 'vibrate' : ''}`}
                                    type='date'
                                    name='dateFin'
                                    ref={elemetRefdatedateFin}
                                    value={formData.dateFin}
                                    onChange={handleChange}
                                    placeholder="Date de fin"
                                />

                            </div>
                            <div class="w-full mb-5">
                                <label class="block  tracking-wide  text-lg  mb-1" for="grid-Title">
                                    Slogan
                                </label>
                                <textarea
                                    name='slogan'
                                    ref={elemetRefdateslogan}
                                    value={formData.slogan}
                                    onChange={handleChange}
                                    placeholder=" Le Slogan"
                                    class={`block w-full bg-transparent h-[11em]  resize-none outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3 ${errors.slogan ? 'vibrate' : ''}`}
                                ></textarea>

                            </div>
                            <div className="flex justify-end items-center">
                                <button type="submit" className="w-max  flex justify-end p-1 bg-orange-600 rounded cursor-pointer px-5 py-1 text-white">Ajouter</button>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={hideAllForms} appearance="subtle">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>




                <Modal dialogClassName='paddBottom' overflow={false} size='lg' open={showFormClasse} onClose={hideAllForms}>
                    <Modal.Header>
                        <Modal.Title className='uppercase text-[250px] u text-center'>Ajouter une classe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <div className="w-full mb-5">
                                <label className="block tracking-wide text-lg mb-2" htmlFor="nom">Nom</label>
                                <input
                                    className={`block w-full bg-transparent outline-none focus:border focus:border-fuchsia-500 border border-red rounded py-3 px-4 mb-3 ${errors.nom && 'vibrate'}`}
                                    type='text'
                                    name='nom'
                                    ref={elemetRefNom1}
                                    value={formData1.nom}
                                    onChange={handleInputChange}
                                    placeholder="Nom"
                                />
                            </div>
                            <div className="w-full mb-5">
                                <label className="block tracking-wide text-lg mb-2" htmlFor="anneeScolaire">A/S</label>
                                <Select
                                    id="anneeScolaire"
                                    className={`block w-full bg-transparent outline-none focus:border focus:border-fuchsia-500 border border-red rounded py-3 px-4 mb-3 ${errors.anneescolaireId && 'vibrate'}`}
                                    options={anneesScolaires}
                                    value={selectedAnneeScolaire}
                                    onChange={handleSelectChange}
                                    placeholder="Sélectionnez une année scolaire"
                                />
                            </div>
                            <button type="submit" className="mt-4 bg-blue-400 hover:bg-blue-600 text-white py-2 px-4 rounded">Enregistrer</button>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={hideAllForms} appearance="subtle">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>



                {classes.map((data, posi) => {
                    return (
                        <div key={posi} className={`border border-orange-400 h-max ${mobile ? 'w-[98%]' : 'w-[20em] '} relative rounded-lg p-4 m-1`}>
                            <div className="flex flex-col">
                                <div className={`flex  flex-col`}>
                                    <span className={`leading-none font-bold ${mobile3 ? 'text-[18px]' : 'sm:text-3xl text-[20px]'} `}>
                                        {data.nom}
                                    </span>
                                    <div className={`text-gray-500 font-bold mt-2 ${mobile3 ? 'text-[14px]' : 'text-xl'}`}>
                                        Nombre total d'élèves : <span>{(data.elefe && (data.elefe.nombrefilles + data.elefe.nombregarcons)) || 0}</span>
                                    </div>
                                </div>
                                <div className={`text-[18px] flex justify-between max-sm:w-full text-center mt-4 font-normal text-gray-500 ${mobile3 ? 'flex-col' : ''}`}>
                                    <div className={`p-2 bg-[#0000ff07] border border-orange-100 rounded-lg ${mobile3 ? 'mb-3' : ''} `}>
                                        <div className={`font-bold ${mobile3 ? 'text-[15px]' : ''}`}>Garçons</div>
                                        <div className={`flex j ${mobile3 ? 'w-full justify-around' : 'w-24 justify-between'}`}>
                                            <div className={`font-semibold text-blue-500 ${mobile3 ? 'text-[12px]' : ''}`}>{(data.elefe && data.elefe.nombregarcons) || 0}</div>
                                            <div>~</div>
                                            <div className={`font-semibold text-blue-700 ${mobile3 ? 'text-[12px]' : ''}`}>{(((data.elefe && data.elefe.nombregarcons) || 0) / ((data.elefe && (data.elefe.nombrefilles + data.elefe.nombregarcons)) || 1) * 100).toFixed(1)}%</div>
                                        </div>
                                    </div>
                                    <div className={`p-2 bg-[#0000ff07] border border-orange-100 rounded-lg ${mobile3 ? 'mb-3' : ''} `}>
                                        <div className={`font-bold ${mobile3 ? 'text-[15px]' : ''}`}>Fille</div>
                                        <div className={`flex j ${mobile3 ? 'w-full justify-around' : 'w-24 justify-between'}`}>
                                            <div className={`font-semibold text-blue-500 ${mobile3 ? 'text-[12px]' : ''}`}>{(data.elefe && data.elefe.nombrefilles) || 0}</div>
                                            <div>~</div>
                                            <div className={`font-semibold text-blue-700 ${mobile3 ? 'text-[12px]' : ''}`}>{(((data.elefe && data.elefe.nombrefilles) || 0) / ((data.elefe && (data.elefe.nombrefilles + data.elefe.nombregarcons)) || 1) * 100).toFixed(1)}%</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex mt-2 text-gray-600">
                                    <div>Année scolaire :</div>
                                    <div className="text-blue-700 font-semibold ml-1">{data.anneescolaire ? data.anneescolaire.nom : 'Non spécifiée'}</div>
                                </div>
                            </div>
                            <div className="w-full flex justify-center items-center mt-4">
                                <Link to={`/AdminEnseignant/ClassesDetail/${data.id}`} className="border border-blue-100 px-4 py-1 bg-blue-400 hover:bg-blue-600 transition-all text-white hover:text-white focus:text-white hover:no-underline focus:no-underline rounded-md cursor-pointer">
                                    Détail
                                </Link>
                            </div>
                        </div>
                    );
                })}



            </div>
        </div >
    )
}

export default Enseignant
