/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/img-redundant-alt */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { decryptData } from '../../../../encryptionModule';

function EditEventPart() {
    const { id } = useParams();
    const bpId = decryptData(id);
    const history = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [blogData, setBlogData] = useState({
        soustitre: "",
        descriptions: "",
        photo: "",
        link: ""
    });
    const [newphoto, setNewPhoto] = useState(null);
    // Fonction de validation pour les liens YouTube
    const isValidYouTubeLink = (link) => {
        const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/
        return youtubeRegex.test(link);
    };
    useEffect(() => {
        axios.get(`https://irakoze.burundientempsreel.com/api/getevenementcontentById/${bpId}`)
            .then((response) => {
                setBlogData(response.data);
            })
            .catch((error) => {
                console.log(error.message);
            });
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!blogData.soustitre || blogData.soustitre.trim() === '') {
            toast.error("Le champ sous-titre est obligatoire.");
            return;
        }

        if (!blogData.descriptions || blogData.descriptions.trim() === '') {
            toast.error("Le champ description est obligatoire.");
            return;
        }

        // Validation du lien YouTube pour la propriété link
        if (blogData.link.trim() && !isValidYouTubeLink(blogData.link)) {
            toast.error('Le lien doit être une URL YouTube valide.');
            return;
        }

        // Vérification que l'une des deux propriétés (photo ou link) est remplie, mais pas les deux
        if ((blogData.photo || newphoto) && blogData.link.trim()) {
            toast.error('Vous devez remplir soit le champ photo, soit le champ lien, mais pas les deux.');
            return;
        }

        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('soustitre', blogData.soustitre);
        formData.append('descriptions', blogData.descriptions);
        if (blogData.link) {
            formData.append('link', blogData.link);
        }
        if (newphoto) {
            formData.append('photo', newphoto);
        }

        axios.put(`https://irakoze.burundientempsreel.com/api/updateEvenementContent/${bpId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                history("/AdminEvent/Evenement");
                toast.success('Événement modifié avec succès !');
            })
            .catch((error) => {
                console.error(error.message);
                toast.error('Une erreur est survenue lors de la soumission du formulaire.');
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <div className="container w-full p-4 mx-auto mt-5">
            <Link to='/AdminEvent/Evenement'>retour</Link>
            <div className="w-full mx-auto border border-fuchsia-800  rounded-md shadow-md overflow-hidden">
                <div className="p-6">
                    <h5 className="text-lg font-semibold mb-4">Éditer Part-Blog</h5>
                    <form onSubmit={handleSubmit}>
                        <div class="w-full mb-5">
                            <label
                                class="block  tracking-wide  text-lg  mb-2"
                                for="grid-Title"
                            >
                                Sous titre
                            </label>
                            <input
                                class={`block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3  `}
                                placeholder="sous-titre"
                                value={blogData.soustitre}
                                onChange={(e) => setBlogData({ ...blogData, soustitre: e.target.value })}
                            />
                        </div>
                        <div class="w-full mb-5">
                            <label
                                class="block  tracking-wide  text-lg  mb-1"
                                for="grid-Title"
                            >
                                Le contenue
                            </label>
                            <textarea
                                placeholder=" Le contenue"
                                class={`block w-full bg-transparent min-h-[15em]  resize-y outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3 `}

                                value={blogData.descriptions}
                                onChange={(e) => setBlogData({ ...blogData, descriptions: e.target.value })}
                            ></textarea>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="photo" className="block text-lg font-medium text-gray-700">Photo</label>
                            <div className="flex items-center">
                                {blogData.photo ? (
                                    <div className="uploaded-photo ">
                                        <img
                                            src={`https://irakoze.burundientempsreel.com/uploads/evenements/${blogData.photo}`}
                                            className="w-full max-h-64 object-contain"
                                            alt="Photo Preview"
                                        />
                                        <div

                                            className="px-2 py-1 text-sm mt-5 w-max bg-red-500 cursor-pointer text-white rounded-md ml-2"
                                            onClick={() => setBlogData({ ...blogData, photo: "" })}
                                        >
                                            Supprimer
                                        </div>
                                    </div>
                                ) : newphoto ? (
                                    <div className="uploaded-photo ">
                                        <img
                                            src={URL.createObjectURL(newphoto)}
                                            className="w-full max-h-64 object-contain"
                                            alt="Photo Preview"
                                        />
                                        <div
                                            className="px-2 py-1 cursor-pointer w-max mt-5 text-sm bg-red-500 text-white rounded-md ml-2"
                                            onClick={() => setNewPhoto(null)}
                                        >
                                            Supprimer
                                        </div>
                                    </div>
                                ) : (
                                    <div className="upload-icon">
                                        <input
                                            type="file"
                                            id="photo"
                                            accept="image/*"
                                            className="hidden"
                                            onChange={(e) => {
                                                const selectedPhoto = e.target.files[0];
                                                if (selectedPhoto) {
                                                    setNewPhoto(selectedPhoto);
                                                }
                                            }}
                                        />
                                        <label htmlFor="photo" className={`px-4 py-2 mt-2 block bg-blue-500 text-white rounded-md ${(blogData.link != '' && blogData.link != null) ? "pointer-events-none opacity-50 " : " pointer-events-auto opacity-100"} cursor-pointer`}>
                                            Télécharger
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div class="w-full mb-5">
                            <label
                                class="block  tracking-wide  text-lg  mb-2"
                                for="grid-Title"
                            >
                                Lien YouTube
                            </label>
                            <input
                                class={`block w-full bg-transparent outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3  `}
                                placeholder="Lien YouTube"
                                value={blogData.link}

                                onChange={(e) => setBlogData({ ...blogData, link: e.target.value })}
                            />
                        </div>
                        <div className='flex justify-end'>
                            <button
                                type="submit"
                                className=" flex justify-center w-max py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <svg
                                        className="animate-spin h-5 w-5 mr-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014.707 15H2v2h4v-4H4.707zM19.293 9H22V7h-4v4h2.293A7.962 7.962 0 0116 14.291V10h3.293zM20 12a8 8 0 01-8 8v-4c3.728 0 6.824-2.544 7.732-6H20zm-8-6v4H8c0-3.728 2.544-6.824 6-7.732V6H12z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Enregistrer'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditEventPart;
