import React from 'react'
import DetailTemoignage from './DetailTemoignage'
function DetailTemoignageComponent() {
    return (
        <>
            <DetailTemoignage />
        </>
    )
}

export default DetailTemoignageComponent
