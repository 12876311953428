/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../Loading/Loading';
import { formatDate, chiffrement } from '../../../module';
import ReactPlayer from 'react-player';
import EmpyServices from '../EmpyServeices/EmpyServeices';
import { FadeLoader } from 'react-spinners';
import { InView } from 'react-intersection-observer';
function Evenement() {
    const [lastEvents, setlastEvents] = useState([]);
    const [isPending, setPending] = useState(true);

    useEffect(() => {
        axios
            .get('https://irakoze.burundientempsreel.com/api/getlastevent')
            .then((response) => {
                setlastEvents(response.data);
                setTimeout(() => {
                    setPending(false);
                }, 100);
            })
            .catch((error) => {
                setTimeout(() => {
                    setPending(false);
                }, 100);
                console.error(error.message);
                if (error.response) {
                    console.log(error.response.data);
                    setTimeout(() => {
                        setPending(false);
                    }, 100);
                }
            });
    }, []);



    const [mobile1, setMobille1] = useState(window.innerWidth < 1178);
    const [mobile2, setMobille2] = useState(window.innerWidth < 627);
    const [mobile3, setMobille3] = useState(window.innerWidth < 443);
    const [mobile4, setMobille4] = useState(window.innerWidth < 504);
    useEffect(() => {
        const hundleSize = () => {
            setMobille1(window.innerWidth < 1178)
            setMobille2(window.innerWidth < 627)
            setMobille3(window.innerWidth < 443)
            setMobille4(window.innerWidth < 504)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    })


    const [visibleItems, setVisibleItems] = useState([]);
    const [loadedItems, setLoadedItems] = useState([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1,
        };

        const handleIntersection = (index) => {
            setVisibleItems((prev) => [...prev, index]);
        };
        const intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = parseInt(entry.target.getAttribute('data-position'), 10);
                    handleIntersection(index);
                }
            });
        }, observerOptions);

        const elements = document.querySelectorAll('.blocks');

        elements.forEach((element) => {
            intersectionObserver.observe(element);
            return () => {
                intersectionObserver.unobserve(element);
            };
        });

        return () => {
            intersectionObserver.disconnect();
        };
    }, []);

    const handleImageLoad = (index) => {
        setLoadedItems((prev) => [...prev, index]);
    };

    return (
        <>
            <div className="  w-[97%]  bg-gray-50 rounded-lg  flex flex-col mt-[20px]  justify-center items-center ">
                {isPending && <Loading />}
                <h2 className='text-[35px] font-serif text-gray-700 px-4 p-1 my-2 rounded '>Evénement</h2>
                <div className='h-[1px] mb-1 border bg-white w-full'></div>
                <div className={`flex flex-wrap justify-left  gap-2 p-2`}>
                    {lastEvents.length > 0 ? (
                        lastEvents.map((last, index) => {
                            return (
                                <div
                                    data-position={index}
                                    className={` blocks border  bg-[#e4e4e4] overflow-hidden ${mobile4 ? 'flex-auto' : ''} ${mobile2 ? 'w-[16em]' : 'w-[20em]'}  rounded-2xl bg-white     p-1`}>
                                    <div className="w-full mt-1 flex items-center flex-col p-3">
                                        <div className='font-semibold  w-full text-[20px] text-center'>{last.titre}</div>
                                        <span className='font-sans text-[13px] w-full text-orange-700'>Le {formatDate(last.createdAt)}</span>

                                        <div className={` ${mobile3 ? 'w-[80%] text-[14px]' : ' w-full text-[16px]'} h-max   flex justify-start`}>
                                            <Link to={`/Visit/Evenement/Detail/${chiffrement(((last.id).toString()))}`} className=' transition-all   p-2  mt-2 rounded'>En savoir plus</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                    ) : (<>
                        <div className='flex  flex-col items-center w-[50em] border border-orange-600 p-3 rounded-lg bg-slate-100 h-[50vh] widthsssssssss'>
                            <EmpyServices items="d'événement" />
                            <div className='text-[18px] font-sans text-gray-700'>
                                Les élèves impliqués dans l'organisation d'événements développent des compétences importantes telles que la gestion de projet, la communication, le travail d'équipe, la prise de décision et la résolution de problèmes.
                                <br /><br />
                                Les événements ajoutent de la diversité à l'expérience éducative en offrant aux élèves des opportunités d'apprentissage en dehors de la salle de classe. Ils complètent le programme académique et encouragent la curiosité intellectuelle.

                            </div>
                        </div>
                    </>)}
                </div>
                <Link to='/Visit/Evenement' className={`bg-orange-400 ${mobile3 ? 'w-[80%] text-[15px]' : 'w-[20em] text-[20px]'}  mb-4  py-2  text-center rounded-3xl cursor-pointer text-white  hover:border-orange-600 hover:bg-white transition-all hover:text-gray-700 border border-white ${lastEvents.length <= 0 ? 'pointer-events-none opacity-60' : 'pointer-events-auto opacity-100'}`}>Plus d'événements</Link>


            </div>

        </>
    );
}

export default Evenement;
