/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Loading from "../../../Loading/Loading";
import PropTypes from 'prop-types'

function AffchageMembre() {
  const [listoffmembers, setlistoffmembers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [totalElements, setTotalElements] = useState(0)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, statusFilter]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://irakoze.burundientempsreel.com/api/members", {
        params: {
          search: searchQuery,
          page: currentPage,
          size: pageSize,
        },
      });
      setlistoffmembers(response.data.listoffmembers);
      setTotalPages(response.data.totalPages);
      setTotalElements(response.data.totalElements);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);

    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchData();
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(1);
  };


  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, totalElements);
    return `${from} à ${to} membres sur ${totalElements}`;
  };
  function handleDelete(id, position) {
    Swal.fire({
      title: "Confirmation",
      text: "Etes-vous sûr que vous voulez supprimer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "SUPPRIMER",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "swal-confirm-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://irakoze.burundientempsreel.com/api/membres/${id}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success("le membre a été supprimé avec succès");
              setCurrentPage(1);
              const allMember = [...listoffmembers];
              allMember.splice(position, 1);
              setlistoffmembers(allMember);
            } else {
              toast.error(
                "Une erreur s'est produite lors de la suppression du membre"
              );
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(
              "Une erreur s'est produite lors de la suppression du fichier"
            );
          });
      }
    });
  }




  return (
    <div className="min-h-[75vh] flex flex-col items-center">
      <div className="w-full px-5 rounded-b-lg flex flex-col sm:flex-row items-center justify-between border-b border-gray-300">
        <div className="flex flex-wrap py-1 items-center mt-4 sm:mt-0">
          <input
            type="text"
            placeholder="Rechercher..."
            className="p-2 border border-gray-300 bg-transparent rounded-md focus:outline-none"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button
            className="px-4 py-2 text-white bg-blue-500  rounded-md hover:bg-blue-600 focus:outline-none"
            onClick={handleSearch}
          >
            Rechercher
          </button>
          <select
            className="p-2 border  border-gray-300 bg-transparent rounded-md focus:outline-none ml-4"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            <option className="text-black" value="5">5 par page</option>
            <option className="text-black" value="10">10 par page</option>
            <option className="text-black" value="20">20 par page</option>
          </select>
        </div>
        <Link to='/AdminMembers/AjouterMembers' className='bg-orange-500 hover:text-white hover:no-underline text-white focus:text-white text-[12px] focus:no-underline sm:text-[17px] rounded h-max w-max px-2 py-1 mt-4 sm:mt-0'>
          Ajouter un membre
        </Link>
      </div>





      <div className="mt-4 w-full">
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto wid">
            <div class="inline-block min-w-full shadow rounded-lg">
              <table class="min-w-full border leading-normal  m-1 rounded-xl overflow-hidden">
                <thead>
                  <tr>
                    <th class="px-5 py-3 border-b-2 border-r-2 border-gray-200 text-left text-xs font-semibold  uppercase tracking-wider">
                      Profil
                    </th>
                    <th class="px-5 py-3 border-b-2 border-r-2 border-gray-200 text-left text-xs font-semibold  uppercase tracking-wider">
                      Email
                    </th>
                    <th class="px-5 py-3 border-b-2 border-r-2 border-gray-200 text-left text-xs font-semibold  uppercase tracking-wider">
                      Tel
                    </th>
                    <th class="px-5 py-3 border-b-2 border-r-2 border-gray-200 text-left text-xs font-semibold  uppercase tracking-wider">
                      Fonction
                    </th>
                    <th class="px-5 py-3 border-b-2 border-r-2 border-gray-200 text-left text-xs font-semibold  uppercase tracking-wider">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listoffmembers.length > 0 ?
                    listoffmembers.map((value, position) => {
                      return (
                        <tr>
                          <td class="p-2 border-b border-gray-200  border-r text-sm">
                            <div class="flex items-center">
                              <div key={position} class="flex-shrink-0 border  w-10 h-10 overflow-hidden relative">
                                <img
                                  src={`https://irakoze.burundientempsreel.com/uploads/membres/${value.file}`} className="w-full h-full border-none object-contain"
                                />
                              </div>
                              <div class="ml-3">
                                <p class=" whitespace-no-wrap">
                                  {value.nom_complet}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td class="p-2 border-b border-gray-200  border-r text-sm">
                            <p class=" whitespace-no-wrap">
                              {value.email}
                            </p>
                          </td>
                          <td class="p-2 border-b border-gray-200 border-r  text-sm">
                            <p class=" whitespace-no-wrap">
                              {value.tel}
                            </p>
                          </td>
                          <td class="p-2 border-b border-gray-200 border-r  text-sm">
                            <p class=" whitespace-no-wrap">
                              {value.fonction}
                            </p>
                          </td>
                          <td class="p-2 border-b border-gray-200 border-r  text-sm">
                            <td class="Action">
                              <span class="contIcon">
                                <div class=" w-[7em] h-[3em]">
                                  <Link
                                    to={`/AdminMembers/Members/Modifier/${value.id}`}
                                    class="link bi bi-pencil-square p-2 m-1 hover:bg-green-600 bg-green-400 transition-all rounded text-stone-50"
                                    title="Modifier"
                                  ></Link>
                                  <button
                                    onClick={() =>
                                      handleDelete(value.id, position)
                                    }
                                    className="link bi bi-trash3-fill p-2 m-1 hover:bg-red-600 rounded bg-red-400 text-white"
                                    title="Supprimer"
                                  >
                                    <i class=""></i>
                                  </button>{" "}
                                </div>
                              </span>
                            </td>
                          </td>
                        </tr>
                      );
                    }) : (
                      <tr class="p-32  dark:bg-slate-600">
                        <td colspan={5} >
                          <div class="container flex flex-wrap justify-between items-center mx-auto  rounded-md dark:bg-slate-500">
                            <div class="text-center border-dashed border-2 border-slate-200 dark:border-slate-100 rounded-md w-full p-20">
                              <i class='bx bxs-contact bx-lg mb-5 dark:text-white'></i>
                              <p class="text-xl mb-2 uppercase font-bold dark:text-white">Pas de membre de l'ecole disponible</p>
                              <spam class="text-m text-slate-400 block mb-10 dark:text-slate-50"> Commencez par la créer un membre de l'ecole si est disponible </spam>
                              <Link to="/AdminMembers/AjouterMembers" class="bg-orange-400 rounded-full px-5 py-3 text-white hover:bg-orange-500 w-52">Créer</Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {listoffmembers.length > 0 && (
          <>
            <div className="w-full flex justify-center items-center">
              <div className="w-[25em] h-full flex justify-around items-center p-2">
                <button onClick={previousPage} disabled={currentPage === 1} className="transition-all flex cursor-pointer hover:p-2 rounded">
                  <i className="bi bi-arrow-left-circle-fill"></i>
                  <span>Précédent</span>
                </button>
                <button onClick={nextPage} disabled={currentPage === totalPages} className="transition-all flex cursor-pointer hover:p-2 rounded">
                  <span>Suivant</span>
                  <i className="bi bi-arrow-right-circle-fill"></i>
                </button>
              </div>
            </div>

            <div className="mt-4 flex items-center justify-center">
              <p className="text-gray-600">{getRange()}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AffchageMembre;
