/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../../Loading/Loading';
import { formatDate, chiffrement } from '../../../module';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types'
import EmpyServices from '../EmpyServeices/EmpyServeices';
import { Button, Modal } from 'rsuite';

function EvenementVoirPlusl() {


  const [isPending, setPending] = useState(true)
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [rechercher, setRechercher] = useState(false)
  const [totalElements, setTotalElements] = useState(0)
  const [events, setevents] = useState([]);
  const [isrechercher, setisRechercher] = useState(0)
  const [eventsDetail, GeteventsDetail] = useState({})
  const HundleClickElement = event => {
    GeteventsDetail(event)
  }
  useEffect(() => {
    const fetchevents = async () => {
      try {
        const response = await axios.get(`https://irakoze.burundientempsreel.com/api/getallValideEvenentClients?page=${currentPage}&pageSize=${pageSize}&searchTerm=${searchTerm}`);
        setevents(response.data.events);
        GeteventsDetail(response.data.events[0]);
        settotalPages(response.data.totalPages);
        setTotalElements(response.data.totalElements);
        setPending(false)
      } catch (error) {
        console.error('Erreur lors de la récupération des events :', error);
        setPending(false)
      }
    };

    fetchevents();
  }, [pageSize, currentPage, isrechercher]);








  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


















  const [mobile1, setMobille1] = useState(window.innerWidth < 1178);
  const [mobile2, setMobille2] = useState(window.innerWidth < 627);
  const [mobile3, setMobille3] = useState(window.innerWidth < 443);
  const [mobile4, setMobille4] = useState(window.innerWidth < 504);
  useEffect(() => {
    const hundleSize = () => {
      setMobille1(window.innerWidth < 1178)
      setMobille2(window.innerWidth < 627)
      setMobille3(window.innerWidth < 443)
      setMobille4(window.innerWidth < 504)
    }
    window.addEventListener('resize', hundleSize)

    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  })






  const LoadingImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };
    const lazyLoad = () => {
      setImgSrc(src);
    };



    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LoadingImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };
  const [openZomm, SetOpenZomm] = useState(false)
  const [datas, Setdatas] = useState('')

  return (
    <>
      {isPending && <Loading />}
      <div className="w-full flex  mt-1 justify-center flex-col items-center ">
        <div className='p-2 w-full min-h-[20em] '>
          <div className="  w-full   rounded-lg  flex flex-col mt-[20px]  justify-center items-center ">
            <div className={`flex w-full ${mobile1 ? 'flex-col' : ''}  p-2`}>
              <div className={`flex flex-col items-center   ${mobile1 ? 'w-full' : 'w-[85%] '}`}>
                <div className="grid grid-cols-1 w-full gap-4">
                  <div className=" w-full rounded-lg">
                    <h3 className="font-bold text-3xl first-letter:uppercase mb-1">
                      {eventsDetail.titre}
                    </h3>
                    <div className="flex justify-between mb-1">
                      <div className="flex text-xs items-center">
                        <span>{formatDate(eventsDetail.createdAt)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 md:col-span-2">
                    {eventsDetail.evenementcontents && eventsDetail.evenementcontents.length > 0 && eventsDetail.evenementcontents.map((content, index) => (
                      <div key={index} className="mb-8">
                        <h3 className="font-bold text-xl first-letter:uppercase mb-4 
                          pb-4">
                          {index + 1}.  {content.soustitre}
                        </h3>
                        {content.link && (
                          <div className=" mb-4 h-[40vh] sm:h-[60vh] md:h-[85vh] rounded-lg overflow-hidden object-cover object-center w-full">
                            <ReactPlayer
                              className="w-full inline h-full"
                              url={content.link}
                              controls
                            />
                          </div>
                        )}
                        {content.photo && (
                          <div className="mb-4">
                            <img
                              className="w-full h-auto rounded border"
                              src={`https://irakoze.burundientempsreel.com/uploads/evenements/${content.photo}`}
                              alt=""
                            />
                          </div>
                        )}
                        <p className="mb-4 pl-5">{content.descriptions}</p>
                      </div>
                    ))}
                  </div>
                </div>


                <div className='w-[99%] mt-5'>
                  <div className="flex flex-wrap gap-4 mt-2">
                    {eventsDetail.evenementfiles && eventsDetail.evenementfiles.length > 0 && eventsDetail.evenementfiles.map((photo, index) => (
                      <div key={index} onClick={() => { Setdatas(photo.file); SetOpenZomm(true) }} className="relative cursor-pointer w-32 h-32  border border-red-600 rounded-lg overflow-hidden">
                        <img
                          src={`https://irakoze.burundientempsreel.com/uploads/evenements/${photo.file}`}
                          className="w-full h-full object-cover"
                          alt=" "
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <Modal size="lg" dialogClassName='paddBottom' overflow={false} open={openZomm} onClose={() => SetOpenZomm(false)}>
                  <Modal.Header>
                    <Modal.Title>{eventsDetail.titre}</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <img
                      src={`https://irakoze.burundientempsreel.com/uploads/evenements/${datas}`}
                      className="w-full h-full object-cover"
                      alt=" "
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => SetOpenZomm(false)} appearance="primary">
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>


              <div className={`${mobile1 ? 'w-full' : 'w-[15%] '} `}>
                <div className='flex text-gray-500 m-1'>Evenement populaire</div>
                {events.length > 0 ? (
                  <>
                    <div className={`flex  flex-wrap justify-start bg-white gap-2`}>
                      {events.map((event, index) => {
                        return (
                          <div key={index} onClick={() => HundleClickElement(event)} className={` border m-2   overflow-hidden w-max    rounded-lg bg-white     p-1`}>
                            <Link className="w-full  mt-1 flex items-center flex-col p-3 cursor-pointer">
                              <div className='font-semibold  w-full text-[20px] text-start'>{event.titre}</div>
                              <span className='font-sans text-[13px] w-full text-orange-700'>Le {formatDate(event.createdAt)}</span>
                            </Link>
                          </div>
                        );
                      })}
                    </div>

                    {events.length > 0 && (
                      <>
                        <div className="w-full flex justify-center items-center">
                          <div className="w-[25em] h-full flex justify-around items-center p-2">
                            <button onClick={previousPage} disabled={currentPage === 1} className="transition-all flex cursor-pointer hover:p-2 rounded">
                              <i className="bi bi-arrow-left-circle-fill"></i>
                              <span>Précédent</span>
                            </button>
                            <button onClick={nextPage} disabled={currentPage === totalPages} className="transition-all flex cursor-pointer hover:p-2 rounded">
                              <span>Suivant</span>
                              <i className="bi bi-arrow-right-circle-fill"></i>
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </>) : (
                  <div className='flex  flex-col items-center w-[50em] border border-orange-600 p-3 rounded-lg bg-slate-100 h-[50vh] widthsssssssss'>
                    <EmpyServices items="d'événement" />

                  </div>
                )
                }
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}
export default EvenementVoirPlusl
