import React from 'react'

function Objectif() {
    return (
        <div className='w-full h-max flex justify-center items-center'>
            <div className='w-[90%]'>
                <h2 className='sm:text-[30px] text-[19px]  text-center  px-4 p-1 my-2 text-orange-400'>Objectif</h2>
                <p className='text-gray-600 sm:text-[18px] text-[15px]'>
                    <div className='text-[20px] font-bold'>Les objectifs d'une école d'excellence sont les suivantes: </div>
                    <p>
                        - Promouvoir un enseignement de qualité par la création d'un environnement favorable d'enseignement-apprentissage en mathématique, sciences, technologie, ingénierie, lettres et arts.
                    </p>
                    <p>
                        - Favoriser l'égalité des chances, l'éclosion des potentialités dans les mêmes conditions d'enseignement, selon le mérite, dans une juste compétition pour accéder aux filières de formation définies.
                    </p>
                    <p>
                        - Développer chez les élèves des compétences variées par une formation scientifique solide en mathématiques, sciences physiques et chimiques, sciences de la vie et de la terre, TIC et informatique, en vue de résoudre des situations-problèmes significatives.
                    </p>
                    <p>
                        - Faire acquérir aux élèves des attitudes de citoyenneté responsable et de vivre ensemble en référence aux valeurs de paix, de justice et au travers de bonnes pratiques de gouvernance scolaire.
                    </p>
                    <p>
                        - Développer chez les élèves l'esprit de créativité, d'innovation et de compétition positive.
                    </p>
                </p>

            </div>
        </div>
    )
}

export default Objectif
