/* eslint-disable jsx-a11y/img-redundant-alt */
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { encryptData } from "../../../../encryptionModule";
import { formatDate } from "../../../../module";


function DetailEventAdmin() {
    const { id } = useParams();
    const history = useNavigate("");
    const [isLoading, setIsLoading] = useState(true);
    const [event, setEvent] = useState({});
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        axios
            .get(`https://irakoze.burundientempsreel.com/api/getonevent/${id}`)
            .then((response) => {
                setEvent(response.data);
                setPhotos(response.data.evenementfiles);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error.message);
                setIsLoading(false);
            });
    }, [id]);

    function handleDelete(id, position) {
        Swal.fire({
            title: 'Confirmation',
            text: 'Êtes-vous sûr de vouloir supprimer cette photo ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'swal-confirm-button',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`https://irakoze.burundientempsreel.com/api/eventfile/ById/${id}`)
                    .then((response) => {
                        if (response.status === 200) {
                            const updatedPhotos = [...photos];
                            updatedPhotos.splice(position, 1);
                            setPhotos(updatedPhotos);
                            toast.success('La photo a été supprimée avec succès');
                        } else {
                            toast.error("Une erreur s'est produite lors de la suppression de la photo");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error("Une erreur s'est produite lors de la suppression de la photo");
                    });
            }
        });
    }

    const hendleUpdateEvent = (id) => {
        Swal.fire({
            title: "Êtes-vous sûr ?",
            text: "Êtes-vous sûr de vouloir désactiver cet événement ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
            reverseButtons: true,
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .put(`https://irakoze.burundientempsreel.com/api/UpdateEvent/${id}/${0}`)
                    .then(() => {
                        toast.success("Événement désactivé avec succès.");
                        history("/AdminEvent/Evenement");
                    })
                    .catch((error) => {
                        console.error(error.message);
                        toast.error("Une erreur est survenue lors de la suppression de l'événement");
                    });
            }
        });
    };

    const hendleUpdateEventContent = (id) => {
        Swal.fire({
            title: "Êtes-vous sûr ?",
            text: "Êtes-vous sûr de vouloir supprimer ce contenu de l'événement ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
            reverseButtons: true,
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`https://irakoze.burundientempsreel.com/api/deleteevenementcontents/${id}`)
                    .then(() => {
                        toast.success("Contenu de l'événement supprimé avec succès");
                        setEvent((prevEvent) => ({
                            ...prevEvent,
                            evenementcontents: prevEvent.evenementcontents.filter((content) => content.id !== id),
                        }));
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error("Une erreur est survenue lors de la suppression du contenu de l'événement");
                    });
            }
        });
    };
    const [mobile, getMobile] = useState(window.innerWidth < 350)

    useEffect(() => {
        const HulndleSize = () => {
            getMobile(window.innerWidth < 350)
        }
        window.addEventListener('resize', HulndleSize)
        return () => window.removeEventListener('resize', HulndleSize)
    }, [])


    return (
        <div className="container mx-auto p-1 sm:p-4">
            <Link to="/AdminEvent/Evenement">retour</Link>
            {isLoading ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="p-1 sm:p-4 bg-white rounded-lg">
                        <div className="flex justify-between mb-1 sm:mb-4">
                            <div className="flex items-center">
                                <Skeleton circle={true} width={12} height={12} />
                                <Skeleton width={20} height={12} className="ml-2" />
                            </div>
                            <Skeleton width={20} height={12} />
                        </div>
                        <h1 className="font-bold mb-1 sm:mb-4">
                            <Skeleton width={64} height={8} />
                        </h1>
                        <div className="aspect-w-16 aspect-h-9 mb-1 sm:mb-4">
                            <Skeleton />
                        </div>
                        <div className="aspect-w-16 aspect-h-9">
                            <Skeleton />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="grid grid-cols-1 gap-4">
                    <div className="p-1 sm:p-4 w-full rounded-lg">
                        <div className="flex justify-betweenmb-1 sm:mb-4">
                            <div className="flex items-center">
                                <span>{formatDate(event.createdAt)}</span>
                            </div>
                        </div>


                        <h3 className="font-bold text-3xl first-letter:uppercase mb-2 sm:mb-6">
                            {event.titre}
                        </h3>
                        <h5 class="mb-2 text-sm font-bold tracking-tight text-gray-500 dark:text-white">
                            Validation {event.valide === 1 ? <i class="bi bi-check-circle-fill text-green-500"></i> : <i class="bi  bi-x-circle-fill text-red-500"></i>}
                        </h5>
                        <div className={`flex ${mobile ? 'flex-col w-full  gap-2' : 'items-center  justify-start'}   mt-10 border-b border-gray-300 pb-4 mb-4`}>
                            <div>
                                <Link
                                    to={`/AdminEvent/Evenement/Editor/${encryptData(event.id.toString())}`}
                                    className=" mx-3"
                                >
                                    Editer
                                </Link>
                                {event.valide === 1 ? <button
                                    onClick={() => hendleUpdateEvent(event.id)}
                                    className="mx-3 text-red-600 font-extrabold  py-2 transition-all rounded"
                                >
                                    désactiver
                                </button> : ''
                                }
                            </div>

                            <Link
                                to={`/AdminEvent/Evenement/Ajouter/${encryptData(event.id.toString())}`}
                                className="mx-3"
                            >
                                Ajouter plus de photos
                            </Link>
                        </div>
                    </div>
                    <div className="col-span-1 md:col-span-2">
                        {event.evenementcontents.map((content, index) => (
                            <div key={index} className="mb-8">
                                <h3 className="font-bold text-xl first-letter:uppercase mb-4 border-b border-gray-300 pb-4">
                                    {index + 1}.  {content.soustitre}
                                </h3>
                                {content.link && (
                                    <div className=" mb-4 sm:h-[80vh] h-[60vh] rounded-lg overflow-hidden object-cover object-center w-full">
                                        <ReactPlayer
                                            className="w-full inline h-full"
                                            url={content.link}
                                            controls
                                        />
                                    </div>
                                )}
                                {content.photo && (
                                    <div className="mb-4">
                                        <img
                                            className="w-full h-auto rounded border"
                                            src={`https://irakoze.burundientempsreel.com/uploads/evenements/${content.photo}`}
                                            alt=""
                                        />
                                    </div>
                                )}
                                <p className="mb-4 pl-5">{content.descriptions}</p>
                                <div className="flex items-center mt-4 justify-start">
                                    <Link
                                        to={`/AdminEvent/Evenement/EditEventPart/${encryptData(content.id.toString())}`}
                                        className="mx-3"
                                    >
                                        Editer
                                    </Link>
                                    <button
                                        onClick={() => hendleUpdateEventContent(content.id)}
                                        className="text-red-600 cursor-pointer  py-1 px-2"
                                    >
                                        Supprimer
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-8">
                {photos.map((photo, index) => (
                    <div key={index} className="relative w-full border rounded-lg overflow-hidden">
                        <img
                            src={`https://irakoze.burundientempsreel.com/uploads/evenements/${photo.file}`}
                            className="w-full h-full object-cover"
                            alt={`Photo ${index + 1}`}
                        />
                        <button
                            onClick={() => handleDelete(photo.id, index)}
                            className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 hover:bg-red-600 transition duration-300"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi h-5 w-5 bi-trash3-fill" viewBox="0 0 16 16">
                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                            </svg>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DetailEventAdmin;
