import React from 'react'
import AddMoreAdminEvent from './AddMoreAdminEvent'
function AddMoreAdminEventComponent() {
    return (
        <>
            <AddMoreAdminEvent />
        </>
    )
}

export default AddMoreAdminEventComponent
