import React from 'react'

function EmpyServices({ items }) {
    return (

        <div className='text-gray-500 text-[25px] text-center'>
            Pas  {items} disponibles publiés par le Lycée d'Excellence NGAGARA correspondant à la recherche.
        </div>

    )
}

export default EmpyServices