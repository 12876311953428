import React from 'react'

import AffchageMembre from './AffchageMembre'
function AffichageMembresComponent() {
  return (
    <>
      <AffchageMembre />
    </>
  )
}

export default AffichageMembresComponent
