/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import { InView } from 'react-intersection-observer';
import { tableauObjets } from './DataUseed';
function EvenementComposeTitreDetail() {
    const [mobile, SetMobile] = useState(window.innerWidth < 600);
    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 600)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const [visibleItems, setVisibleItems] = useState([]);
    const [loadedItems, setLoadedItems] = useState([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1,
        };

        const handleIntersection = (position) => {
            setVisibleItems((prev) => [...prev, position]);
        };
        const intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const position = parseInt(entry.target.getAttribute('data-position'), 10);
                    handleIntersection(position);
                }
            });
        }, observerOptions);

        const elements = document.querySelectorAll('.blocks');

        elements.forEach((element) => {
            intersectionObserver.observe(element);
            return () => {
                intersectionObserver.unobserve(element);
            };
        });

        return () => {
            intersectionObserver.disconnect();
        };
    }, []);

    const handleImageLoad = (position) => {
        setLoadedItems((prev) => [...prev, position]);
    };



    return (
        <div className='  flex flex-col items-end'>
            <div className='w-full'>
                <div className='w-full px-5 rounded-b-lg flex items-center my-3 justify-between border-b border-gray-300 flex-row'>
                    <Link to='/AdminEvent/Evenement' className='bg-orange-500 mb-1  text-[17px] rounded h-max w-max px-2 py-1'>
                        Retour
                    </Link>
                    <Link to='/AdminEvent/AjoutEvenement' className='bg-orange-500 text-[17px] mb-1 rounded h-max w-max px-2 py-1'>
                        Ajouter un événement
                    </Link>
                </div>
                <div className='text-[25px] text-center'>Evenement compose</div>
                <div className="w-full h-max mb-5 flex-col items-center flex flex-wrap justify-center">

                    <div className="w-full h-max mb-5 p-1 flex flex-wrap justify-center">
                        <div className=' mx-2 my-4 w-[95%] border border-fuchsia-700 rounded-xl p-4'>
                            <Link to='/AdminEvent/Evenement' className='text-[30px]'>{tableauObjets[0].titre}</Link>
                            <div className='flex flex-wrap w-full'>
                                {tableauObjets[0].sous.map((dataSous, indexSous) => (
                                    <div className='w-[18em] m-2  border p-4 rounded-2xl  border-orange-700' key={indexSous}>
                                        <div className='w-full flex justify-center'>
                                            <Link to='/AdminEvent/EvenementComposeSousTitre/Detail'
                                                className='text-center first-letter:uppercase  font-serif p-1  text-[17px] mb-2'>
                                                {dataSous.sousTitre}
                                            </Link>
                                        </div>
                                        <div className='w-[16em] h-[16em] overflow-hidden border rounded-xl '>
                                            <img src={dataSous.photos} className=" inline object-cover object-center w-full h-full" />
                                        </div>
                                        <a>Le 06/03/2024</a>
                                        <div className=" my-3 text-[18px] overflow-hidden">
                                            {dataSous.description.length > 100 ? dataSous.description.slice(1, 100) + '...' : dataSous.description}
                                        </div>
                                        <div className="flex border-t pt-1 justify-between p-1 mt-5">
                                            <Link
                                                to={`/AdminEvent/EvenementComposeSousTitre/Detail/Modifier`}
                                                title="Modifier"
                                                className="bi bi-box-arrow-in-down-left flex justify-center items-center bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded"
                                            >
                                            </Link>
                                            <Link
                                                to={`/AdminEvent/EvenementComposeSousTitre/AddMore`}
                                                title="Ajouter les autre fichiers supplementaires de ce fichiers principal"
                                                className="transition-all bi bi-patch-plus-fill px-2 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded"
                                            ></Link>
                                            <button
                                                title="Supprimer"
                                                className="transition-all bi bi-trash-fill bg-red-500 hover:bg-red-600 text-white w-[30px] h-[30px] rounded"
                                            ></button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EvenementComposeTitreDetail
