/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { dechiffrement, formatDate, transformDate } from '../../../module';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types'
import Loading from '../../Loading/Loading';

function DetailleEvementVisitor() {
    const { idc } = useParams();
    const id = dechiffrement(idc);
    console.log(id);
    const [event, setEvent] = useState({});
    const [photo, setPhoto] = useState([]);
    const [isPending, setPending] = useState(true)

    useEffect(() => {
        axios.get(`https://irakoze.burundientempsreel.com/api/getonevent/${id}`).then((response) => {
            setEvent(response.data);
            setPhoto(response.data.evenementfiles);
            setTimeout(() => {
                setPending(false)
            }, 100)
        });
    }, [id]);




    const [mobile, SetMobile] = useState(window.innerWidth < 935)

    useEffect(() => {

        const hundleSize = () => {
            SetMobile(window.innerWidth < 935)
        }

        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }

    }, [])




    const [loadingImages, setLoadingImages] = useState([]);
    const [loadingImage, setLoadingImage] = useState(true);

    const handleImageLoad = (index) => {

        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            updatedLoadingImages[index] = true;
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 50000);
            return updatedLoadingImages;
        });

    };





    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };
    const [mobile1, setMobille1] = useState(window.innerWidth < 1178);
    return (


        <>
            {isPending && <Loading />}
            <div className='w-ful'>
                <Link to='/Visit/Evenement' className='flex h-8 items-center ml-5 bg-orange-500 text-white w-max p-2 mt-4 rounded'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-return-left mr-2" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                    </svg>
                    <p>Retour</p>
                </Link>
            </div>
            {event ? (
                <div className={`flex flex-col items-center ${mobile1 ? 'w-[95%] ' : 'w-[80%] '}`}>
                    <div className="grid grid-cols-1 gap-4">
                        <div className="p-4  w-full rounded-lg">
                            <div className="flex justify-between mb-4">
                                <div className="flex items-center">
                                    <span>{formatDate(event.createdAt)}</span>
                                </div>
                            </div>


                            <h3 className="font-bold text-3xl first-letter:uppercase mb-6">
                                {event.titre}
                            </h3>

                        </div>
                        <div className="col-span-1 md:col-span-2">
                            {event.evenementcontents && event.evenementcontents.length > 0 && event.evenementcontents.map((content, index) => (
                                <div key={index} className="mb-8">
                                    <h3 className="font-bold text-xl first-letter:uppercase mb-4 border-b border-gray-300 pb-4">
                                        {index + 1}.  {content.soustitre}
                                    </h3>
                                    {content.link && (
                                        <div className=" mb-4 sm:h-[80vh] h-[60vh] rounded-lg overflow-hidden object-cover object-center w-full">
                                            <ReactPlayer
                                                className="w-full inline h-full"
                                                url={content.link}
                                                controls
                                            />
                                        </div>
                                    )}
                                    {content.photo && (
                                        <div className="mb-4">
                                            <img
                                                className="w-full h-auto rounded border"
                                                src={`https://irakoze.burundientempsreel.com/uploads/evenements/${content.photo}`}
                                                alt=""
                                            />
                                        </div>
                                    )}
                                    <p className="mb-4 pl-5">{content.descriptions}</p>

                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='w-[99%] mt-5'>

                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-8">
                            {event.evenementfiles && event.evenementfiles.length > 0 && event.evenementfiles.map((photo, index) => (
                                <div key={index} className="relative w-full border rounded-lg overflow-hidden">
                                    <img
                                        src={`https://irakoze.burundientempsreel.com/uploads/evenements/${photo.file}`}
                                        className="w-full h-full object-cover"
                                        alt=" "
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (<div>Erreur</div>)}

        </>

    )
}

export default DetailleEvementVisitor
