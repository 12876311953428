import React from 'react'
import DetailsEventVisitorComponent from '../../../component/Visitor/Evenement/DetailsEventVisitorComponent'

function DetailEventVisitoPage() {


    return (
        <DetailsEventVisitorComponent />
    )
}

export default DetailEventVisitoPage
