import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Profile() {

  const id = localStorage.getItem('v');
  const [user, setUser] = useState({});
  useEffect(() => {
    if (id) {
      axios.get(`https://irakoze.burundientempsreel.com/api/usergetprofilekk/${id}`).then((response) => {
        setUser(response.data);
      }).catch((err) => {
        console.log(err.message)
      })
    }
  }, [id])



  const [mobiles, SetMbiles] = useState(window.innerWidth < 471)

  useEffect(() => {
    const HundleSize = () => {
      SetMbiles(window.innerWidth < 471)
    }

    window.addEventListener('resize', HundleSize)
    return () => window.removeEventListener('resize', HundleSize)
  }, [])


  return (
    <div className='w-full h-full flex justify-start flex-col items-center'>
      <div className='w-full px-5 rounded-b-lg flex items-center justify-between border-b border-gray-300 flex-row'>
        <h2 className={`${mobiles ? 'text-[12px] hidden' : 'text-lg  '} font-semibold`}>Votre donner de compte</h2>
        <Link to='/' className={`bg-orange-500 ${mobiles ? 'text-[12px]' : 'text-[17px] '} my-2 rounded h-max w-max px-2 py-1`}>
          Aller à la page d'accueil
        </Link>
      </div>
      <div className="flex flex-col mt-4 mx-2 items-center border border-orange-600 rounded-xl p-2 max-w-[30em] h-max ">
        <div className='text-[23px]'>Votre donner de compte</div>
        <div className="  p-2 rounded-xl overflow-hidden  m-1 w-[10em] h-[10em]" >
          <img src={`https://irakoze.burundientempsreel.com/uploads/photosuser/${user.Profile}`} alt="" className=' w-full h-full' />
        </div>
        <div className="w-full">
          <div className='text-[18px] font-sans'>
            <p>Nom: {user.nom}</p>
            <p>Prenom: {user.prenom}</p>
            <p>Tel: +209 790 322 829</p>
            <p>Email: telecomtelesphore@gmail.com</p>
            <p>Votre genre:Masculin</p>
          </div>
        </div>
        <div className="w-full flex justify-end ">
          <Link to='/' className='bg-green-400 mr-6 text-white p-1 rounded hover:bg-green-600 transition-all'>Retour</Link>
          <Link to="/Admin/Profil/Modifier" className='bg-orange-400 text-white p-1 rounded hover:bg-orange-600 transition-all'>Modifier</Link>
        </div>
      </div>
    </div>
  )
}

export default Profile


