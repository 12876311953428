import React from 'react'
import RessourceComponent from '../../../component/Visitor/Ressource/RessourceComponent'
function RessourcePage() {


    return (
        <RessourceComponent />
    )
}

export default RessourcePage
