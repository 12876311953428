import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-toastify";
import { FiFileText, FiImage } from 'react-icons/fi';
import { Button, Modal } from 'rsuite';
const ClasseDetail = () => {
    const { id } = useParams();
    const [classe, setClasse] = useState(null);
    const [existingEleve, setExistingEleve] = useState(null);
    const [showAddEleveForm, setShowAddEleveForm] = useState(false);
    const [showAddNoteForm, setShowAddNoteForm] = useState(false);
    const [newEleve, setNewEleve] = useState({ nombrefilles: 0, nombregarcons: 0, classseId: id });
    const [newNote, setNewNote] = useState({ titre: '', description: '', fichier: '', classseId: id });
    const [showUpdateEleveForm, setShowUpdateEleveForm] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://irakoze.burundientempsreel.com/api/classesDetail/${id}`);
                setClasse(response.data);

                // Initialise les valeurs existantes des élèves si elles existent
                if (response.data.elefe) {
                    setExistingEleve(response.data.elefe);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des détails de la classe :', error);
            }
        };

        fetchData();
    }, [id]);

    const handleAddEleve = async (e) => {
        e.preventDefault();
        if (newEleve.nombrefilles <= 0 || newEleve.nombregarcons <= 0) {
            toast.error("Le nombre d'élèves doit être supérieur à zéro");
            return;
        }
        try {
            await axios.post('https://irakoze.burundientempsreel.com/api/eleves', newEleve);
            setNewEleve({ nombrefilles: 0, nombregarcons: 0 });
            setShowAddEleveForm(false);
            window.location.reload();
            toast.success('La création a été effectuée avec succès');
        } catch (error) {
            console.error('Erreur lors de l\'ajout de l\'élève :', error);
            toast.error("Erreur lors de l'ajout de nombre d'élèves");
        }
    };

    const handleUpdateEleve = async (e) => {
        e.preventDefault();
        try {
            // Validation du formulaire
            if (existingEleve.nombrefilles <= 0 || existingEleve.nombregarcons <= 0) {
                toast.error("Le nombre d'élèves doit être supérieur à zéro");
                return;
            }

            // Envoi de la requête PUT pour mettre à jour les élèves
            await axios.put(`https://irakoze.burundientempsreel.com/api/eleves/${id}`, existingEleve);
            setExistingEleve({ nombrefilles: 0, nombregarcons: 0 });
            setShowUpdateEleveForm(false);
            window.location.reload();
            toast.success('La mise à jour a été effectuée avec succès');
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'élève :', error);
            toast.error("Erreur lors de la mise à jour du nombre d'élèves");
        }
    };

    const handleAddNote = async (e) => {
        e.preventDefault();

        // Validation du titre
        if (!newNote.titre) {
            toast.error('Veuillez entrer un titre');
            return;
        }

        // Validation de la description
        if (!newNote.description) {
            toast.error('Veuillez entrer une description');
            return;
        }

        // Validation du fichier
        if (!newNote.fichier) {
            toast.error('Veuillez sélectionner un fichier');
            return;
        }

        const formData = new FormData();
        formData.append('titre', newNote.titre);
        formData.append('description', newNote.description);
        formData.append('fichier', newNote.fichier);
        formData.append('classseId', newNote.classseId);

        try {
            await axios.post('https://irakoze.burundientempsreel.com/api/notes', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setNewNote({ titre: '', description: '', fichier: '', classseId: id });
            toast.success('La création a été effectuée avec succès')
            window.location.reload();
        } catch (error) {
            if (error.response && error.response.data.errors) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    if (err.field === 'titre') {
                        toast.error(err.message);
                    } else if (err.field === 'description') {
                        toast.error(err.message);
                    } else if (err.field === 'fichier') {
                        toast.error(err.message);
                    }
                });
            } else {
                toast.error('Erreur lors de l\'ajout de la note');
            }
        }
    };





    const [isFullScreen, setIsFullScreen] = useState(false);
    const [showUpdateNoteForm, setShowUpdateNoteForm] = useState(false);
    const [updatedNote, setUpdatedNote] = useState({
        titre: '',
        description: '',
        fichier: '',
        classseId: ''
    });
    const [ischange, setIsChange] = useState(false)
    const handleEditNote = (index) => {
        // Pré-remplir le formulaire de mise à jour avec les valeurs de la note à modifier
        const noteToUpdate = notes[index];
        setUpdatedNote({
            id: noteToUpdate.id, // Ajoutez l'ID de la note à mettre à jour
            titre: noteToUpdate.titre,
            description: noteToUpdate.description,
            classseId: noteToUpdate.classseId,
            fichier: noteToUpdate.fichier
        });
        setShowUpdateNoteForm(true);
    };
    const hideAllForms = () => {
        setShowAddEleveForm(false);
        setShowAddNoteForm(false);
        setShowUpdateEleveForm(false)
        setShowUpdateNoteForm(false)
    };
    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    const toggleUpdateNoteForm = () => {
        setShowUpdateNoteForm(!showUpdateNoteForm);
    };

    const handleUpdateNote = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('titre', updatedNote.titre);
        formData.append('description', updatedNote.description);
        formData.append('classseId', updatedNote.classseId);
        if (updatedNote.fichier) {
            formData.append('fichier', updatedNote.fichier);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        axios.put(`https://irakoze.burundientempsreel.com/api/notes/${updatedNote.id}`, formData, config)
            .then((response) => {
                console.log('Réponse de la mise à jour de la note :', response.data);
                toast.success('Note mise à jour avec succès !');
                hideAllForms()
                window.location.reload();
            })
            .catch((error) => {
                console.error('Erreur lors de la mise à jour de la note :', error);
                if (error.response) {
                    // Si une réponse est reçue du serveur avec un code d'erreur, afficher le message d'erreur du serveur
                    toast.error(error.response.data.error);
                } else {
                    // Si une erreur inattendue se produit, afficher un message générique
                    toast.error('Erreur lors de la mise à jour de la note. Veuillez réessayer.');
                }
            });
    };

    // Styles CSS pour la grille
    const gridContainerStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '20px',
    };



    if (!classe) {
        return <div>Loading...</div>;
    }

    const { elefe, notes } = classe;

    return (
        <div className="p-4 min-h-[85vh]">
            <Link to="/AdminEnseignant">&larr; Retour</Link>
            <h2 className="text-2xl font-bold my-4">{classe.nom}</h2>
            <p>Année scolaire: {classe.anneescolaire ? classe.anneescolaire.nom : 'Non spécifiée'}</p>
            {/* Section des élèves */}
            <div className="border rounded-lg p-4 mb-3">
                <div className="flex justify-between flex-wrap items-center">
                    <div>
                        <h3 className="text-lg font-bold">Élèves</h3>
                    </div>
                    <div>
                        {elefe ? (
                            <button onClick={() => setShowUpdateEleveForm(!showUpdateEleveForm)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 sm:py-2 px-1  sm:px-4 rounded">
                                Modifier le nombre
                            </button>
                        ) : (
                            <button onClick={() => setShowAddEleveForm(!showAddEleveForm)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 sm:py-2 px-1  sm:px-4  rounded">
                                Ajouter le nombre
                            </button>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <p>Nombre total d'élèves : {elefe ? elefe.nombregarcons + elefe.nombrefilles : 0}</p>
                    <div className="font-semibold text-blue-500">
                        Garçons: {elefe ? elefe.nombregarcons : 0} ~ {(elefe ? (elefe.nombregarcons / (elefe.nombregarcons + elefe.nombrefilles)) * 100 : 0).toFixed(1)}%
                    </div>
                    <div className="font-semibold text-blue-500">
                        Filles: {elefe ? elefe.nombrefilles : 0} ~ {(elefe ? (elefe.nombrefilles / (elefe.nombregarcons + elefe.nombrefilles)) * 100 : 0).toFixed(1)}%
                    </div>
                </div>
            </div>

            {/* Section des notes */}
            <div className="p-4 border rounded-lg">
                <div className="flex justify-between flex-wrap-reverse items-center">
                    <div>
                        <h3 className="text-lg font-bold mb-4">Notes publiées</h3>
                    </div>
                    <div>
                        <button onClick={() => setShowAddNoteForm(!showAddNoteForm)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 sm:py-2 px-1  sm:px-4 rounded">
                            Ajouter une note
                        </button>
                    </div>
                </div>

                <div className="grid" style={gridContainerStyle}>
                    {notes.map((note, index) => (
                        <div key={index} className="flex flex-col w-[15em] items-center border border-gray-200 rounded-lg shadow">
                            {note.fichier && (
                                <div className="">
                                    {note.fichier.toLowerCase().endsWith('.pdf') ? (
                                        <a href={`https://irakoze.burundientempsreel.com/uploads/enseignement/${note.fichier}`} target="_blank" rel="noopener noreferrer">
                                            <FiFileText className="absolute top-0 right-0 mr-2 mt-2  text-white cursor-pointer" />
                                        </a>
                                    ) : note.fichier.toLowerCase().match(/\.(jpeg|jpg|png|gif)$/) ? (
                                        <div onClick={toggleFullScreen} className="absolute top-0 text-white right-0 mr-2 mt-2  cursor-pointer">
                                            <FiImage />
                                        </div>
                                    ) : (
                                        <span>Fichier non pris en charge</span>
                                    )}


                                    <Modal dialogClassName='paddBottom' overflow={false} size='99%' open={note.fichier.toLowerCase().match(/\.(jpeg|jpg|png|gif)$/) && isFullScreen} onClose={toggleFullScreen}>
                                        <Modal.Header>
                                            <Modal.Title className='uppercase text-[250px] u text-center'>{note.titre}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <img className="w-full h-full object-cover" src={`https://irakoze.burundientempsreel.com/uploads/enseignement/${note.fichier}`} alt="     " />
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button onClick={toggleFullScreen} appearance="subtle">
                                                Cancel
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>




                                    <img className={`object-cover w-max rounded-t-lg h-max md:h-auto md:w-48  rounded-s-lg cursor-pointer ${isFullScreen && 'hidden'}`}
                                        src={`https://irakoze.burundientempsreel.com/uploads/enseignement/${note.fichier}`}
                                        alt="" />
                                </div>
                            )}
                            <div className="flex flex-col justify-between p-4">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight ">{note.titre}</h5>
                                <p className="mb-3 font-normal">{note.description}</p>
                                <button onClick={() => handleEditNote(index)} className="bg-blue-500 hover:bg-blue-700  font-bold py-2 px-4 rounded">
                                    Modifier la note
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Modal dialogClassName='paddBottom' overflow={false} size='lg' open={showAddEleveForm} onClose={hideAllForms}>
                <Modal.Header>
                    <Modal.Title className='uppercase text-[250px] u text-center'>Ajouter Nombre élèves</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleAddEleve}>
                        <div className="w-full mb-5">
                            <label className="block tracking-wide text-lg mb-2" htmlFor="grid-Title">
                                Nombre des filles
                            </label>
                            <input
                                className={`block w-full bg-transparent outline-none focus:border focus:border-fuchsia-500 border border-red rounded py-3 px-4 mb-3`}
                                type='number'
                                name='nombrefilles'
                                min='1'
                                value={newEleve.nombrefilles}
                                onChange={(e) => setNewEleve({ ...newEleve, nombrefilles: e.target.value })}
                                placeholder="nombrefilles"
                            />
                        </div>
                        <div className="w-full mb-5">
                            <label className="block tracking-wide text-lg mb-2" htmlFor="grid-Title">
                                Nombre des garçons
                            </label>
                            <input
                                className={`block w-full bg-transparent outline-none focus:border focus:border-fuchsia-500 border border-red rounded py-3 px-4 mb-3`}
                                type='number'
                                name='nombregarcons'
                                min='1'
                                value={newEleve.nombregarcons}
                                onChange={(e) => setNewEleve({ ...newEleve, nombregarcons: e.target.value })}
                                placeholder="nombregarcons"
                            />
                        </div>

                        <div className="flex justify-end items-center">
                            <button type="submit" className="w-max flex justify-end p-1 bg-orange-600 rounded cursor-pointer px-5 py-1 text-white">Ajouter</button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideAllForms} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal dialogClassName='paddBottom' overflow={false} size='xs' open={showUpdateEleveForm} onClose={hideAllForms}>
                <Modal.Header>
                    <Modal.Title className='uppercase text-[250px] u text-center'>Modifier Nombre élèves</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleUpdateEleve}>
                        <div className="w-full mb-5">
                            <label className="block tracking-wide text-lg mb-2" htmlFor="grid-Title">
                                Nombre des filles
                            </label>
                            <input
                                className={`block w-full bg-transparent outline-none focus:border focus:border-fuchsia-500 border border-red rounded py-3 px-4 mb-3`}
                                type='number'
                                name='nombrefilles'
                                min='1'
                                value={existingEleve.nombrefilles}
                                onChange={(e) => setExistingEleve({ ...existingEleve, nombrefilles: e.target.value })}
                                placeholder="nombrefilles"
                            />
                        </div>
                        <div className="w-full mb-5">
                            <label className="block tracking-wide text-lg mb-2" htmlFor="grid-Title">
                                Nombre des garçons
                            </label>
                            <input
                                className={`block w-full bg-transparent outline-none focus:border focus:border-fuchsia-500 border border-red rounded py-3 px-4 mb-3`}
                                type='number'
                                name='nombregarcons'
                                min='1'
                                value={existingEleve.nombregarcons}
                                onChange={(e) => setExistingEleve({ ...existingEleve, nombregarcons: e.target.value })}
                                placeholder="nombregarcons"
                            />
                        </div>

                        <div className="flex justify-end items-center">
                            <button type="submit" className="w-max flex justify-end p-1 bg-orange-600 rounded cursor-pointer px-5 py-1 text-white">Modifier</button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideAllForms} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal dialogClassName='paddBottom' overflow={false} size='lg' open={showAddNoteForm} onClose={hideAllForms}>
                <Modal.Header>
                    <Modal.Title className='uppercase text-[250px] u text-center'>Ajouter une Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleAddNote}>
                        <div className="w-full mb-5">
                            <label className="block tracking-wide text-lg mb-2" htmlFor="grid-Title">
                                Titre
                            </label>
                            <input
                                className={`block w-full bg-transparent outline-none focus:border focus:border-fuchsia-500 border border-red rounded py-3 px-4 mb-3`}
                                type='text'
                                name='nom'
                                value={newNote.titre}
                                onChange={(e) => setNewNote({ ...newNote, titre: e.target.value })}
                                placeholder="titre"
                            />
                        </div>
                        <div className="  flex items-center ">
                            <label className=" cursor-pointer border px-3 py-2 rounded w-full  tracking-wide text-lg mb-2 flex items-center" htmlFor={`photo`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-box-arrow-in-up mr-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1z" />
                                    <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708z" />
                                </svg>
                                Fichier (Image ou PDF)
                            </label>
                            <input
                                type="file"
                                accept="image/*,.pdf"
                                hidden
                                onChange={(e) => setNewNote({ ...newNote, fichier: e.target.files[0] })}
                                id={`photo`}
                            />
                        </div>




                        {newNote.fichier && (
                            <div className="mt-2">
                                {newNote.fichier.type.startsWith('image/') ? (
                                    <img src={URL.createObjectURL(newNote.fichier)} alt="Preview" className="max-w-full h-auto" />
                                ) : newNote.fichier.type === 'application/pdf' ? (
                                    <embed src={URL.createObjectURL(newNote.fichier)} type="application/pdf" width="100%" height="600px" />
                                ) : (
                                    <span>Fichier non pris en charge</span>
                                )}
                            </div>
                        )}

                        <div className="w-full mb-5">
                            <label className="block tracking-wide text-lg mb-1" htmlFor="grid-Title">
                                Description
                            </label>
                            <textarea
                                name='description'
                                value={newNote.description}
                                onChange={(e) => setNewNote({ ...newNote, description: e.target.value })}
                                placeholder=" La description"
                                className={`block w-full bg-transparent min-h-[15em]  resize-y outline-none focus:border focus:border-fuchsia-500 border border-red rounded py-3 px-4 mb-3`}
                            ></textarea>

                        </div>
                        <div className="flex justify-end items-center">
                            <button type="submit" className="w-max flex justify-end p-1 bg-orange-600 rounded cursor-pointer px-5 py-1 text-white">Ajouter</button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideAllForms} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal dialogClassName='paddBottom' overflow={false} size='lg' open={showUpdateNoteForm} onClose={toggleUpdateNoteForm}>
                <Modal.Header>
                    <Modal.Title className='uppercase text-[250px] u text-center'>Modifier la Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleUpdateNote}>

                        <div className="w-full mb-5">
                            <label className="block tracking-wide text-lg mb-2" htmlFor="grid-Title">
                                Titre
                            </label>
                            <input
                                className={`block w-full bg-transparent outline-none focus:border focus:border-fuchsia-500 border border-red rounded py-3 px-4 mb-3`}
                                type='text'
                                name='nom'
                                value={updatedNote.titre}
                                onChange={(e) => setUpdatedNote({ ...updatedNote, titre: e.target.value })}
                                placeholder="titre"
                            />
                        </div>
                        {/* Champ pour la description */}
                        <div className="w-full mb-5">
                            <label className="block tracking-wide text-lg mb-2" htmlFor="grid-Description">
                                Description
                            </label>
                            <textarea
                                className={`block w-full bg-transparent outline-none focus:border focus:border-fuchsia-500 border border-red rounded py-3 px-4 mb-3`}
                                name='description'
                                value={updatedNote.description}
                                onChange={(e) => setUpdatedNote({ ...updatedNote, description: e.target.value })}
                                placeholder="Description"
                            />
                        </div>
                        {/* Champ pour le fichier */}
                        <div className="flex items-center">
                            <label className=" cursor-pointer border px-3 py-2 rounded w-full  tracking-wide text-lg mb-2 flex items-center" htmlFor={`photo`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-box-arrow-in-up mr-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1z" />
                                    <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708z" />
                                </svg>
                                Fichier (Image ou PDF)
                            </label>
                            <input
                                type="file"
                                accept="image/*,.pdf"
                                hidden
                                onChange={(e) => { setUpdatedNote({ ...updatedNote, fichier: e.target.files[0] }); setIsChange(true) }}
                                id={`photo`}
                            />
                        </div>

                        {ischange ? (
                            <div className="mt-2">
                                {updatedNote.fichier && updatedNote.fichier.type && updatedNote.fichier.type.startsWith('image/') ? (
                                    <img src={URL.createObjectURL(updatedNote.fichier)} alt="Preview" className="max-w-full h-auto" />
                                ) : updatedNote.fichier && updatedNote.fichier.type === 'application/pdf' ? (
                                    <embed src={URL.createObjectURL(updatedNote.fichier)} type="application/pdf" width="100%" height="600px" />
                                ) : (
                                    <span>Fichier non pris en charge</span>
                                )}
                            </div>
                        ) : (
                            updatedNote.fichier && (
                                <div className="mt-2">
                                    {updatedNote.fichier.toLowerCase().endsWith('.pdf') ? (
                                        <embed src={`https://irakoze.burundientempsreel.com/uploads/enseignement/${updatedNote.fichier}`} type="application/pdf" width="100%" height="600px" />
                                    ) : updatedNote.fichier.toLowerCase().match(/\.(jpeg|jpg|png|gif)$/) && (
                                        <img src={`https://irakoze.burundientempsreel.com/uploads/enseignement/${updatedNote.fichier}`} alt="Preview" className="max-w-full h-auto" />
                                    )}
                                </div>
                            )
                        )}




                        <div className="flex justify-end items-center mt-5">
                            <button type="submit" className="w-max flex justify-end p-1 bg-orange-600 rounded cursor-pointer px-5 py-1 text-white">Enregistrer les modifications</button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={toggleUpdateNoteForm} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>


        </div >
    );
};

export default ClasseDetail;
