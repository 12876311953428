import React from 'react'
import DetailRessourceComponent from '../../../component/Visitor/Ressource/DetailRessourceComponent'
function DetailResourcePage() {


    return (
        <DetailRessourceComponent />
    )
}

export default DetailResourcePage
