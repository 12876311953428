import React from 'react'
import AjoutRessourceAdmin from './AjoutRessourceAdmin'
function AjoutRessourceAdminComponent() {
    return (
        <>
            <AjoutRessourceAdmin />
        </>
    )
}

export default AjoutRessourceAdminComponent
