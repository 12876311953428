import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FadeLoader } from 'react-spinners'

function NavBarsSlide({ getThemes }) {
    const { pathname } = useLocation()
    let AdminAceuil = /^\/AdminAccueil.*/
    let AdminEventGereAjout = /^\/AdminEvent.*/

    let AdminTemoignage = /^\/AdminTemoig\/Temoignage.*/
    let AdminTemoignageStandBuy = /^\/AdminTemoig\/StandyBuy.*/

    let AdminAnnonces = /^\/AdminAnnonce\/Annonce.*/
    let AdminAnnoncesAjouter = /^\/AdminAnnonce\/AjouterAnnonce.*/



    let AdminRessources = /^\/AdmiRessources\/Ressources.*/
    let AdminRessourcesAjouter = /^\/AdmiRessources\/AjouteRessources.*/

    let AdminMembre = /^\/AdminMembers\/Members.*/
    let AdminMembreAjouter = /^\/AdminMembers\/AjouterMembers.*/

    let AdminEnseignant = /^\/AdminEnseignant.*/

    let AdminContact = /^\/AdminContact\/Contact.*/
    let AdminContactReseauxSoci = /^\/AdminContact\/SocialContact.*/
    let AdminContactSuggestion = /^\/AdminContact\/SuggestionContact.*/

    let AdminFonction = /^\/AdminFonction.*/


    const [mobile, SetMobile] = useState(window.innerWidth < 776);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 776)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const [isLoading, GetisLoading] = useState(true)
    const hundleLoading = () => {
        GetisLoading(false)
    }


    return (
        <div className={`w-[14em] h-full  border-r-2 border-orange-300  flex-col items-center ${mobile ? 'hidden' : 'flex'} `}>

            <div className="w-full h-[9em] flex justify-center items-center   relative  rounded overflow-hidden ">
                {isLoading && <div className={`absolute  w-full z-[60]  h-full ${getThemes == 'light' ? ' bg-[#f0eded]' : 'bg-[#242323]'}  top-0 left-0   flex items-center justify-center`}>
                    <FadeLoader
                        color="#36d7b7"
                        height={20}
                        width={5}
                    />
                </div>
                }
                <img onLoad={hundleLoading} src="https://irakoze.burundientempsreel.com/uploads/Photo/LENGA2.png" alt="" className='block w-[14em] h-[14em]   object-contain object-center' />
            </div>

            <div className="w-full h-[75%] overflow-x-hidden overflow-y-auto border-t-2 border-orange-500">
                <div className="w-full h-100% font-serif">
                    {AdminAceuil.test(pathname) || pathname == '/' ? (
                        <Link to='/AdminAccueil' className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Acceuil</p>
                        </Link>
                    ) : (
                        <Link to='/AdminAccueil' className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Acceuil</p>
                        </Link>
                    )}

                    {AdminEventGereAjout.test(pathname) ? (
                        <Link to='/AdminEvent/AjoutEvenement' className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2  bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-calendar4-event text-[20px] mr-4"></i>
                            <p className='sizeText'>Evénement</p>
                        </Link>

                    ) : (
                        <Link to='/AdminEvent/AjoutEvenement' className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-calendar4-event text-[20px] mr-4"></i>
                            <p className='sizeText'>Evénement</p>
                        </Link>
                    )}

                    {AdminTemoignage.test(pathname) || AdminTemoignageStandBuy.test(pathname) ? (
                        <Link to="/AdminTemoig/Temoignage" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600  transition-all text-fuchsia-50  hover:text-fuchsia-50 '>
                            <i class="bi bi bi-badge-tm  text-[20px] mr-4"></i>
                            <p className='sizeText'>Témoignage</p>
                        </Link>
                    ) : (
                        <Link to="/AdminTemoig/Temoignage" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi bi-badge-tm  text-[20px] mr-4"></i>
                            <p className='sizeText'>Témoignage</p>
                        </Link>
                    )}


                    {AdminAnnonces.test(pathname) || AdminAnnoncesAjouter.test(pathname) ? (
                        <Link to="/AdminAnnonce/AjouterAnnonce" className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                            <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Annonce</p>
                        </Link>
                    ) : (
                        <Link to="/AdminAnnonce/AjouterAnnonce" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Annonce</p>
                        </Link>
                    )}


                    {AdminRessources.test(pathname) || AdminRessourcesAjouter.test(pathname) ? (
                        <Link to="/AdmiRessources/AjouteRessources" className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                            <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                            <p className='sizeText'>Ressource</p>
                        </Link>
                    ) : (
                        <Link to="/AdmiRessources/AjouteRessources" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                            <p className='sizeText'>Ressource</p>
                        </Link>
                    )}
                    {AdminMembre.test(pathname) || AdminMembreAjouter.test(pathname) ? (
                        <Link to="/AdminMembers/AjouterMembers" className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                            <i class="bi bi-people-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Membre</p>
                        </Link>
                    ) : (
                        <Link to="/AdminMembers/AjouterMembers" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-people-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Membre</p>
                        </Link>
                    )}


                    {AdminEnseignant.test(pathname) ? (
                        <Link to="/AdminEnseignant" className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                            <i class="bi bi-book-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Enseignant</p>
                        </Link>
                    ) : (
                        <Link to="/AdminEnseignant" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-book-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Enseignant</p>
                        </Link>
                    )}


                    {AdminContact.test(pathname) || AdminContactReseauxSoci.test(pathname) || AdminContactSuggestion.test(pathname) ? (
                        <Link to="/AdminContact/Contact" className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                            <i class="bi bi-envelope-at-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Contact</p>
                        </Link>
                    ) : (
                        <Link to="/AdminContact/Contact" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-envelope-at-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Contact</p>
                        </Link>
                    )}
                    {AdminFonction.test(pathname) ? (
                        <Link to="/AdminFonction" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                            <i class="bi bi-funnel-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Fonction</p>
                        </Link>
                    ) : (
                        <Link to="/AdminFonction" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all '>
                            <i class="bi bi-funnel-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Fonction</p>
                        </Link>
                    )}

                </div>
            </div>

        </div>
    )
}

export default NavBarsSlide
