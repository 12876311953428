import React from 'react'
import AnnonceComponent from '../../../component/Visitor/Annonce/AnnonceComponent'
function AnnoncePage() {


    return (
        <AnnonceComponent />
    )
}

export default AnnoncePage
