/* eslint-disable no-dupe-keys */
import React, { useRef } from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Options = [
    { value: 'Elève', label: 'Elève' },
    { value: 'Parent', label: 'Parent' },
    { value: 'Professeur', label: 'Professeur' },
    { value: 'Particulier', label: 'Particulier' }
]

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '0px',
        backgroundColor: "transparent",
        top: '7px',
        with: '100%',
        boxShadow: state.isFocused ? '0 0 0 2px #ddd' : 'none',
        borderBottom: state.isFocused ? '2px solid blue' : '2px solid gray',
        '&:hover': {
            borderBottom: state.isFocused ? '2px solid blue' : '2px solid orange',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'orange' : 'transparent',
        color: state.isSelected ? '#333' : '#666',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
        position: 'static',
        zIndex: '10000',
    }),
};
function AddTemoignageAdmin() {


    const [isTitre, SetisTitre] = useState('')
    const [animationClassTitre, setAnimationClassTitre] = useState('');
    const elementRefisTitre = useRef(null)

    const [nom_complet, Setnom_complet] = useState('')
    const [animationClassNom, setAnimationClassNom] = useState('');
    const elementRefisnom_complet = useRef(null)

    const [isPrenom, SetisPrenom] = useState('')
    const [animationClassPrenom, setAnimationClassPrenom] = useState('');
    const elementRefisisPrenom = useRef(null)

    const [isSelectValue, SetisSelectValue] = useState('')
    const [animationClassSelecteValue, setanimationClassSelecteValue] = useState('');
    const elementRefisisSelectvalue = useRef(null)

    const [isTextarea, SetisTextarea] = useState('')
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const elementRefisisTextatarea = useRef(null)



    const handleSubmit = (e) => {
        e.preventDefault()
        if (isTitre.trim() === '') {
            toast.warning('Le titre de témoignage est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassTitre('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassTitre(' ')
            }, 3000)
            return;
        } else if (nom_complet.trim() === '') {
            toast.warning('Le nom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassNom('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassNom(' ')
            }, 3000)
            return;
        } else if (isPrenom.trim() === '') {
            toast.warning('Le  prenom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassPrenom(' ')
            }, 3000)
            return;
        }
        else if (isSelectValue === '') {
            toast.warning('Sélectionnez votre catégorie', {
                autoClose: 2000
            });
            setanimationClassSelecteValue('animate__animated animate__shakeX border-red-500')
            setTimeout(() => {
                setanimationClassSelecteValue(' ')
            }, 3000)
            return;
        }
        else if (isTextarea.trim() === '') {
            toast.warning('Le contenue de la temoignage est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassTextarea(' ')
            }, 3000)
            return;
        }
        axios.post('https://irakoze.burundientempsreel.com/api/addtemoignage', {
            nom_complet: nom_complet,
            categorie: isSelectValue.value,
            titre: isTitre,
            contenue: isTextarea

        }).then((Response) => {
            toast.success(Response.data);
            Setnom_complet('')
            SetisTitre('')
            SetisSelectValue('')
            SetisTextarea('')

        }).catch((error) => {
            toast.error(error.message);
        });
    };
    return (
        <div className='flex justify-center min-h-[87vh] flex-col items-center'>
            <div className="  border m-1  sm:m-3 p-2 rounded-xl min-w-[95%] h-max ">
                <h3 className=' p-2 text-center mb-[1em]'>Vos témoignage</h3>
                <form onSubmit={handleSubmit}>
                    <div class="w-full mb-5">
                        <label
                            class="block  tracking-wide  text-lg  mb-2"
                            for="grid-Title"
                        >
                            Tritre
                        </label>
                        <input
                            ref={elementRefisTitre}
                            class={`block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3 ${animationClassTitre} `}
                            value={isTitre}
                            placeholder=" Tritre"
                            onInput={(e) => SetisTitre(e.target.value)}
                        />
                    </div>


                    <div class="w-full mb-5">
                        <label
                            class="block  tracking-wide  text-lg  mb-2"
                            for="grid-Title"
                        >
                            Nom
                        </label>
                        <input
                            ref={elementRefisnom_complet}
                            class={`block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3 ${animationClassNom} `}
                            value={nom_complet}
                            placeholder="Nom"
                            onInput={(e) => Setnom_complet(e.target.value)}
                        />
                    </div>

                    <div class="w-full mb-5">
                        <label
                            class="block  tracking-wide  text-lg  mb-2"
                            for="grid-Title"
                        >
                            Prenom
                        </label>
                        <input
                            ref={elementRefisisPrenom}
                            class={`block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3 ${animationClassPrenom} `}
                            value={isPrenom}
                            placeholder="Prenom"
                            onInput={(e) => SetisPrenom(e.target.value)}
                        />
                    </div>

                    <div class="w-full mb-5">
                        <label
                            class="block  tracking-wide  text-lg  mb-2"
                            for="grid-Title"
                        >
                            Selon vos choix
                        </label>
                        <div ref={elementRefisisSelectvalue} className={`  flex flex-col h-11 w-full relative border-b-2     ${animationClassSelecteValue}`}>
                            <Select onChange={SetisSelectValue} defaultValue={isSelectValue} options={Options} styles={customStyles} >
                            </Select>
                        </div>
                    </div>


                    <div class="w-full mb-5">
                        <label
                            class="block  tracking-wide  text-lg  mb-1"
                            for="grid-Title"
                        >
                            Le contenue
                        </label>
                        <textarea
                            placeholder=" Le contenue"
                            ref={elementRefisisTextatarea}
                            class={`block w-full bg-transparent min-h-[11em]  resize-y outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3 ${animationClassTextarea} `}

                            value={isTextarea}
                            onChange={(e) => { SetisTextarea(e.target.value) }}
                        ></textarea>
                    </div>
                    <div className="flex justify-end items-center">
                        <input type="submit" className="w-max  flex justify-end p-1 bg-orange-600 rounded cursor-pointer px-5 py-1 text-white" id="send"></input>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddTemoignageAdmin
