/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import "./App.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css/animate.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import 'rsuite/dist/rsuite.min.css';

// Pour le visiteur
import NavBarsVisitors from './component/Visitor/NavBarsVisitors/NavBarsVisitors';
import FooterVisitor from './component/Visitor/FooterVisitor/FooterVisitor';

import HomeVisitorPage from './page/VisitorPage/HomeVisitorPage';
import EvenemntVisitorPage from './page/VisitorPage/EvenementPage/EvenemntVisitorPage';
import DetailEventVisitoPage from './page/VisitorPage/EvenementPage/DetailEventVisitoPage';
import FormulaireTemoignVisitores from './component/Visitor/Temoignage/FormulaireTemoignVisitores';
import TemoignagePage from './page/VisitorPage/TemoignagePage/TemoignagePage';
import AnnoncePage from './page/VisitorPage/AnnoncePage/AnnoncePage';
import RessourcePage from './page/VisitorPage/RessourcePage/RessourcePage';
import DetailAnnoncePage from './page/VisitorPage/AnnoncePage/DetailAnnoncePage';
import DetailResourcePage from './page/VisitorPage/RessourcePage/DetailResourcePage';
import Contact from './component/Visitor/Contact/Contact';
import AproposPage from './page/VisitorPage/AproposPage/AproposPage';



// pour admin
import Login from './LoginPage/Login';
import NavBarsSlide from './component/Admin/NavBarsSlide/NavBarsSlide';
import RegisterAdmin from './component/Visitor/RegisterAdmin/RegisterAdmin';
import CopyRight from './component/Visitor/CopyRight/CopyRight';
import NavBars from './component/Admin/NavBars/NavBars';


import AcceuilAdminPage from './page/AdminPage/AcceuilAdminPage/AcceuilAdminPage';
import EvenementAdimPage from './page/AdminPage/EvenementAdminPage/EvenementAdimPage';
import DetailEvementAdminPage from './page/AdminPage/EvenementAdminPage/DetailEvementAdminPage';
import AjoutEvenementAdminPage from './page/AdminPage/EvenementAdminPage/AjoutEvenementAdminPage';
import TemoignageAdmimPage from './page/AdminPage/TemoignagePge/TemoignageAdmimPage';
import DetailTemoigePage from './page/AdminPage/TemoignagePge/DetailTemoigePage';
import StanbdBuyTemoignagePage from './page/AdminPage/TemoignagePge/StanbdBuyTemoignagePage';
import StandBuyDetailTemoignagePage from './page/AdminPage/TemoignagePge/StandBuyDetailTemoignagePage';
import AddMoresPage from './page/AdminPage/EvenementAdminPage/AddMoresPage';

import GereAnnonceAdminPage from './page/AdminPage/AnnonceAdminPage/GereAnnonceAdminPage';
import DetailAdminAnnoncePage from './page/AdminPage/AnnonceAdminPage/DetailAdminAnnoncePage';
import AjouterAdminAnnoncesPage from './page/AdminPage/AnnonceAdminPage/AjouterAdminAnnoncesPage';
import RessourceAdminPage from './page/AdminPage/RessourceAdminPage/RessourceAdminPage';
import AjouteRessourcesAdminPAge from './page/AdminPage/RessourceAdminPage/AjouteRessourcesAdminPAge';
import EditeEvenementComponent from './component/Admin/EvenementAdmin/EvenementGereAdmin/EditeEvenementComponent';
import EditorAnnonceAdminPAge from './page/AdminPage/AnnonceAdminPage/EditorAnnonceAdminPAge';
import EditeRessourcePage from './page/AdminPage/RessourceAdminPage/EditeRessourcePage';
import DetailResourceAdminPAge from './page/AdminPage/RessourceAdminPage/DetailResourceAdminPAge';
import AddMoreRessourcePAge from './page/AdminPage/RessourceAdminPage/AddMoreRessourcePAge';
import AddMoreAnnonceAdminPage from './page/AdminPage/AnnonceAdminPage/AddMoreAnnonceAdminPage';
import CoordonnerPhysiquePage from './page/AdminPage/ContactAdminPAge/CoordonnerPhysiquePage';
import ModifierContactPage from './page/AdminPage/ContactAdminPAge/ModifierContactPage';
import ReseauxSocialPage from './page/AdminPage/ContactAdminPAge/ReseauxSocialPage';
import HoraireOuverturePage from './page/AdminPage/ContactAdminPAge/HoraireOuverturePage';
import ModifierHorairePage from './page/AdminPage/ContactAdminPAge/ModifierHorairePage';
import SuggestionPage from './page/AdminPage/ContactAdminPAge/SuggestionPage';
import AfficheMembrePage from './page/AdminPage/Membre/AfficheMembrePage';
import AjouteMembresPage from './page/AdminPage/Membre/AjouteMembresPage';
import ModifierMembresComponent from './component/Admin/Membre/AffchageMembre/ModifierMembresComponent';
import Profile from './component/Admin/Paramettre/Profil/Profile';
import ModifierProfil from './component/Admin/Paramettre/Profil/ModifierProfil';
import StandBuyDetailComponent from './component/Admin/TemoignageAdmin/StandBuyAdmin/StandBuyDetailComponent';
import PageNotFound from './page/PageNotFound';
import EvenementComposeTitreDetail from './component/Admin/EvenementAdmin/EvenementGereAdmin/EvenementComposeTitreDetail';
import EvenementComposeSousTitreDetail from './component/Admin/EvenementAdmin/EvenementGereAdmin/EvenementComposeSousTitreDetail';
import EvenementComposeSousTitreDetailModifier from './component/Admin/EvenementAdmin/EvenementGereAdmin/EvenementComposeSousTitreDetailModifier';
import EvenementComposeSousTitreAddMore from './component/Admin/EvenementAdmin/EvenementGereAdmin/EvenementComposeSousTitreAddMore';
import ComptUser from './component/Visitor/RegisterAdmin/ComptUser';
import AddTemoignageAdmin from './component/Admin/TemoignageAdmin/TemoignageGerer/AddTemoignageAdmin';
import Enseignant from './component/Admin/Enseignant/Enseignant';
import Classes from './component/Admin/Enseignant/Classes';
import EditerEvent from './component/Admin/EvenementAdmin/EvenementGereAdmin/EditerEvent';
import EditEventPart from './component/Admin/EvenementAdmin/EvenementGereAdmin/EditEventPart';
import AnneesScolairesPage from './component/Admin/Enseignant/AnneesScolairesPage';
import ClassesPage from './component/Admin/Enseignant/ClassesPage';
import ClasseDetail from './component/Admin/Enseignant/ClasseDetail';
import { CustomProvider } from 'rsuite';


import NavBarsInspect from './component/User/Formeurs/NavBarsInspect/NavBarsInspect';
import NavBarsSlideInsp from './component/User/Formeurs/NavBarsSlideInsp/NavBarsSlideInsp';
import EvenementInspecteur from './component/User/Formeurs/EvenementInspecteur/EvenementInspecteur';
import DetailEventInspect from './component/User/Formeurs/EvenementInspecteur/DetailEventInspect';
import EditeEvenementInspect from './component/User/Formeurs/EvenementInspecteur/EditeEvenementInspect';
import EditEventPartInspect from './component/User/Formeurs/EvenementInspecteur/EditEventPartInspect';
import EventddMoreInspect from './component/User/Formeurs/EvenementInspecteur/EventddMoreInspect';
import EvenementInspecteurValid from './component/User/Formeurs/EvenementInspecteurValide/EvenementInspecteurValid';
import DetailEventInspectValide from './component/User/Formeurs/EvenementInspecteurValide/DetailEventInspectValide';
import EditEventPartInspectValide from './component/User/Formeurs/EvenementInspecteurValide/EditEventPartInspectValide';
import EditeEvenementInspectValid from './component/User/Formeurs/EvenementInspecteurValide/EditeEvenementInspectValid';
import TemoignageInsp from './component/User/Formeurs/TemoignageInsp/TemoignageInsp';
import TemoignageInspDetail from './component/User/Formeurs/TemoignageInsp/TemoignageInspDetail';
import AnnoncesInspect from './component/User/Formeurs/AnnoncesInspect/AnnoncesInspect';
import AnnoncesInspectDeatil from './component/User/Formeurs/AnnoncesInspect/AnnoncesInspectDeatil';
import AnnoncesInspectModifier from './component/User/Formeurs/AnnoncesInspect/AnnoncesInspectModifier';
import AnnoncesInspectAddMore from './component/User/Formeurs/AnnoncesInspect/AnnoncesInspectAddMore';
import AnnoncesInspectValid from './component/User/Formeurs/AnnoncesInspect/AnnoncesInspectValid';
import AnnoncesInspectDeatilValid from './component/User/Formeurs/AnnoncesInspect/AnnoncesInspectDeatilValid';
import AnnoncesInspectAddMoreValid from './component/User/Formeurs/AnnoncesInspect/AnnoncesInspectAddMoreValid';
import AnnoncesInspectModifierValide from './component/User/Formeurs/AnnoncesInspect/AnnoncesInspectModifierValide';
import RessourceInspect from './component/User/Formeurs/RessourceInspect/RessourceInspect';
import RessourceInspectetail from './component/User/Formeurs/RessourceInspect/RessourceInspectetail';
import RessourceInspectModif from './component/User/Formeurs/RessourceInspect/RessourceInspectModif';
import RessourceInspectAddMore from './component/User/Formeurs/RessourceInspect/RessourceInspectAddMore';
import RessourceInspectAddMoreValide from './component/User/Formeurs/RessourceInspect/RessourceInspectAddMoreValide';
import RessourceInspectValide from './component/User/Formeurs/RessourceInspect/RessourceInspectValide';
import RessourceInspectetailValide from './component/User/Formeurs/RessourceInspect/RessourceInspectetailValide';
import TemoignageInspValide from './component/User/Formeurs/TemoignageInsp/TemoignageInspValide';
import TemoignageInspDetailValide from './component/User/Formeurs/TemoignageInsp/TemoignageInspDetailValide';
import AcceuiInspect from './component/User/Formeurs/AcceuiInspect/AcceuiInspect';
import AddTemoignageInspect from './component/User/Formeurs/AddTemoignageInspect/AddTemoignageInspect';
import ProfileInspect from './component/User/Formeurs/ComptInspect/ProfileInspect';
import ProfileInspectModifier from './component/User/Formeurs/ComptInspect/ProfileInspectModifier';
import AdminFonction from './component/Admin/AdminFonction/AdminFonction';
import AjoutAdminFonction from './component/Admin/AdminFonction/AjoutAdminFonction';
import ModifierAdminFonction from './component/Admin/AdminFonction/ModifierAdminFonction';
import axios from 'axios';
import Edication from './component/Visitor/Edication/Edication';

function App() {
  const [isAdmin, SetisAdmin] = useState(false)
  const [isInspecteur, SetisInspecteur] = useState(false)
  const [iseVisitor, setIsVisitor] = useState(false)
  useEffect(() => {
    AOS.init();
  })
  const token = localStorage.getItem("vvvvv");
  const adminId = localStorage.getItem("v");
  const inspecteurId = localStorage.getItem("vv");

  useEffect(() => {
    if (token) {
      axios
        .get("https://irakoze.burundientempsreel.com/api/auth", {
          headers: {
            accessToken: token,
          },
        })
        .then((response) => {
          if (response.data.error) {
            SetisAdmin(false);
            SetisInspecteur(false);
            setIsVisitor(true)
          } else {
            if (adminId !== null) {
              SetisAdmin(true);
              setIsVisitor(false)
              SetisInspecteur(false);

            } else if (inspecteurId !== null) {
              SetisInspecteur(true);
              setIsVisitor(false)
              SetisAdmin(false);
            }

          }
        });
    } else {
      setIsVisitor(true)
    }
  }, []);


  const [getThemes, SetgetThemes] = useState()
  const [getThemesInsp, SetgetThemesInsp] = useState()

  const GetValuesThemes = (e) => { SetgetThemes(e) }
  const GetValuesThemesInsp = (e) => { SetgetThemesInsp(e) }


  const [open, GetOpen] = useState(false)


  return isAdmin ? (
    <CustomProvider theme={getThemes} >
      <Router>
        <div className='w-[100%] h-[100vh] flex overflow-hidden font-serif'>
          <NavBarsSlide getThemes={getThemes} />
          <div className='w-[100%] h-[100vh]  overflow-hidden'>
            <NavBars GetValuesThemes={GetValuesThemes} isAdmin={isAdmin} SetisAdmin={SetisAdmin} />
            <div className="h-[87vh] max-sm:h-[92vh] w-full  overflow-x-hidden overflow-y-auto">
              <Routes>
                <Route path='/AdminAccueil' Component={AcceuilAdminPage} />
                <Route path='/' Component={AcceuilAdminPage} />
                <Route path='/AdminEvent/AjoutEvenement' Component={AjoutEvenementAdminPage} />
                <Route path='/AdminEvent/Evenement' Component={EvenementAdimPage} />
                <Route path='/AdminEvent/Evenement/Detail/:id' Component={DetailEvementAdminPage} />
                <Route path='/AdminEvent/Evenement/Editor/:id' Component={EditerEvent} />
                <Route path='/AdminEvent/Evenement/EditEventPart/:id' Component={EditEventPart} />
                <Route path='/AdminEvent/Evenement/Ajouter/:id' Component={AddMoresPage} />
                <Route path='/AdminEvent/EvenementComposeTitre/Detail' Component={EvenementComposeTitreDetail} />
                <Route path='/AdminEvent/EvenementComposeSousTitre/Detail' Component={EvenementComposeSousTitreDetail} />
                <Route path='/AdminEvent/EvenementComposeSousTitre/Detail/Modifier' Component={EvenementComposeSousTitreDetailModifier} />
                <Route path='/AdminEvent/EvenementComposeSousTitre/AddMore' Component={EvenementComposeSousTitreAddMore} />

                <Route path='/AdminTemoig/Temoignage' Component={TemoignageAdmimPage} />
                <Route path='/AdminTemoig/Temoignage/Detail/:id' Component={DetailTemoigePage} />
                <Route path='/AdminTemoig/StandyBuy' Component={StanbdBuyTemoignagePage} />
                <Route path='/AdminTemoig/StandyBuy/Detail/:id' Component={StandBuyDetailTemoignagePage} />


                <Route path='/AdminAnnonce/Annonce' Component={GereAnnonceAdminPage} />
                <Route path='/AdminAnnonce/Annonce/detail/:id' Component={DetailAdminAnnoncePage} />
                <Route path='/AdminAnnonce/AjouterAnnonce' Component={AjouterAdminAnnoncesPage} />
                <Route path='/AdminAnnonce/Annonce/AjouterPlus/:id' Component={AddMoreAnnonceAdminPage} />
                <Route path='/AdminAnnonce/Annonce/Editor/:id' Component={EditorAnnonceAdminPAge} />



                <Route path='/AdmiRessources/Ressources' Component={RessourceAdminPage} />
                <Route path='/AdmiRessources/AjouteRessources' Component={AjouteRessourcesAdminPAge} />
                <Route path='/AdmiRessources/Ressources/Editor/:id' Component={EditeRessourcePage} />
                <Route path='/AdmiRessources/Ressources/Detail/:id' Component={DetailResourceAdminPAge} />
                <Route path='/AdmiRessources/Ressources/AjoutPlus/:id' Component={AddMoreRessourcePAge} />

                <Route path='/AdminContact/Contact' Component={CoordonnerPhysiquePage} />
                <Route path='/AdminContact/Contact/ModifierContact/:id' Component={ModifierContactPage} />
                <Route path='/AdminContact/SocialContact' Component={ReseauxSocialPage} />
                <Route path='/AdminContact/Contact/HoraireOuverture' Component={HoraireOuverturePage} />
                <Route path='/AdminContact/Contact/HoraireOuverture/ModifierHoraire' Component={ModifierHorairePage} />
                <Route path='/AdminContact/SuggestionContact' Component={SuggestionPage} />

                <Route path='/AdminMembers/Members' Component={AfficheMembrePage} />
                <Route path='/AdminMembers/AjouterMembers' Component={AjouteMembresPage} />
                <Route path='/AdminMembers/Members/Modifier/:id' Component={ModifierMembresComponent} />

                <Route path='/AdminEnseignant' Component={Enseignant} />
                <Route path='/AdminEnseignant/classes' Component={Classes} />
                <Route path='/AdminEnseignant/AnneesScolairesPage' Component={AnneesScolairesPage} />
                <Route path='/AdminEnseignant/ClassesPage' Component={ClassesPage} />
                <Route path='/AdminEnseignant/ClassesDetail/:id' Component={ClasseDetail} />


                <Route path='/Admin/Profil' Component={Profile} />
                <Route path='/Admin/Profil/Modifier' Component={ModifierProfil} />
                <Route path='/Admin/StandyBuy/Detail/:id' Component={StandBuyDetailComponent} />

                <Route path='/AdminFonction' Component={AdminFonction} />
                <Route path='/AdminFonction/ajoute' Component={AjoutAdminFonction} />
                <Route path='/AdminFonction/modifier/:id' Component={ModifierAdminFonction} />

                <Route path='/regis' Component={RegisterAdmin} />
                <Route path='/compte' Component={ComptUser} />
                <Route path='/ajouTemoin' Component={AddTemoignageAdmin} />
                <Route path='/Profile' Component={Profile} />
                <Route path="*" Component={PageNotFound} />

              </Routes>
              <CopyRight />
            </div>
          </div>
        </div>
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </CustomProvider>
  ) : isInspecteur ? (
    <CustomProvider theme={getThemesInsp} >
      <Router>
        <div className='w-[100%] h-[100vh] flex overflow-hidden font-serif'>
          <NavBarsSlideInsp getThemes={getThemesInsp} />
          <div className='w-[100%] h-[100vh]  overflow-hidden'>
            <NavBarsInspect GetValuesThemesInsp={GetValuesThemesInsp} setIsVisitor={setIsVisitor} isAdmin={isAdmin} SetisInspecteur={SetisInspecteur} SetisAdmin={SetisAdmin} />
            <div className="h-[87vh] max-sm:h-[92vh] w-full  overflow-x-hidden overflow-y-auto">
              <Routes>
                <Route path='/InspectAccueil' Component={AcceuiInspect} />
                <Route path='/' Component={AcceuiInspect} />
                <Route path='/InspectEvent/Evenement' Component={EvenementInspecteur} />
                <Route path='/InspectEvent/Evenement/Detail/:id' Component={DetailEventInspect} />
                <Route path='/InspectEvent/Evenement/Editor/:id' Component={EditeEvenementInspect} />
                <Route path='/InspectEvent/Evenement/EditEventPart/:id' Component={EditEventPartInspect} />
                <Route path='/InspectEvent/Evenement/Ajouter/:id' Component={EventddMoreInspect} />

                <Route path='/InspectEvent/Evenement/valid' Component={EvenementInspecteurValid} />
                <Route path='/InspectEvent/Evenement/valid/Detail/:id' Component={DetailEventInspectValide} />
                <Route path='/InspectEvent/Evenement/valid/Editor/:id' Component={EditeEvenementInspectValid} />
                <Route path='/InspectEvent/Evenement/valid/EditEventPart/:id' Component={EditEventPartInspectValide} />
                <Route path='/InspectEvent/Evenement/valid/Ajouter/:id' Component={EventddMoreInspect} />

                <Route path='/InspectTemoig/Temoignage' Component={TemoignageInsp} />
                <Route path='/InspectTemoig/Temoignage/Detail/:id' Component={TemoignageInspDetail} />

                <Route path='/InspectTemoig/Temoignage/valid' Component={TemoignageInspValide} />
                <Route path='/InspectTemoig/Temoignage/valid/Detail/:id' Component={TemoignageInspDetailValide} />

                <Route path='/InspectAnnonce/Annonce' Component={AnnoncesInspect} />
                <Route path='/InspectAnnonce/Annonce/detail/:id' Component={AnnoncesInspectDeatil} />
                <Route path='/InspectAnnonce/Annonce/detail/Editor/:id' Component={AnnoncesInspectModifier} />
                <Route path='/InspectAnnonce/Annonce/detail/AjouterPlus/:id' Component={AnnoncesInspectAddMore} />


                <Route path='/InspectAnnonce/Annonce/valide' Component={AnnoncesInspectValid} />
                <Route path='/InspectAnnonce/Annonce/valide/detail/:id' Component={AnnoncesInspectDeatilValid} />
                <Route path='/InspectAnnonce/Annonce/valide/detail/Editor/:id' Component={AnnoncesInspectModifierValide} />
                <Route path='/InspectAnnonce/Annonce/valide/detail/AjouterPlus/:id' Component={AnnoncesInspectAddMoreValid} />



                <Route path='/InspectRessources/Ressources' Component={RessourceInspect} />
                <Route path='/InspectRessources/Ressources/Editor/:id' Component={RessourceInspectModif} />
                <Route path='/InspectRessources/Ressources/Detail/:id' Component={RessourceInspectetail} />
                <Route path='/InspectRessources/Ressources/AjoutPlus/:id' Component={RessourceInspectAddMore} />

                <Route path='/InspectRessources/Ressources/Valide' Component={RessourceInspectValide} />
                <Route path='/InspectRessources/Ressources/Valide/Editor/:id' Component={RessourceInspectModif} />
                <Route path='/InspectRessources/Ressources/Valide/Detail/:id' Component={RessourceInspectetailValide} />
                <Route path='/InspectRessources/Ressources/Valide/AjoutPlus/:id' Component={RessourceInspectAddMoreValide} />




                <Route path='/Inspect/Profil' Component={ProfileInspect} />
                <Route path='/Inspect/Profil/Modifier' Component={ProfileInspectModifier} />
                <Route path='/ajouTemoinInspect' Component={AddTemoignageInspect} />
                <Route path="*" Component={PageNotFound} />
              </Routes>
              <CopyRight />
            </div>
          </div>
        </div>
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </CustomProvider>
  ) : iseVisitor ? (

    <CustomProvider theme='light'>
      <Router>
        <div className='w-[100%] bg-white h-[100vh] overflow-hidden  font-serif'>
          <NavBarsVisitors GetOpen={GetOpen} />
          {open && <Login GetOpen={GetOpen} isAdmin={isAdmin} SetisAdmin={SetisAdmin} />}
          <div className="h-[90vh] bg-white overflow-x-hidden overflow-y-auto">
            <Routes>
              <Route path='/' Component={HomeVisitorPage} />
              <Route path='/Visit/Evenement' Component={EvenemntVisitorPage} />
              <Route path='/Visit/Evenement/Detail/:idc' Component={DetailEventVisitoPage} />
              <Route path='/Visit/Temoignage' Component={TemoignagePage} />
              <Route path='/Visit/NouvelleTemoignage' Component={FormulaireTemoignVisitores} />
              <Route path='/Visit/Annonce' Component={AnnoncePage} />
              <Route path='/Visit/Annonce/Detail/:idc' Component={DetailAnnoncePage} />
              <Route path='/Visit/Ressource' Component={RessourcePage} />
              <Route path='/Visit/Ressource/Detail/:idc' Component={DetailResourcePage} />
              <Route path='/Visit/Contact' Component={Contact} />
              <Route path='/Visit/Edication' Component={Edication} />
              <Route path='/Visit/Apropos' Component={AproposPage} />
              <Route path="*" Component={PageNotFound} />
            </Routes>
            <FooterVisitor />
          </div>
          <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
      </Router>
    </CustomProvider>

  ) : null
}
export default App;
