import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function GereAnnonces() {
  const [annonces, setAnnonces] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [totalElements, setTotalElements] = useState(0)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, statusFilter]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://irakoze.burundientempsreel.com/api/getallannonce", {
        params: {
          status: statusFilter,
          search: searchQuery,
          page: currentPage,
          size: pageSize,
        },
      });
      setAnnonces(response.data.Annonces);
      setTotalPages(response.data.totalPages);
      setTotalElements(response.data.totalElements);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);

    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchData();
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(1);
  };


  function transformDate(isoDate) {
    const date = new Date(isoDate);
    const frenchDays = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    const day = frenchDays[date.getDay()];
    const dayNumber = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    hours = hours > 9 ? hours : "0" + hours;

    let time = `${hours}h${minutes}`;
    return (
      <>
        <p className="text-blue-600">
          {day}, {"Le"} {dayNumber}/{date.getMonth() + 1}/{year} à {time}
        </p>

      </>
    );
  }
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, totalElements);
    return `${from} à ${to} annonces sur ${totalElements}`;
  };
  return (
    <div className="min-h-[75vh] flex flex-col items-center">
      <div className="w-full px-5 rounded-b-lg flex flex-wrap items-center justify-between border-b border-gray-300">
        <div className="flex flex-wrap items-center my-2">
          <input
            type="text"
            placeholder="Rechercher..."
            className="p-2 sm:ml-4 m-1 border bg-transparent border-gray-300 rounded-md focus:outline-none"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button
            className="px-4 py-2 mr-1 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none"
            onClick={handleSearch}
          >
            Rechercher
          </button>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="p-2 border border-gray-300 bg-transparent rounded-md focus:outline-none"
          >
            <option className="text-black" value="">Tous les statuts</option>
            <option className="text-black" value="1">Activer</option>
            <option className="text-black" value="0">Desactiver</option>
          </select>

          <select
            className="p-2 border border-gray-300 bg-transparent rounded-md focus:outline-none sm:ml-4 m-1"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            <option className="text-black" value="5">5 par page</option>
            <option className="text-black" value="10">10 par page</option>
            <option className="text-black" value="20">20 par page</option>
          </select>
        </div>
        <Link to='/AdminAnnonce/AjouterAnnonce' className='bg-orange-500 hover:text-white hover:no-underline text-white focus:text-white text-[12px] focus:no-underline sm:text-[17px] rounded h-max w-max px-2 py-1 sm:ml-4 m-1'>
          Ajouter un annonce
        </Link>
      </div>


      <div className="mt-4 w-full">
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          <div className="mt-4 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {annonces.map((annonce) => (
                <div key={annonce.id} className="flex flex-col border rounded-lg overflow-hidden">
                  <img src={`https://irakoze.burundientempsreel.com/uploads/annonces/${annonce.file}`} alt={annonce.titre} className="w-full h-48 object-cover" />
                  <div className="p-4 flex flex-col flex-grow">
                    <h2 className="text-lg font-semibold">{annonce.titre}</h2>
                    <p className="flex-grow">{annonce.description.length > 30 ? `${annonce.description.substring(0, 30)}...` : annonce.description}</p>
                    <div className="text-gray-400 text-[13px] m-3  pl-10  w-full">
                      {transformDate(annonce.date)}
                    </div>
                    <div className="mt-3 flex justify-between items-end">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${annonce.status === 1 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                        {annonce.status === 1 ? 'Actif' : 'Inactif'}
                      </span>
                      <div className="flex space-x-2">
                        <Link to={`/AdminAnnonce/Annonce/detail/${annonce.id}`} className="p-1 bg-green-500 hover:bg-green-600 text-white rounded text-xs">
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                            <title>Détails</title>
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                          </svg>
                        </Link>
                        <Link to={`/AdminAnnonce/Annonce/Editor/${annonce.id}`} className="p-1 bg-green-500 hover:bg-green-600 text-white rounded text-xs">
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                            <title>Modifier</title>
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                          </svg>
                        </Link>

                        <Link to={`/AdminAnnonce/Annonce/AjouterPlus/${annonce.id}`} className="p-1 bg-blue-500 hover:bg-blue-600 text-white rounded text-xs">
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-cloud-plus" viewBox="0 0 16 16">
                            <title>Ajouter Plus</title>
                            <path fillRule="evenodd" d="M8 5.5a.5.5 0 1 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5" />
                            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                          </svg>
                        </Link>

                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>



        )}

        {annonces.length > 0 && (
          <>
            <div className="w-full flex justify-center items-center">
              <div className="w-[25em] h-full flex justify-around items-center p-2">
                <button onClick={previousPage} disabled={currentPage === 1} className="transition-all flex cursor-pointer hover:p-2 rounded">
                  <i className="bi bi-arrow-left-circle-fill"></i>
                  <span>Précédent</span>
                </button>
                <button onClick={nextPage} disabled={currentPage === totalPages} className="transition-all flex cursor-pointer hover:p-2 rounded">
                  <span>Suivant</span>
                  <i className="bi bi-arrow-right-circle-fill"></i>
                </button>
              </div>
            </div>

            <div className="mt-4 flex items-center justify-center">
              <p className="text-gray-600">{getRange()}</p>
            </div>
          </>
        )}
      </div>
    </div >
  );
}

export default GereAnnonces;
