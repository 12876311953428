/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function TeteTemoi() {
    const url = window.location.pathname;

    const [mobiles, SetMbiles] = useState(window.innerWidth < 400)

    useEffect(() => {
        const HundleSize = () => {
            SetMbiles(window.innerWidth < 400)
        }

        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])

    return (
        <div className='text-[20px]  h-[7vh] flex justify-between items-end'>
            <Link className={`w-full ${mobiles ? 'text-[13px]' : ''} border-b-2 pl-2 ${(url == "/InspectTemoig/Temoignage" || url == "/event/Detail" || url == "/event/AddMore" || url == "/event/detailTitre" || url == "/event/edit") && "border-fuchsia-600 hover:text-fuchsia-600 focus:text-fuchsia-600 text-fuchsia-600"}`} to='/InspectTemoig/Temoignage'>Temoignage invalide</Link>
            <Link className={`w-full ${mobiles ? 'text-[13px]' : ''} border-b-2 pl-2 ${(url == "/InspectTemoig/Temoignage/valid" || url == "/event/Detail" || url == "/event/AddMore" || url == "/event/detailTitre" || url == "/event/edit") && "border-fuchsia-600 hover:text-fuchsia-600 focus:text-fuchsia-600 text-fuchsia-600"}`} to='/InspectTemoig/Temoignage/valid'>Temoignage valide</Link>
        </div>
    )
}

export default TeteTemoi