/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { toast } from 'react-toastify'

function HorairesOuverutre() {

  const [debutAnnee, SetDebutAnner] = useState("")
  const [finAnnee, SetFinAnnee] = useState("")
  const [animationClassdebutAnnee, setAnimationClasdebutAnnee] = useState('');
  const [animationClassfinAnnee, setAnimationClasfinAnnee] = useState('');

  const [debutPremierTrime, SetDebutPremierTrime] = useState("")
  const [finPremierTrime, SetFinPremierTrime] = useState("")
  const [animationClassdebutPremierTrime, setAnimationClasdebutPremierTrime] = useState('');
  const [animationClassfinPremi, setAnimationClasfinPremi] = useState('');


  const [debutDeuxTrime, SetDebutDeuxTrime] = useState("")
  const [finDeuxTrime, SetFinDeuxTrime] = useState("")
  const [animationClassdebutDeuxTrim, setAnimationClasdebutDeuxTrim] = useState('');
  const [animationClassfinDeuxTrim, setAnimationClasfinDeuxTrim] = useState('');


  const [debutTroisTrim, SetDebutTroisTrim] = useState("")
  const [finTroisTrim, SetFinTroisTrim] = useState("")
  const [animationClassdebutTroisTrim, setAnimationClasdebutTroisTrim] = useState('');
  const [animationClassfinTroisTrim, setAnimationClasfinTroisTrim] = useState('');


  const handlesDate = (e) => {
    if (debutAnnee == " ") {
      toast("La date de début de l'année scolaire est obligatoire !!", {
        autoClose: 2000
      });
      setAnimationClasdebutAnnee('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
      setTimeout(() => {
        setAnimationClasdebutAnnee('')
      }, 3000)
      e.preventDefault()
      return false
    } else {

      e.preventDefault()
    }
    e.preventDefault()
  }



  return (
    <>
      {/* pour le cas il en a par defautt */}
      <div className='flex justify-center items-center h-full'>
        <div className="w-max h-max border p-4">
          <h2 className='text-[30px] animeBorder'>Horaire d'ouverture</h2>
          <div className="">
            <h4 className='text-orange-600 text-[22px] mt-3'>Année scolaire</h4>
            <p className='text-[20px] '><b>A/S</b>: 09/10/2023 à 05/07/2024</p>

            <h4 className='text-orange-600 text-[22px] mt-3'>Les trimestres</h4>
            <p className='text-[18px] leading-[2em] font-sans'><b>Premier trimestres</b>: 09/10/2023 à 05/07/2024</p>
            <p className='text-[18px] leading-[2em] font-sans'><b>Deuxième trimestres</b>: 09/10/2023 à 05/07/2024</p>
            <p className='text-[18px] leading-[2em] font-sans'><b>Troisième trimestres</b>: 09/10/2023 à 05/07/2024</p>
          </div>
          <div className="w-full flex  rounded  justify-end  mt-5">
            <Link to='/Admin/Contact/HoraireOuverture/ModifierHoraire' className='p-1 bg-orange-400  text-white hover:bg-orange-600 rounded transition-all  cursor-pointer w-max'> Modifier</Link>
          </div>
        </div>

      </div>

      {/* si aucun */}

      <div className='flex justify-center items-center'>
        <div className="w-[30em]  border p-4 h-full ">
          <h2 className='text-[30px] animeBorder'>Ajouter des horaires</h2>
          <form action="" onSubmit={handlesDate}>
            <div className="">
              <div className="ann">
                <h4 className='text-orange-600 text-[22px] mt-3'>Année scolaire</h4>
                <div className='text-[20px flex  p-1 justify-between'>
                  <div className="haire">
                    <p>Début</p>
                    <p className={`${animationClassdebutAnnee}`}><input type="date" onChange={(e) => { alert(e.target.value); SetDebutAnner(e.target.value) }} className='p-1 mt-[5px] border outline-none border-b-4 font-sans rounded border-orange-500' /></p>
                  </div>
                  <div className="haire">
                    <p>Fin</p>
                    <p><input type="date" className='p-1 mt-[5px] border outline-none border-b-4 font-sans rounded border-orange-500' /></p>
                  </div>
                </div>
              </div>



              <div className="mt-[30px] ">
                <h4 className='text-orange-600 text-[22px] mt-3'>Les trimestres</h4>

                <div className='text-[20px'>
                  <h4 className='text-blue-800'>Premier trimestre</h4>
                  <div className="flex w-full justify-between">
                    <div className="haire">
                      <p>Début</p>
                      <p><input type="date" className='p-1 mt-[5px] border outline-none border-b-4 font-sans rounded border-orange-500' /></p>
                    </div>
                    <div className="haire">
                      <p>Fin</p>
                      <p><input type="date" className='p-1 mt-[5px] border outline-none border-b-4 font-sans rounded border-orange-500' /></p>
                    </div>
                  </div>
                </div>

                <div className='text-[20px mt-[40px]'>
                  <h4 className='text-blue-800'>Deuxième trimestre</h4>
                  <div className="flex w-full justify-between">
                    <div className="haire">
                      <p>Début</p>
                      <p><input type="date" className='p-1 mt-[5px] border outline-none border-b-4 font-sans rounded border-orange-500' /></p>
                    </div>
                    <div className="haire">
                      <p>Fin</p>
                      <p><input type="date" className='p-1 mt-[5px] border outline-none border-b-4 font-sans rounded border-orange-500' /></p>
                    </div>
                  </div>
                </div>
                <div className='text-[20px mt-[40px]'>
                  <h4 className='text-blue-800'>Troisième trimestre</h4>
                  <div className="flex w-full justify-between">
                    <div className="haire">
                      <p>Début</p>
                      <p><input type="date" className='p-1 mt-[5px] border outline-none border-b-4 font-sans rounded border-orange-500' /></p>
                    </div>
                    <div className="haire">
                      <p>Fin</p>
                      <p><input type="date" className='p-1 mt-[5px] border outline-none border-b-4 font-sans rounded border-orange-500' /></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center">
              <label for="send" className="w-max  flex justify-end p-1 "><i title="Envoyer" class="bi bi-send bg-orange-600 rounded cursor-pointer px-5 py-1 text-white"></i></label>
              <input type="submit" id="send" hidden></input>
            </div>
          </form>
        </div>

      </div>
    </>
  )
}

export default HorairesOuverutre
