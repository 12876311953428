import React from 'react'
import EditorAnnonces from './EditorAnnonces'
function EditorAnnonceComponent() {
    return (
        <>
            <EditorAnnonces />
        </>

    )
}

export default EditorAnnonceComponent