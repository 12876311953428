import React from 'react'
import GereAnnonces from './GereAnnonces'
function GereAnnoncesComponent() {
    return (

        <GereAnnonces />

    )
}

export default GereAnnoncesComponent
