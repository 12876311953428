import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import { Link } from 'react-router-dom';
import EmpyServices from '../EmpyServeices/EmpyServeices';

function Temoignage() {
    const [lasttemoignage, setLasttemoignage] = useState([]);
    const [isPending, setPending] = useState(true);
    const [mobile3, setMobile3] = useState(window.innerWidth < 443);

    useEffect(() => {
        axios
            .get('https://irakoze.burundientempsreel.com/api/getlasttemoignage')
            .then((response) => {
                setLasttemoignage(response.data);
                setTimeout(() => {
                    setPending(false);
                }, 100);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        const handleSize = () => {
            setMobile3(window.innerWidth < 443);
        };

        window.addEventListener('resize', handleSize);
        return () => {
            window.removeEventListener('resize', handleSize);
        };
    }, []);

    return (
        <>
            {isPending && <Loading />}
            {lasttemoignage.length > 0 ? (

                <div className="w-[97%] bg-gray-50 rounded-lg mb-3 flex flex-col mt-[20px] justify-center items-center">
                    <h2 className="text-[30px] px-4 p-1 my-2 rounded">Témoignage</h2>
                    <div className="h-[1px] mb-1 bg-gray-300 w-full"></div>
                    <div className='grid sm:grid-cols-2 grid-cols-1 md:grid-cols-3 overflow-hidden gap-1  w-full h-max'>
                        {lasttemoignage.map((temo, index) => (
                            <div key={index} className="border bg-white rounded  p-2 sm:p-4 w-[97%] m-2">
                                <div className="w-full h-max rounded overflow-hidden">
                                    <p className="pl-3">
                                        <div className="font-bold text-[25px]">Auteur</div>
                                        <div className="text-blue-900 text-[22px]">{temo.nom} {temo.prenom}</div>
                                        <div className="text-rose-900 text-[22px]">{temo.categorie}</div>
                                    </p>
                                </div>
                                <div className="w-[95%]">
                                    <div className="font-bold underline italic text-1xl">{temo.titre}</div>
                                    <div className="">
                                        <div className="px-4 text-gray-700 text-[19px]">
                                            {temo.contenue && temo.contenue.length > 100
                                                ? temo.contenue.slice(0, 100) + '...'
                                                : temo.contenue}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="w-full flex justify-center m-4">
                        <Link to="/Visit/Temoignage" className={`bg-orange-400 ${mobile3 ? 'w-[80%]' : 'w-[20em]'} text-[20px] mb-4 py-2 text-center rounded-3xl cursor-pointer text-white hover:border-orange-600 hover:bg-white transition-all hover:text-gray-700 border border-white`}>Plus de témoignages</Link>
                    </div>
                </div>

            ) : (
                <div className="w-[97%] bg-gray-50 rounded-lg pb-10 flex flex-col mt-[20px] justify-center items-center">
                    <h2 className="text-[30px] px-4 p-1 my-2 rounded">Témoignage</h2>
                    <div className="h-[1px] mb-1 bg-gray-300 w-full"></div>
                    <div className="responsive1 flex justify-center p-2 w-full">
                        <div className="flex flex-col items-center w-[50em] widthsssssssss border border-orange-600 p-3 rounded-lg bg-slate-100 h-max">
                            <EmpyServices items="de témoignage" />

                            <div className="w-full flex justify-center m-4">
                                <Link to="/Visit/NouvelleTemoignage" className={`bg-orange-400 ${mobile3 ? 'w-[80%] text-[15px]' : 'w-[20em] text-[20px]'} mb-4 py-2 text-center rounded-3xl cursor-pointer text-white hover:border-orange-600 hover:bg-white transition-all hover:text-gray-700 border border-white`}>Laisse votre témoignages</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Temoignage;
