import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function ReseauxSocial() {
    const [lieuSocial, setLieuSocial] = useState(null);
    const [showOpenAddForm, setShowOpenAddForm] = useState(false);
    const [showOpenModifierForm, setShowOpenModifierForm] = useState(false);
    const [formData, setFormData] = useState({
        youtubelabel: '',
        lienyoutube: '',
        facebooklabel: '',
        lienfacebook: '',
        twitterlabel: '',
        lientwitter: ''
    });
    const [loding, setLoding] = useState(false)
    useEffect(() => {
        axios.get('https://irakoze.burundientempsreel.com/api/lieusociaux')
            .then(response => {
                if (response.data) {
                    setLieuSocial(response.data);
                } else {
                    setShowOpenAddForm(true);
                }
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données:', error);
            });
    }, [loding]);

    const handleModify = () => {
        setShowOpenModifierForm(true);
    };

    const handleAdd = () => {
        setShowOpenAddForm(true);
    };
    // Fonction de validation des liens YouTube
    const isValidYouTubeLink = (link) => {
        // Expression régulière pour valider les liens YouTube
        const youtubePattern = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
        return youtubePattern.test(link);
    };
    // Fonction de validation des liens Facebook
    const isValidFacebookLink = (link) => {
        // Expression régulière pour valider les liens Facebook
        const facebookPattern = /^(https?\:\/\/)?(www\.)?(facebook\.com)\/.+$/
        return facebookPattern.test(link);
    };
    // Fonction de validation des liens Twitter
    const isValidTwitterLink = (link) => {
        // Expression régulière pour valider les liens Twitter
        const twitterPattern = /^(https?\:\/\/)?(www\.)?(twitter\.com)\/.+$/
        return twitterPattern.test(link);
    };
    const handleSubmitAdd = (e) => {
        e.preventDefault();
        // Validation des champs vides
        if (formData.youtubelabel.trim() === '') {
            toast.error('Veuillez remplir le champ Label YouTube.');
            return false;
        }
        if (formData.lienyoutube.trim() === '') {
            toast.error('Veuillez remplir le champ Lien YouTube.');
            return false;
        }
        if (formData.facebooklabel.trim() === '') {
            toast.error('Veuillez remplir le champ Label Facebook.');
            return false;
        }
        if (formData.lienfacebook.trim() === '') {
            toast.error('Veuillez remplir le champ Lien Facebook.');
            return false;
        }
        if (formData.twitterlabel.trim() === '') {
            toast.error('Veuillez remplir le champ Label Twitter.');
            return false;
        }
        if (formData.lientwitter.trim() === '') {
            toast.error('Veuillez remplir le champ Lien Twitter.');
            return false;
        }
        // Validation des liens YouTube, Facebook et Twitter
        if (!isValidYouTubeLink(formData.lienyoutube)) {
            toast.error('Veuillez entrer un lien YouTube valide.');
            return false;
        }
        if (!isValidFacebookLink(formData.lienfacebook)) {
            toast.error('Veuillez entrer un lien Facebook valide.');
            return false;
        }
        if (!isValidTwitterLink(formData.lientwitter)) {
            toast.error('Veuillez entrer un lien Twitter valide.');
            return false;
        }
        axios.post('https://irakoze.burundientempsreel.com/api/lieusociaux', formData)
            .then(response => {
                setLieuSocial(response.data);
                setShowOpenAddForm(false);
                setLoding(!loding)
                toast.success('Les données ont bien été enregistrées.');
            })
            .catch(error => {
                console.log(error.message)
                toast.error('Erreur lors de l\'ajout des données');
            });
    };
    const handleSubmitModify = (e) => {
        e.preventDefault();
        // Validation des champs vides
        if (formData.youtubelabel.trim() === '') {
            toast.error('Veuillez remplir le champ Label YouTube.');
            return false;
        }
        if (formData.lienyoutube.trim() === '') {
            toast.error('Veuillez remplir le champ Lien YouTube.');
            return false;
        }
        if (formData.facebooklabel.trim() === '') {
            toast.error('Veuillez remplir le champ Label Facebook.');
            return false;
        }
        if (formData.lienfacebook.trim() === '') {
            toast.error('Veuillez remplir le champ Lien Facebook.');
            return false;
        }
        if (formData.twitterlabel.trim() === '') {
            toast.error('Veuillez remplir le champ Label Twitter.');
            return false;
        }
        if (formData.lientwitter.trim() === '') {
            toast.error('Veuillez remplir le champ Lien Twitter.');
            return false;
        }
        // Validation des liens YouTube, Facebook et Twitter
        if (!isValidYouTubeLink(formData.lienyoutube)) {
            toast.error('Veuillez entrer un lien YouTube valide.');
            return false;
        }
        if (!isValidFacebookLink(formData.lienfacebook)) {
            toast.error('Veuillez entrer un lien Facebook valide.');
            return false;
        }
        if (!isValidTwitterLink(formData.lientwitter)) {
            toast.error('Veuillez entrer un lien Twitter valide.');
            return false;
        }
        axios.put('https://irakoze.burundientempsreel.com/api/lieusociaux', formData)
            .then(response => {
                setLieuSocial(response.data);
                setShowOpenModifierForm(false);
                setLoding(!loding)
                toast.success('La modification des données a été effectuée avec succès.')
            })
            .catch(error => {
                console.log(error.message)
                toast.error('Erreur lors de la modification des données');
            });
    };

    return (
        <div className='w-full min-h-[85vh] p-3 flex flex-col'>
            {!showOpenModifierForm && <div className="w-full h-max border border-orange-700 rounded-2xl p-2">
                <h2 className='text-[25px] text-center w-full'>Réseaux social</h2>
                {lieuSocial ? (
                    <>
                        <div class="w-full">
                            <div class="flex flex-col pb-3 border-b w-full">
                                <Link to={lieuSocial.lienfacebook} className='mb-1 text-gray-500 md:text-lg dark:text-gray-400'><b><i className="bi bi-facebook text-blue-700 text-lg font-semibold"></i></b>: {lieuSocial.facebooklabel}</Link>
                            </div>
                            <div class="flex flex-col py-3 border-b w-full">
                                <Link to={lieuSocial.lienyoutube} className='mb-1 text-gray-500 md:text-lg dark:text-gray-400'><b><i className="bi bi-youtube text-red-500 text-lg font-semibold"></i></b>: {lieuSocial.youtubelabel}</Link>
                            </div>
                            <div class="flex flex-col pt-3 border-b w-full">
                                <Link to={lieuSocial.lientwitter} className='mb-1 text-gray-500 md:text-lg dark:text-gray-400'><b><i className="bi bi-twitter-x text-blue-700 text-lg font-semibold"></i></b>: {lieuSocial.twitterlabel}</Link>
                            </div>
                        </div>

                        <div className="w-full flex rounded justify-end pr-2">
                            <button onClick={handleModify}>
                                Modifier
                            </button>
                        </div>
                    </>
                ) : !showOpenModifierForm && !showOpenAddForm && (
                    <div className="w-full flex rounded justify-end pr-2">
                        <button onClick={handleAdd}>
                            Ajouter
                        </button>
                    </div>
                )}
                {/* Afficher le formulaire d'ajout si ouvert */}
                {showOpenAddForm && (
                    <form className="w-full my-3 border p-2 rounded-md mx-auto" onSubmit={handleSubmitAdd}>
                        <div className="mb-4 text-[14px] sm:text-[25px] text-clip font-semibold w-full ">Ajouter un réseau social</div>

                        <div className="rounded-lg w-full">
                            <label
                                class="block  tracking-wide  text-lg  mb-2"
                                htmlFor="floating_YouTube"
                            >
                                Label YouTube
                            </label>
                            <input

                                class={`block w-full bg-transparent border-gray-300  outline-none  focus:border focus:border-fuchsia-500   border  rounded py-3 px-4 mb-3 `} placeholder="Label youtube "

                                value={formData.youtubelabel} onChange={(e) => setFormData({ ...formData, youtubelabel: e.target.value })}
                                id="floating_YouTube"
                                type="text" name="floating_YouTube"
                            />
                        </div>

                        <div className="rounded-lg w-full">
                            <label
                                class="block  tracking-wide  text-lg  mb-2"
                                htmlFor="floating_YT_link"
                            >
                                Lien YouTube
                            </label>
                            <input

                                class={`block w-full bg-transparent border-gray-300  outline-none  focus:border focus:border-fuchsia-500   border  rounded py-3 px-4 mb-3 `}
                                placeholder='Lien de youtube'
                                value={formData.lienyoutube} onChange={(e) => setFormData({ ...formData, lienyoutube: e.target.value })}
                                id="floating_YouTube"
                                type="text" name="floating_YouTube"
                            />
                        </div>

                        <div className="rounded-lg w-full">
                            <label
                                class="block  tracking-wide  text-lg  mb-2"
                                htmlFor="floating_Facebook"
                            >
                                Label Facebook
                            </label>
                            <input

                                class={`block w-full bg-transparent border-gray-300  outline-none  focus:border focus:border-fuchsia-500   border  rounded py-3 px-4 mb-3 `}
                                placeholder='Label Facebook'
                                value={formData.facebooklabel} onChange={(e) => setFormData({ ...formData, facebooklabel: e.target.value })}
                                id="floating_Facebook"
                                type="text"
                            />
                        </div>


                        <div className="rounded-lg w-full">
                            <label
                                class="block  tracking-wide  text-lg  mb-2"
                                htmlFor="floating_FB_link"
                            >
                                Lien Facebook
                            </label>
                            <input

                                class={`block w-full bg-transparent border-gray-300  outline-none  focus:border focus:border-fuchsia-500   border  rounded py-3 px-4 mb-3 `}
                                placeholder='Lien Facebook'
                                value={formData.lienfacebook} onChange={(e) => setFormData({ ...formData, lienfacebook: e.target.value })}
                                id="floating_FB_link"
                                type="text"
                                name='floating_FB_link'
                            />
                        </div>



                        <div className="rounded-lg w-full">
                            <label
                                class="block  tracking-wide  text-lg  mb-2"
                                htmlFor="floating_Twitter"
                            >
                                Label Twitter
                            </label>
                            <input

                                class={`block w-full bg-transparent border-gray-300  outline-none  focus:border focus:border-fuchsia-500   border  rounded py-3 px-4 mb-3 `}
                                placeholder='Label Twitter'
                                value={formData.twitterlabel} onChange={(e) => setFormData({ ...formData, twitterlabel: e.target.value })}
                                id="floating_Twitter"
                                type="text"
                                name='floating_Twitter'
                            />
                        </div>

                        <div className="rounded-lg w-full">
                            <label
                                class="block  tracking-wide  text-lg  mb-2"
                                htmlFor="floating_TW_link"
                            >
                                Lien Twitter
                            </label>
                            <input

                                class={`block w-full bg-transparent border-gray-300  outline-none  focus:border focus:border-fuchsia-500   border  rounded py-3 px-4 mb-3 `}
                                placeholder='Lien Twitter'
                                value={formData.lientwitter} onChange={(e) => setFormData({ ...formData, lientwitter: e.target.value })}
                                id="floating_TW_link"
                                type="text"
                                name='floating_TW_link'
                            />
                        </div>
                        {/* Bouton de soumission */}
                        <button type='submit' className="block w-full py-2.5 px-4 bg-blue-500 text-white rounded-md text-sm font-medium shadow hover:bg-blue-600 focus:outline-none focus:bg-blue-600">Ajouter</button>
                    </form>
                )}


            </div>
            }
            {/* Afficher le formulaire de modification si ouvert */}
            {showOpenModifierForm && (
                <form className="w-full my-3 border p-2 rounded-md mx-auto" onSubmit={handleSubmitModify}>
                    <div className="mb-4 text-[14px] sm:text-[25px] text-clip font-semibold w-full ">Modifier les réseaux sociaux</div>

                    <div className="rounded-lg w-full">
                        <label
                            class="block  tracking-wide  text-lg  mb-2"
                            htmlFor="floating_YouTube"
                        >
                            Label YouTube
                        </label>
                        <input

                            class={`block w-full bg-transparent border-gray-300  outline-none  focus:border focus:border-fuchsia-500   border  rounded py-3 px-4 mb-3 `} placeholder="Label youtube "

                            value={formData.youtubelabel} onChange={(e) => setFormData({ ...formData, youtubelabel: e.target.value })}
                            id="floating_YouTube"
                            type="text" name="floating_YouTube"
                        />
                    </div>

                    <div className="rounded-lg w-full">
                        <label
                            class="block  tracking-wide  text-lg  mb-2"
                            htmlFor="floating_YT_link"
                        >
                            Lien YouTube
                        </label>
                        <input

                            class={`block w-full bg-transparent border-gray-300  outline-none  focus:border focus:border-fuchsia-500   border  rounded py-3 px-4 mb-3 `}
                            placeholder='Lien de youtube'
                            value={formData.lienyoutube} onChange={(e) => setFormData({ ...formData, lienyoutube: e.target.value })}
                            id="floating_YouTube"
                            type="text" name="floating_YouTube"
                        />
                    </div>

                    <div className="rounded-lg w-full">
                        <label
                            class="block  tracking-wide  text-lg  mb-2"
                            htmlFor="floating_Facebook"
                        >
                            Label Facebook
                        </label>
                        <input

                            class={`block w-full bg-transparent border-gray-300  outline-none  focus:border focus:border-fuchsia-500   border  rounded py-3 px-4 mb-3 `}
                            placeholder='Label Facebook'
                            value={formData.facebooklabel} onChange={(e) => setFormData({ ...formData, facebooklabel: e.target.value })}
                            id="floating_Facebook"
                            type="text"
                        />
                    </div>


                    <div className="rounded-lg w-full">
                        <label
                            class="block  tracking-wide  text-lg  mb-2"
                            htmlFor="floating_FB_link"
                        >
                            Lien Facebook
                        </label>
                        <input

                            class={`block w-full bg-transparent border-gray-300  outline-none  focus:border focus:border-fuchsia-500   border  rounded py-3 px-4 mb-3 `}
                            placeholder='Lien Facebook'
                            value={formData.lienfacebook} onChange={(e) => setFormData({ ...formData, lienfacebook: e.target.value })}
                            id="floating_FB_link"
                            type="text"
                            name='floating_FB_link'
                        />
                    </div>



                    <div className="rounded-lg w-full">
                        <label
                            class="block  tracking-wide  text-lg  mb-2"
                            htmlFor="floating_Twitter"
                        >
                            Label Twitter
                        </label>
                        <input

                            class={`block w-full bg-transparent border-gray-300  outline-none  focus:border focus:border-fuchsia-500   border  rounded py-3 px-4 mb-3 `}
                            placeholder='Label Twitter'
                            value={formData.twitterlabel} onChange={(e) => setFormData({ ...formData, twitterlabel: e.target.value })}
                            id="floating_Twitter"
                            type="text"
                            name='floating_Twitter'
                        />
                    </div>

                    <div className="rounded-lg w-full">
                        <label
                            class="block  tracking-wide  text-lg  mb-2"
                            htmlFor="floating_TW_link"
                        >
                            Lien Twitter
                        </label>
                        <input

                            class={`block w-full bg-transparent border-gray-300  outline-none  focus:border focus:border-fuchsia-500   border  rounded py-3 px-4 mb-3 `}
                            placeholder='Lien Twitter'
                            value={formData.lientwitter} onChange={(e) => setFormData({ ...formData, lientwitter: e.target.value })}
                            id="floating_TW_link"
                            type="text"
                            name='floating_TW_link'
                        />
                    </div>
                    {/* Bouton de soumission */}
                    <div className='flex'>
                        <div onClick={() => setShowOpenModifierForm(false)} className="block text-center mx-2 w-full py-2.5 px-4 bg-green-500 cursor-pointer text-white rounded-md text-sm font-medium shadow hover:bg-green-600 focus:outline-none focus:bg-blue-600">Retour</div>
                        <button type='submit' className="block w-full mx-2 py-2.5 px-4 bg-blue-500 text-white rounded-md text-sm font-medium shadow hover:bg-blue-600 focus:outline-none focus:bg-blue-600">Modifier</button>
                    </div>
                </form>


            )}
        </div >
    );
}

export default ReseauxSocial;
