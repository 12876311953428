import React from 'react'
import Sloger from './Sloger'
import Evenement from './Evenement'
import Temoignage from './Temoignage'
import Annonce from './Annonce'
import Resource from './Resource'
function HomComponent() {

  return (
    <div className='bg-gray-200 flex flex-col items-center'>
      <Sloger />
      <Evenement />
      <Annonce />
      <Resource />
      <Temoignage />
    </div>
  )
}

export default HomComponent
