import React from 'react'
import EditorRessources from './EditorRessources'

function EditorRessourcesComponent() {
    return (
        <>

            <EditorRessources />
        </>
    )
}

export default EditorRessourcesComponent