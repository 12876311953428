import React from 'react'
import DetailAnnonceComponent from '../../../component/Visitor/Annonce/DetailAnnonceComponent'
function DetailAnnoncePage() {


    return (
        <DetailAnnonceComponent />
    )
}

export default DetailAnnoncePage
