/* eslint-disable no-dupe-keys */
import React, { useRef } from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios';
import { Link } from 'react-router-dom';



function AddTemoignageAdmin() {

    const [isTitre, SetisTitre] = useState('')
    const [animationClassTitre, setAnimationClassTitre] = useState('');
    const elementRefisTitre = useRef(null)

    const [nom_complet, Setnom_complet] = useState('')
    const [animationClassNom, setAnimationClassNom] = useState('');
    const elementRefisnom_complet = useRef(null)


    const [isSelectValue, SetisSelectValue] = useState('Particulier')

    const id = localStorage.getItem('v')
    const [isTextarea, SetisTextarea] = useState('')
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const elementRefisisTextatarea = useRef(null)



    const handleSubmit = (e) => {
        e.preventDefault()
        if (isTitre.trim() === '') {
            toast.warning('Le titre de témoignage est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassTitre('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassTitre(' ')
            }, 3000)
            return;
        }
        else if (isTextarea.trim() === '') {
            toast.warning('Le contenue de la temoignage est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassTextarea(' ')
            }, 3000)
            return;
        }
        axios.post('https://irakoze.burundientempsreel.com/api/Adminaddtemoignage', {
            userId: id,
            categorie: isSelectValue,
            titre: isTitre,
            contenue: isTextarea

        }).then((Response) => {
            toast.success(Response.data);
            Setnom_complet('')
            SetisTitre('')
            SetisTextarea('')
        }).catch((error) => {
            toast.error(error.message);
        });
    };
    return (
        <div className='flex  min-h-[87vh] flex-col items-start'>
            <div className='w-full px-5 rounded-b-lg  flex items-center justify-between border-b border-gray-300 flex-row'>
                <h2 className="sm:text-lg max-sm:hidden font-semibold">Vos témoignage</h2>
                <Link to='/' className='bg-orange-500 text-white hover:no-underline focus:no-underline hover:text-white focus:text-white my-2 text-[13px] sm:text-[17px] rounded h-max w-max px-2 py-1'>
                    Aller à la page d'accueil
                </Link>
            </div>
            <div className="border rounded-xl border-orange-700 m-3 p-2  w-[95%] h-max ">
                <h3 className=' p-2 max-sm:text-[20px] text-center mb-[1em]'>Vos témoignage</h3>
                <form onSubmit={handleSubmit}>
                    <div class="w-full mb-5">
                        <label
                            class="block  tracking-wide  text-lg  mb-2"
                            for="grid-Title"
                        >
                            Tritre
                        </label>
                        <input
                            ref={elementRefisTitre}
                            class={`block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3 ${animationClassTitre} `}
                            value={isTitre}
                            placeholder=" Tritre"
                            onInput={(e) => SetisTitre(e.target.value)}
                        />
                    </div>




                    <div class="w-full mb-5">
                        <label
                            class="block  tracking-wide  text-lg  mb-1"
                            for="grid-Title"
                        >
                            Le contenue
                        </label>
                        <textarea
                            placeholder=" Le contenue"
                            ref={elementRefisisTextatarea}
                            class={`block w-full bg-transparent min-h-[11em]  resize-y outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3 ${animationClassTextarea} `}

                            value={isTextarea}
                            onChange={(e) => { SetisTextarea(e.target.value) }}
                        ></textarea>
                    </div>
                    <div className="flex justify-end items-center">
                        <input type="submit" className="w-max  flex justify-end p-1 bg-orange-600 rounded cursor-pointer px-5 py-1 text-white" id="send"></input>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddTemoignageAdmin
