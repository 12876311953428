import React, { useEffect, useState } from 'react';
import { classes } from './datas'; // Assurez-vous que le chemin vers le fichier de données est correct
import { Link } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { Modal, Sidenav } from 'rsuite';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';//pour affciher si le pdf possed des lients et les autre
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Classes() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);

    const [mobile3, Setmonile3] = useState(window.innerWidth < 318)
    const [showTextarea, getshowTextarea] = useState(false)
    const [showTextareaIndex, getshowTextareaIndex] = useState(null)
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, []);

    const onFileChange = async event => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setPreviewUrl(URL.createObjectURL(file));

        if (file && file.type === 'application/pdf') {
            const reader = new FileReader();
            reader.onload = async () => {
                const pdfjsLib = require('pdfjs-dist/legacy/build/pdf');
                pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

                const buffer = reader.result;
                const pdfData = new Uint8Array(buffer);
                const pdfDoc = await pdfjsLib.getDocument(pdfData).promise;
                const page = await pdfDoc.getPage(1);
                const scale = 2;
                const viewport = page.getViewport({ scale });

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };
                await page.render(renderContext).promise;

                const pdfPreviewUrl = canvas.toDataURL('image/png');
                setPdfPreviewUrl(pdfPreviewUrl);
            };

            reader.readAsArrayBuffer(file);
        } else {
            setPdfPreviewUrl(null);
        }
    };


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const showTxtarae = (index, value) => {
        getshowTextarea(value)
        getshowTextareaIndex(index)
    }
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);


    return (
        <div className='p-2'>
            <div className='text-3xl w-full '>Liste des classes en section sss....</div>
            <div className='w-full flex flex-wrap'>
                {classes.map((data, posi) => {
                    return (
                        <div key={posi} className={` border border-orange-400 h-max relative rounded-lg p-4 m-1 `}>
                            <div className="flex   flex-col">
                                <div className=" flex  flex-col">
                                    <span className={`leading-none font-bold  ${mobile3 ? 'text-[18px]' : 'sm:text-3xl text-[20px]   '}`}>
                                        {data.classe}<sup className="underline">èm</sup>Année
                                    </span>
                                    <div className={` text-gray-500  font-bold mt-2 ${mobile3 ? 'text-[18px]' : 'text-xl'} `}>
                                        Nombres des élèves : <span>{data.nombreTotal_Eleves}</span>
                                    </div>
                                </div>
                                <div className={`text-[18px] flex justify-between  max-sm:w-full text-center mt-4 font-normal text-gray-500 ${mobile3 ? 'flex-col' : ''}`}>
                                    <div className={`p-2 bg-[#0000ff07] border border-orange-100 rounded-lg ${mobile3 ? 'mb-3' : ''} `}>
                                        <div className={`font-bold ${mobile3 ? 'text-[15px]' : ''}`}>Garçons</div>
                                        <div className={`flex j ${mobile3 ? 'w-full justify-around' : 'w-24 justify-between'}`}>
                                            <div className={`font-semibold text-blue-500 ${mobile3 ? 'text-[12px]' : ''}`}>{data.nombresGarcon}</div>
                                            <div>~</div>
                                            <div className={`font-semibold text-blue-700 ${mobile3 ? 'text-[12px]' : ''}`}>{(data.nombresGarcon * 100 / data.nombreTotal_Eleves).toFixed()}%</div>
                                        </div>
                                    </div>
                                    <div className={`p-2 bg-[#0000ff07] border border-orange-100 rounded-lg ${mobile3 ? 'mb-3' : ''} `}>
                                        <div className={`font-bold ${mobile3 ? 'text-[15px]' : ''}`}>Fille</div>
                                        <div className={`flex j ${mobile3 ? 'w-full justify-around' : 'w-24 justify-between'}`}>
                                            <div className={`font-semibold text-blue-500 ${mobile3 ? 'text-[12px]' : ''}`}>{data.nombresFille}</div>
                                            <div>~</div>
                                            <div className={`font-semibold text-blue-700 ${mobile3 ? 'text-[12px]' : ''}`}>{(data.nombresFille * 100 / data.nombreTotal_Eleves).toFixed()}%</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex mt-2 text-lg"><div>Result à publier :</div> <div className="text-blue-700 ml-1">{data.result_publier}% </div></div>
                                <div className="flex mt-2 text-gray-600"><div>Année scolaire :</div> <div className="text-blue-700 font-semibold ml-1"> 2023-2024</div></div>
                            </div>
                            <div className="w-full flex justify-center items-center mt-4">
                                <div type='button' onClick={() => showTxtarae(posi, true)} className="border border-blue-100 px-4 py-1 bg-blue-400 hover:bg-blue-600 transition-all text-white rounded-md cursor-pointer">
                                    Publier
                                </div>
                            </div>
                            <div className={` bg-transparent left-0 px-1 bg-red-600 p-1 bottom-1 w-full absolute ${showTextarea && showTextareaIndex == posi ? 'h-max' : 'h-[0em] overflow-hidden'}`}>
                                <Sidenav style={{ padding: '10px' }}>
                                    <div>
                                        <input placeholder='Titre' className=' resize-none mt-2 bg-transparent border-orange-600 rounded-md border px-2 py-3 w-full outline-none focus:border-2 focus:border-orange-700 p-2' />

                                    </div>
                                    <div >
                                        <label htmlFor='flies' className=' cursor-pointer w block  border-orange-600  w-full outline-none focus:border-2 focus:border-orange-700  mt-2  rounded-md border px-2 py-3  p-2'>
                                            Fichier
                                        </label>
                                        <input onChange={onFileChange} type='file' accept=".pdf, image/*, video/*" id='flies' hidden />
                                    </div>

                                    {previewUrl && (
                                        <div onClick={handleOpen} className='w-10 h-10 cursor-pointer border rounded overflow-hidden border-orange-600 mt-1 p-0.5'>
                                            {selectedFile && selectedFile.type === 'video/mp4' ? (
                                                <video src={previewUrl} controls width="250" />
                                            ) : selectedFile && selectedFile.type === 'application/pdf' ? (
                                                <>
                                                    {pdfPreviewUrl && (
                                                        <img src={pdfPreviewUrl} alt="  " className='w-full h-full object-cover' />
                                                    )}
                                                    <Document
                                                        className='pdf-document'
                                                        file={previewUrl}
                                                        onLoadSuccess={onDocumentLoadSuccess}
                                                    >
                                                        <Page pageNumber={pageNumber} />
                                                    </Document>
                                                </>
                                            ) : (
                                                <img src={previewUrl} alt="  " className='w-full h-full object-cover' />
                                            )}
                                        </div>
                                    )}
                                    <Modal size='lg' open={open} onClose={handleClose}>
                                        <Modal.Header>
                                            <Modal.Title>Photos de couverture:<span className='italic font-serif'>{selectedFile && selectedFile.name.split(".")[0]}</span></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='flex justify-center'>
                                            {selectedFile && selectedFile.type === 'application/pdf' ? (
                                                <>
                                                    {pdfPreviewUrl && (
                                                        <img src={pdfPreviewUrl} alt="  " className='w-full h-full object-contain' />
                                                    )}
                                                </>
                                            ) : selectedFile && selectedFile.type === 'video/mp4' ? (
                                                <>
                                                    <video src={previewUrl} controls width="250" />
                                                </>
                                            ) : (
                                                <>
                                                    <img src={previewUrl} alt='    ' className='w-full h-full object-contain' />
                                                </>
                                            )}
                                        </Modal.Body>
                                    </Modal>
                                    <div>
                                        <textarea placeholder='Description' className='  border-orange-600 rounded-md border px-2 py-3 w-full outline-none focus:border-2 focus:border-orange-700 resize-none mt-1 bg-transparent  h-[5em]   p-2'>

                                        </textarea>
                                    </div>
                                    <div className="w-full flex justify-center items-center mt-1">
                                        <input type='button' value='Publier' onClick={() => alert('send Frormnulaire')} className="border border-blue-100 px-4 py-1 bg-blue-400 hover:bg-blue-600 transition-all text-white rounded-md cursor-pointer" />
                                    </div>
                                </Sidenav>
                            </div>
                        </div>
                    )
                })}
                <div >

                    <input onChange={onFileChange} type='file' accept=".pdf, image/*, video/*" id='flies' hidden />
                </div>
            </div>
        </div>

    );
}

export default Classes;
