/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { Popover, Radio, RadioGroup, Sidenav, Whisper } from 'rsuite';
import { Icon } from '@rsuite/icons';

import { FadeLoader } from 'react-spinners';


function NavBars({ SetisAdmin, isAdmin, GetValuesThemes }) {
    const history = useNavigate();
    const [isClicked, SetisClicked] = useState(false)
    const [imageLoading, SetImageLoading] = useState(true)
    useEffect(() => {
        const handleKeyPress = () => {
            SetisClicked(false)
        };
        window.addEventListener('click', handleKeyPress);
        return () => {
            window.removeEventListener('click', handleKeyPress);
        };
    }, []);


    const id = localStorage.getItem('v');
    const [user, setUser] = useState({});
    useEffect(() => {
        if (id) {
            axios.get(`https://irakoze.burundientempsreel.com/api/usergetprofilekk/${id}`).then((response) => {
                setUser(response.data);
            }).catch((err) => {
                console.log(err.message)
            })
        }
    }, [id])

    const Logout = () => {
        localStorage.removeItem('v');
        localStorage.removeItem('vvvvv');
        SetisAdmin(false)
        history('/');
    }


    const { pathname } = useLocation()
    let AdminAceuil = /^\/AdminAccueil.*/
    let AdminEventAjout = /^\/AdminEvent\/AjoutEvenement.*/

    let AdminTemoignage = /^\/AdminTemoig\/Temoignage.*/
    let AdminTemoignageStandBuy = /^\/Admin\/StandBuy.*/


    let AdminAnnonces = /^\/AdminAnnonce\/Annonce.*/
    let AdminAnnoncesAjouter = /^\/AdminAnnonce\/AjouterAnnonce.*/

    let AdminEnseignant = /^\/AdminEnseignant.*/

    let AdminRessources = /^\/AdmiRessources\/Ressources.*/
    let AdminRessourcesAjouter = /^\/AdmiRessources\/AjouteRessources.*/

    let AdminMembre = /^\/AdminMembers\/Members.*/
    let AdminMembreAjouter = /^\/AdminMembers\/AjouterMembers.*/

    let AdminContact = /^\/AdminContact\/Contact.*/
    let AdminContactReseauxSoci = /^\/AdminContact\/SocialContact.*/
    let AdminContactSuggestion = /^\/AdminContact\/SuggestionContact.*/

    let AdminFonction = /^\/AdminFonction.*/



    console.log('user', user)



    const [menu, SetMenue] = useState(false);
    const [mobile, SetMobile] = useState(window.innerWidth < 776);
    const [mobile1, SetMobile1] = useState(window.innerWidth < 577);
    const [mobile2, SetMobile2] = useState(window.innerWidth < 400);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 776)
            SetMobile1(window.innerWidth < 577)
            SetMobile2(window.innerWidth < 400)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    useEffect(() => {

        const hundleClick = () => {
            SetMenue(false)
        }
        window.addEventListener('click', hundleClick)
        return () => {
            window.addEventListener('click', hundleClick)
        }
    }, [])





    const Sun = React.forwardRef((props, ref) => (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi w-5 h-5 bi-sun" viewBox="0 0 16 16">
            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
        </svg>
    ));

    const Moon = React.forwardRef((props, ref) => (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-moon-fill h-7 y-7 cursor-pointer" viewBox="0 0 16 16">
            <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
        </svg>
    ));

    const SemiMoon = React.forwardRef((props, ref) => (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-circle-half h-5 w-5" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 0 8 1zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16" />
        </svg>
    ));

    const deconnection = React.forwardRef((propos, ref) => (
        <svg {...propos} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-circle-half h-5 w-5" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
        </svg>
    ))


    const Settings = React.forwardRef((propos, ref) => (
        <svg {...propos} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-circle-half h-5 w-5" viewBox="0 0 16 16">
            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
        </svg>
    ))







    const triggerRef = useRef();

    const close = () => triggerRef.current && triggerRef.current.close();

    const [isDark, GetisDark] = useState('light')
    const handleChange = (value) => {
        GetisDark(value);
        close()
        localStorage.setItem('isDark', value);
    };

    useEffect(() => {
        const storedValue = localStorage.getItem('isDark')
        if (storedValue) {
            GetisDark(storedValue);
        }

    }, [isDark]);

    GetValuesThemes(isDark)


    const [isLoading, GetisLoading] = useState(true)
    const [isLoading1, GetisLoading1] = useState(true)
    const [isLoading2, GetisLoading2] = useState(true)
    const hundleLoading = () => {
        GetisLoading(false)
    }

    const hundleLoading1 = () => {
        GetisLoading1(false)

    }

    const hundleLoading2 = () => {
        GetisLoading2(false)

    }



    return (
        <div className={` ${mobile ? 'justify-between' : 'justify-end'}  flex  items-center px-0.5 sm:px-2 h-[13vh] max-sm:h-[8vh]  relative border-b-2 border-orange-500`}>

            <div
                className={`sm:mx-2 mx-0.5 cursor-pointer h-max  p-1 text-white rounded bg-gradient-to-r from-orange-500 to-orange-300 hover:from-orange-700 hover:to-orange-500 transition-all ${mobile ? 'block' : 'hidden'}`}>
                <div onClick={(e) => {
                    SetMenue(!menu);
                    e.stopPropagation()
                }}>

                    {menu ? (
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </>
                    ) : (
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                            </svg>
                        </>
                    )}



                </div>
            </div>

            <div className={`w-[50px] h-[50px] max-sm:w-[40px] max-sm:h-[40px] ${mobile ? 'block' : 'hidden'} mt-1 border relative rounded flex justify-center items-center overflow-hidden `}>
                {isLoading &&
                    <div className={`absolute  w-full z-[60]  h-full ${isDark == 'light' ? ' bg-[#ffffff]' : 'bg-[#242323]'} pl-4 pt-2  top-0 left-0   flex items-center justify-center`}>
                        <FadeLoader
                            color="#36d7b7"
                            height={15}
                            width={2}
                            margin={-5}
                        />
                    </div>
                }
                <img draggable='false' onLoad={hundleLoading}
                    src="https://irakoze.burundientempsreel.com/uploads/Photo/LENGA2.png" alt="" className='block w-full h-full object-cover object-center' />
            </div>


            <Link to='/ajouTemoin' className='border border-orange-500 mx-1 p-1 sm:p-2 rounded'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-badge-tm-fill h-8  w-8 max-sm:h-6 max-sm:w-6" viewBox="0 0 16 16">
                    <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm3.295 3.995V11H4.104V5.995h-1.7V5H7v.994H5.295zM8.692 7.01V11H7.633V5.001h1.209l1.71 3.894h.039l1.71-3.894H13.5V11h-1.072V7.01h-.057l-1.42 3.239h-.773L8.75 7.008h-.058z" />
                </svg>
            </Link>


            <Link to='/regis' className='border border-orange-500 mx-1 rounded p-1 sm:p-2'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi h-8  w-8 max-sm:h-6 max-sm:w-6  bi-person-fill-add" viewBox="0 0 16 16">
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                    <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                </svg>
            </Link>

            <Link to='/compte' className='border mx-1 border-orange-500 p-1 sm:p-2 rounded'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check h-8  w-8 max-sm:h-6 max-sm:w-6" viewBox="0 0 16 16">
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                    <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                </svg>
            </Link>
            <div className='w-max h-max relative'>
                <Whisper
                    placement='auto'
                    trigger='click'
                    ref={triggerRef}
                    speaker={
                        <Popover>
                            <div>
                                <div>
                                    <div class='w-24 h-24 relative overflow-hidden'>

                                        {isLoading2 && <div className={`absolute  w-full z-[60]  h-full ${isDark == 'light' ? ' bg-[#ffffff]' : 'bg-[#242323]'} pl-4 pt-2  top-0 left-0   flex items-center justify-center`}>
                                            <FadeLoader
                                                color="#36d7b7"
                                                height={15}
                                                width={2}
                                                margin={-5}
                                            />
                                        </div>
                                        }
                                        <img draggable='false' onLoad={hundleLoading2}
                                            src={`https://irakoze.burundientempsreel.com/uploads/photosuser/${user.photo}`} alt="" className='w-full h-full object-cover' />
                                    </div>
                                    <Link onClick={() => close()} to='/Profile' className=' mt-2 italic text-[20px]'>{user.nom}gg {user.prenom}</Link>
                                    <div className="w-full h-[1px] my-2 bg-slate-300"></div>
                                </div>
                                <p className="text-[17px] my-1 cursor-default">Thème</p>
                                <RadioGroup name="radio-name" value={isDark} onChange={handleChange}>
                                    <label htmlFor="lumi" className={`flex px-2 rounded cursor-pointer hover:bg-[#29328538] w-full justify-between items-center ${isDark === 'light' ? 'text-orange-600' : null}`}>
                                        <div className="flex items-center justify-start w-[15em]  ">
                                            <div className="mr-2">
                                                <Icon as={Sun} />
                                            </div>
                                            <div className="text-[18px]">Mode lumière</div>
                                        </div>
                                        <div className=" flex w-7"> <Radio id="lumi" checked={isDark === 'light'} value="light"></Radio></div>
                                    </label>
                                    <label htmlFor="sombe" className={`flex px-2 rounded cursor-pointer hover:bg-[#29328538] w-full justify-between items-center ${isDark === 'dark' ? 'text-orange-600' : null}`}>
                                        <div className="flex items-center w-[15em] ">
                                            <div className="mr-2"><Icon as={Moon} /></div>
                                            <div className="text-[18px]">Mode sombre</div>
                                        </div>
                                        <div className=" flex w-7 ">
                                            <Radio id="sombe" checked={isDark === 'dark'} value="dark"></Radio>
                                        </div>
                                    </label>

                                    <label htmlFor="contrast" className={`flex px-2 rounded cursor-pointer hover:bg-[#29328538] w-full justify-between items-center ${isDark === 'high-contrast' ? 'text-orange-600' : null}`}>
                                        <div className="flex  w-[15em] items-center">
                                            <div className="mr-2"><Icon as={SemiMoon} /></div>
                                            <div className="text-[17px]">Mode contraste</div>
                                        </div>
                                        <div className=" flex w-7 ">
                                            <Radio id="contrast" checked={isDark === 'high-contrast'} value="high-contrast"></Radio></div>
                                    </label>
                                </RadioGroup>
                                <div className="w-full h-[1px] my-2 bg-slate-300"></div>
                                <div onClick={Logout} className={`flex p-2 mb-1 rounded cursor-pointer hover:bg-[#29328538] w-full justify-between items-center `}>
                                    <div className="flex  w-[15em] items-center">
                                        <div className="mr-2"><Icon as={deconnection} /></div>
                                        <div className="text-[17px]">Deconnecte</div>
                                    </div>
                                </div>
                            </div>

                        </Popover>
                    }
                >
                    <div className="flex justify-end items-center h-full  mr-1 sm:px-3">
                        <div className="logos w-[50px] h-[50px] max-sm:w-[40px] max-sm:h-[40px] border border-orange-600  rounded-lg cursor-pointer relative overflow-hidden ">

                            {isLoading1 && <div className={`absolute  w-full z-[60]  h-full ${isDark == 'light' ? ' bg-[#ffffff]' : 'bg-[#242323]'} pl-4 pt-2  top-0 left-0   flex items-center justify-center`}>
                                <FadeLoader
                                    color="#36d7b7"
                                    height={15}
                                    width={2}
                                    margin={-5}
                                />
                            </div>
                            }
                            <img draggable='false' onLoad={hundleLoading1}
                                src={`https://irakoze.burundientempsreel.com/uploads/photosuser/${user.photo}`} alt="" className='w-full h-full object-cover' />

                            <Link onClick={() => close()} to='/CompteFum' className=' mt-2 italic text-[20px]'>{user.nom_complet} </Link>
                            <div className="w-full h-[1px] my-2 bg-slate-300"></div>
                        </div>
                    </div>

                </Whisper>
            </div>

            <div className={`w-[14em] ${menu ? 'left-0' : '-left-full'} transition-all fixed z-[200000000000] top-[13vh] max-sm:top-[8vh]  h-full  border-r-2 border-orange-300  flex-col items-center ${mobile ? 'flex' : 'hidden'} `}>
                <Sidenav style={{ height: '87vh' }}>
                    <div className="w-full  h-full overflow-x-hidden overflow-y-auto border-t-2 border-orange-500">
                        <div className="w-full h-100%">
                            {AdminAceuil.test(pathname) || pathname == '/' ? (
                                <Link to='/AdminAccueil' className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all '>
                                    <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Acceuil</p>
                                </Link>
                            ) : (
                                <Link to='/AdminAccueil' className='flex  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all '>
                                    <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Acceuil</p>
                                </Link>
                            )}

                            {AdminEventAjout.test(pathname) || AdminEventAjout.test(pathname) ? (
                                <Link to='/AdminEvent/AjoutEvenement' className='flex   justify-start items-center  p-1 w-full h-12    pb-2  bg-orange-600 text-fuchsia-50  transition-all '>
                                    <i class="bi bi-calendar4-event text-[20px] mr-4"></i>
                                    <p className='sizeText'>Evénement</p>
                                </Link>

                            ) : (
                                <Link to='/AdminEvent/AjoutEvenement' className='flex  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all '>
                                    <i class="bi bi-calendar4-event text-[20px] mr-4"></i>
                                    <p className='sizeText'>Evénement</p>
                                </Link>
                            )}

                            {AdminTemoignage.test(pathname) || AdminTemoignageStandBuy.test(pathname) ? (
                                <Link to="/AdminTemoig/Temoignage" className='flex   justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600  transition-all text-fuchsia-50  '>
                                    <i class="bi bi bi-badge-tm  text-[20px] mr-4"></i>
                                    <p className='sizeText'>Témoignage</p>
                                </Link>
                            ) : (
                                <Link to="/AdminTemoig/Temoignage" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all '>
                                    <i class="bi bi bi-badge-tm  text-[20px] mr-4"></i>
                                    <p className='sizeText'>Témoignage</p>
                                </Link>
                            )}


                            {AdminAnnonces.test(pathname) || AdminAnnoncesAjouter.test(pathname) ? (
                                <Link to="/AdminAnnonce/Annonce" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                                    <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Annonce</p>
                                </Link>
                            ) : (
                                <Link to="/AdminAnnonce/Annonce" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all '>
                                    <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Annonce</p>
                                </Link>
                            )}


                            {AdminRessources.test(pathname) || AdminRessourcesAjouter.test(pathname) ? (
                                <Link to="/AdmiRessources/Ressources" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                                    <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                                    <p className='sizeText'>Ressource</p>
                                </Link>
                            ) : (
                                <Link to="/AdmiRessources/Ressources" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all '>
                                    <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                                    <p className='sizeText'>Ressource</p>
                                </Link>
                            )}
                            {AdminMembre.test(pathname) || AdminMembreAjouter.test(pathname) ? (
                                <Link to="/AdminMembers/Members" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                                    <i class="bi bi-people-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Membre</p>
                                </Link>
                            ) : (
                                <Link to="/AdminMembers/Members" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all '>
                                    <i class="bi bi-people-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Membre</p>
                                </Link>
                            )}


                            {AdminEnseignant.test(pathname) ? (
                                <Link to="/AdminEnseignant" className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                                    <i class="bi bi-book-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Enseignant</p>
                                </Link>
                            ) : (
                                <Link to="/AdminEnseignant" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-book-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Enseignant</p>
                                </Link>
                            )}


                            {AdminContact.test(pathname) || AdminContactReseauxSoci.test(pathname) || AdminContactSuggestion.test(pathname) ? (
                                <Link to="/AdminContact/Contact" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                                    <i class="bi bi-envelope-at-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Contact</p>
                                </Link>
                            ) : (
                                <Link to="/AdminContact/Contact" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all '>
                                    <i class="bi bi-envelope-at-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Contact</p>
                                </Link>
                            )}

                            {AdminFonction.test(pathname) ? (
                                <Link to="/AdminFonction" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                                    <i class="bi bi-funnel-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Fonction</p>
                                </Link>
                            ) : (
                                <Link to="/AdminFonction" className='flex  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all '>
                                    <i class="bi bi-funnel-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Fonction</p>
                                </Link>
                            )}

                        </div>
                    </div>
                </Sidenav>
            </div>
        </div>
    )
}

export default NavBars
