import React, { useState, useEffect } from 'react';
import NavNarsAdminFonction from './NavNarsAdminFonction';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

function AdminFonction() {
    const [fonctions, setFonctions] = useState([]);

    useEffect(() => {
        // Effectuer une requête GET pour récupérer les fonctions depuis l'API
        axios.get('https://irakoze.burundientempsreel.com/api/fonctions')
            .then(response => {
                setFonctions(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des fonctions :', error);
            });
    }, []);

    const handleDelete = async (id) => {
        // Afficher la boîte de dialogue de confirmation
        Swal.fire({
            title: 'Êtes-vous sûr de vouloir supprimer cette fonction ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // Effectuer la requête DELETE à l'API
                    await axios.delete(`https://irakoze.burundientempsreel.com/api/fonctions/${id}`);
                    // Mettre à jour le tableau fonctions en retirant l'élément supprimé
                    setFonctions(prevFonctions => prevFonctions.filter(fonction => fonction.id !== id));
                    // Afficher un message de succès avec toast
                    toast.success('La fonction a été supprimée avec succès.');
                } catch (error) {
                    console.error('Erreur lors de la suppression de la fonction :', error);
                    // Afficher un message d'erreur avec toast
                    toast.error('Une erreur est survenue lors de la suppression de la fonction.');
                }
            }
        });
    };

    return (
        <div>
            <div class="container min-h-[85vh] mx-auto p-4">
                <NavNarsAdminFonction />
                <h1 class="text-2xl font-bold mb-4">Liste des fonctions scolaires</h1>
                <div class="flex flex-wrap gap-4">
                    {fonctions.map(fonction => (
                        <div key={fonction.id} class="border rounded p-4">
                            <h2 class="text-lg font-bold mb-2">{fonction.nom}</h2>
                            <div class="flex justify-end">
                                <button
                                    class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mr-2"
                                    onClick={() => handleDelete(fonction.id)}
                                >
                                    Supprimer
                                </button>
                                <Link to={`/AdminFonction/modifier/${fonction.id}`} class="bg-blue-500 block hover:no-underline focus:no-underline hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">Modifier</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AdminFonction;
