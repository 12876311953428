import React, { useState, useEffect } from 'react';
import NavNarsAdminFonction from './NavNarsAdminFonction';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function ModifierAdminFonction() {
    const { id } = useParams();
    const [nouveauNom, setNouveauNom] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    useEffect(() => {
        const fetchFonction = async () => {
            try {
                const response = await axios.get(`https://irakoze.burundientempsreel.com/api/fonctions/${id}`);
                setNouveauNom(response.data.nom);
                setLoading(false);
            } catch (error) {
                setError(error.response.data.message);
                setLoading(false);
            }
        };

        fetchFonction();
    }, [id]);

    const handleInputChange = (e) => {
        setNouveauNom(e.target.value);
    };

    const handleSubmit = async () => {
        try {
            await axios.put(`https://irakoze.burundientempsreel.com/api/fonctions/${id}`, { nom: nouveauNom });
            toast.success('Fonction modifiée avec succès!');
            navigate("/AdminFonction")
        } catch (error) {
            console.error('Erreur lors de la modification de la fonction :', error);
            toast.error('Une erreur est survenue lors de la modification de la fonction.');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <div className="min-h-[85vh] mx-auto p-4">
                <NavNarsAdminFonction />
                <h1 className="text-2xl font-bold mb-4">Modifier la fonction scolaire</h1>
                <div className="mb-4">
                    <input
                        type="text"
                        value={nouveauNom}
                        onChange={handleInputChange}
                        className="py-3 w-full px-4 mr-2 border bg-transparent outline-none focus:border-fuchsia-800 rounded"
                    />
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="bg-green-500 mt-3 w-max hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Modifier
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ModifierAdminFonction;
