/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import { InView } from 'react-intersection-observer';
import { tableauObjets } from './DataUseed';

function EvenementComposeSousTitreDetail() {
    const [mobile, SetMobile] = useState(window.innerWidth < 600);
    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 600)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const [visibleItems, setVisibleItems] = useState([]);
    const [loadedItems, setLoadedItems] = useState([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1,
        };

        const handleIntersection = (position) => {
            setVisibleItems((prev) => [...prev, position]);
        };
        const intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const position = parseInt(entry.target.getAttribute('data-position'), 10);
                    handleIntersection(position);
                }
            });
        }, observerOptions);

        const elements = document.querySelectorAll('.blocks');

        elements.forEach((element) => {
            intersectionObserver.observe(element);
            return () => {
                intersectionObserver.unobserve(element);
            };
        });

        return () => {
            intersectionObserver.disconnect();
        };
    }, []);

    const handleImageLoad = (position) => {
        setLoadedItems((prev) => [...prev, position]);
    };



    return (
        <div className='  flex flex-col items-end'>
            <div className='w-full'>
                <div className='w-full px-5 rounded-b-lg flex items-center my-3 justify-between border-b border-gray-300 flex-row'>
                    <Link to='/AdminEvent/Evenement' className='bg-orange-500 mb-1  text-[17px] rounded h-max w-max px-2 py-1'>
                        Retour
                    </Link>
                    <Link to='/AdminEvent/AjoutEvenement' className='bg-orange-500 text-[17px] mb-1 rounded h-max w-max px-2 py-1'>
                        Ajouter un événement
                    </Link>
                </div>
                <div className='text-[25px] text-center'>Evenement compose</div>
                <div className="w-full h-max mb-5 flex-col items-center flex flex-wrap justify-center">

                    <div className="w-full h-max mb-5 p-1 flex flex-wrap justify-center">
                        <div className=' mx-2 my-4 w-[95%] border border-fuchsia-700 rounded-xl p-4'>
                            <Link to='/AdminEvent/EvenementComposeTitre/Detail' className='text-[30px]'>{tableauObjets[0].titre}</Link>
                            <div className='flex  w-full'>
                                <div className="w-full h-max mb-5 p-1 flex flex-wrap justify-center">
                                    <div className='w-[95%] m-2 border p-4 rounded-2xl border-orange-700'>
                                        <div className='w-full flex justify-start'>
                                            <Link to='/AdminEvent/EvenementComposeSousTitre/Detail' className='text-center first-letter:uppercase  font-serif p-1  text-[17px] mb-2'>
                                                {tableauObjets[0].sous[0].sousTitre}
                                            </Link>
                                        </div>
                                        <div className={`flex justify-center ${mobile ? 'h-max ' : 'h-[70vh]'} items-center   rounded-2xl  overflow-hidden m-2`}>
                                            <div className='relative h-full w-full'>
                                                <img src={tableauObjets[0].sous[0].photos} className="inline object-cover object-center w-full h-full" />
                                            </div>
                                        </div>
                                        <a>Le 06/03/2024</a>
                                        <div className="my-3 max-sm:text-[15px] text-[18px] overflow-hidden">
                                            {tableauObjets[0].sous[0].description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EvenementComposeSousTitreDetail
