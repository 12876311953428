/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Loading from '../../../Loading/Loading';
import PropTypes from 'prop-types'
import TeteAnn from './TeteAnn';
function AnnoncesInspectDeatilValid() {
    const { id } = useParams();
    const [annonce, setAnnonce] = useState({});
    const [photo, setPhoto] = useState([]);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        axios.get(`https://irakoze.burundientempsreel.com/api/getonanonce/${id}`).then((response) => {
            setAnnonce(response.data);
            setPhoto(response.data.annoncesfiles);
            setTimeout(() => {
                setPending(false)
            }, 100)
        });
    }, [id]);



    function handleDelete(id, position) {
        Swal.fire({
            title: 'Confirmation',
            text: 'Etes-vous sûr que vous voulez supprimer?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'SUPPRIMER',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'swal-confirm-button',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`https://irakoze.burundientempsreel.com/api/annocefile/ById/${id}`)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('le fichier a été supprimé avec succès');
                            const allPhotos = [...photo]
                            allPhotos.splice(position, 1)
                            setPhoto(allPhotos)
                            setTimeout(() => {
                                setPending(false);
                            }, 100);
                        } else {
                            toast.error("Une erreur s'est produite lors de la suppression du fichier");
                        }
                    })
                    .catch((error) => {
                        setTimeout(() => {
                            setPending(false);
                        }, 100);
                        console.error(error);
                        toast.error("Une erreur s'est produite lors de la suppression du fichier");
                    });
            }
        });
    }

    console.log('pending', pending)

    const [loadingImages, setLoadingImages] = useState([]);
    const [loadingImage, setLoadingImage] = useState([]);

    const handleImageLoad = (index) => {

        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            updatedLoadingImages[index] = true;
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 50000);
            return updatedLoadingImages;
        });

    };





    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full "
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };

    const [mobile, SetMobile] = useState(window.innerWidth < 600);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 600)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])
    return (
        <div className='w-full  min-h-[75vh]'>
            {pending && <Loading />}
            <TeteAnn />
            <div className="p-2 w-full   flex shadow1 flex-col">
                {annonce && (
                    <div className="p-1">
                        <div className="w-full h-full ">
                            <div className="h-max border rounded-xl border-orange-700 p-2  overflow-hidden m-2">
                                {annonce.file && (() => {
                                    const extension = annonce.file.split('.').pop();
                                    if (extension === 'pdf') {

                                        return (
                                            <div>PDF introuvable</div>
                                        );
                                    } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                                        return (

                                            <>
                                                <div key={annonce.id} className='relative border h-full w-full '>


                                                    <ReactPlayer
                                                        url={`https://irakoze.burundientempsreel.com/uploads/annonces/${annonce.file}`}
                                                        controls
                                                        className=" inline object-contain object-center w-full h-full"

                                                    />


                                                </div>
                                            </>


                                        );
                                    } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                                        return (
                                            <div className='relative h-full w-full'>


                                                <img
                                                    src={`https://irakoze.burundientempsreel.com/uploads/annonces/${annonce.file}`}
                                                    alt={`Image de ${annonce.titre}`}
                                                    className={`w-full h-full object-contain`} // Masquer l'image pendant le chargement

                                                />
                                            </div>
                                        );
                                    }
                                    return null;
                                })()}

                            </div>
                        </div>
                        <div className="w-full">
                            <h2 className="text-left  sm:text-[25px] my-2">{annonce.titre}</h2>
                            <p className=" sm:text-[19px]">{annonce.description}</p>
                        </div>
                    </div>
                )}
                <div className="flex flex-wrap">
                    {(photo.length > 0) ? (
                        <>
                            <div className={`w-full  ${mobile ? 'text-[15px]' : 'text-[22px]'} text-left  text-bold mt-10 `}>Les photos supplémentaire</div>
                            {
                                photo.map((photos, position) => {
                                    return (
                                        <div className="hoverHideeDelet rounded-xl relative w-[15em] h-[15em] border border-orange-600  overflow-hidden m-2">
                                            <button
                                                onClick={() => handleDelete(photos.id, position)}
                                                className={`shower absolute opacity-0 pointer-events-none z-50  bi bi-trash-fill right-0 bg-red-400 text-[20px] text-white hover:bg-red-600 transition-all w-[30px] h-[30px] flex justify-center items-center cursor-pointer`}
                                            >
                                            </button>
                                            {photos.file && (() => {
                                                const extension = photos.file.split('.').pop();
                                                if (extension === 'pdf') {

                                                    return (
                                                        <div>PDF introuvable</div>
                                                    );
                                                } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                                                    return (

                                                        <>
                                                            <div key={photos.id} className='relative border h-full w-full '>

                                                                <ReactPlayer
                                                                    url={`https://irakoze.burundientempsreel.com/uploads/annonces/${photos.file}`}
                                                                    controls
                                                                    className=" inline object-cover object-center w-full h-full"

                                                                />


                                                            </div>
                                                        </>


                                                    );
                                                } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                                                    return (
                                                        <div key={photos.id} className='relative  h-full w-full '>

                                                            <img
                                                                src={`https://irakoze.burundientempsreel.com/uploads/annonces/${photos.file}`}
                                                                className="w-full h-full border-none object-contain"
                                                                onLoad={() => handleImageLoad(position)}
                                                            />
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })()}
                                        </div>
                                    )
                                })
                            }
                        </>
                    ) : null}
                </div>
            </div>


        </div>
    )
}

export default AnnoncesInspectDeatilValid
