import React from 'react'
import DetailRessource from './DetailRessource'
function DetailRessourceComponent() {
    return (
        <DetailRessource />

    )
}

export default DetailRessourceComponent
