import React from 'react'
import TemoignajeGeraAdmin from './TemoignajeGeraAdmin'
function TemoignageAdminComponent() {
    return (
        <>
            <TemoignajeGeraAdmin />
        </>
    )
}

export default TemoignageAdminComponent
