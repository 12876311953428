import React, { useEffect } from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners'
import Loading from '../../../Loading/Loading';


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderBottom: '2px solid gray',
        borderRadius: '0px',
        backgroundColor: "transparent",
        top: '7px',
        with: '100%',
        boxShadow: state.isFocused ? '0 0 0 2px #ddd' : 'none',
        borderBottom: state.isFocused ? '2px solid blue' : '2px solid gray',
        '&:hover': {
            borderBottom: state.isFocused ? '2px solid blue' : '2px solid orange',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'orange' : 'transparent',
        color: state.isSelected ? '#333' : '#666',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
        position: 'static',
        zIndex: '10000',
    }),
};

function ModifierMembres() {


    const [fonctions, setFonctions] = useState([]);

    useEffect(() => {
        // Appel de l'API pour récupérer les noms de fonction
        axios.get('https://irakoze.burundientempsreel.com/api/fonctions')
            .then(response => {
                // Mappage des noms de fonction dans le bon format pour react-select
                const mappedFonctions = response.data.map(fonction => ({
                    value: fonction.nom,
                    label: fonction.nom
                }));
                setFonctions(mappedFonctions);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des fonctions :', error);
            });
    }, []);


    const [isPending, setPending] = useState(true)
    const [spinnerButton, SetSpinnerButton] = useState(false)
    const { id } = useParams();
    const history = useNavigate();
    const [fonction, setFonction] = useState('');
    const [nom_complet, Setnom_complet] = useState('')
    const [animationClassnom_complet, setanimationClassnom_complet] = useState('');

    const [tele, Settele] = useState('')
    const [animationClasstele, setAnimationClasstele] = useState('');


    const [isEmail, SetisEmail] = useState('')
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;

    const [isSelectValue, SetisSelectValue] = useState({ label: '', value: '' });
    const [animationClassSelecteValue, setanimationClassSelecteValue] = useState('');

    const [animationClassProfil, setanimationClassProfil] = useState('');
    const [file, setFile] = useState(null);
    const [photo, setPhoto] = useState(null);


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };


    useEffect(() => {
        axios.get(`https://irakoze.burundientempsreel.com/api/getonmember/${id}`)
            .then((response) => {
                Setnom_complet(response.data.nom_complet);
                SetisEmail(response.data.email);
                Settele(response.data.tel);
                setPhoto(response.data.file);
                SetisSelectValue({ label: `${response.data.fonction}`, value: `${response.data.fonction}` });
                setTimeout(() => {
                    setPending(false)
                }, 3000);
            });
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (nom_complet.trim() == '') {
            toast.warning("Le nom_complet du membre est obligatoire !!", {
                autoClose: 2000
            });
            setanimationClassnom_complet('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setanimationClassnom_complet(' ')
            }, 3000)
            return;
        }
        else if (tele.trim() == '') {
            toast.warning("Le téléphone du membre est obligatoire", {
                autoClose: 2000
            });
            setAnimationClasstele('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClasstele(' ')
            }, 3000)
            return;
        }
        else if (isEmail.trim() == '') {
            toast.warning("L'address email du membre est obligatoire", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            return;
        }
        else if (!isEmail.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX  border-red-600 text-red-600')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            return;
        }
        SetSpinnerButton(true)
        const formData = new FormData();
        formData.append('nom_complet', nom_complet);
        formData.append('tel', tele);
        formData.append('email', isEmail);
        (isSelectValue) ? (formData.append('fonction', isSelectValue.value)) : (formData.append('fonction', fonction));
        if (file !== null) {

            formData.append('file', file);
            axios.put(`https://irakoze.burundientempsreel.com/api/member/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((Response) => {
                // Handle success response here
                toast.success('Modifications faite avec succes')
                history('/AdminMembers/Members');
            }).catch((error) => {
                if (error.response) {
                    toast.warning(error.response.data.error, {
                        autoClose: 2000
                    });
                }
            }).finally(() => {
                SetSpinnerButton(false)
            });
        } else {
            axios.put(`https://irakoze.burundientempsreel.com/api/member/${id}`, formData).then((Response) => {
                // Handle success response here
                history('/AdminMembers/Members');
            }).catch((error) => {
                if (error.response) {
                    toast.warning(error.response.data.error, {
                        autoClose: 2000
                    });
                }
            });

        }
    }

    const [loading, SetLoading] = useState(true)


    return (
        <div className='flex-col min-h-[75vh] flex  items-center'>
            <div className='w-full px-5 rounded-b-lg flex items-center justify-between border-b border-gray-300 flex-row'>
                <Link to='/AdminMembers/Members' className='bg-orange-500 my-2 hover:text-white hover:no-underline text-white focus:text-white text-[12px] focus:no-underline sm:text-[17px] rounded h-max w-max px-2 py-1'>
                    Retour
                </Link>
                <Link to='/AdminMembers/AjouterMembers' className='bg-orange-500 my-2 hover:text-white hover:no-underline text-white focus:text-white text-[12px] focus:no-underline sm:text-[17px] rounded h-max w-max px-2 py-1'>
                    Ajouter un membre
                </Link>

            </div>
            {isPending && <Loading />}
            <div className=" border border-orange-600 rounded-2xl m-3 p-4  w-[30em] h-max wimax ">
                <h2 className=' p-2 text-center text-[13px] sm:text-[30px] text-orange-500 animeBorder'>Modifier un  membre</h2>
                <form onSubmit={handleSubmit}>
                    <div className=" transition-all flex flex-col   p-1">


                        <div className="flex w-full justify-between  my-10">
                            <div class="relative h-11 w-full  mr-2 ">
                                <label class="text-[18px]">
                                    Nom_complet
                                </label>
                                <input value={nom_complet} onChange={(e) => Setnom_complet(e.target.value)} className={`border p-3 mt-2 font-normal  outline-none focus:border w-full focus:border-orange-600 bg-transparent rounded-lg ${animationClassnom_complet}`} placeholder="nom_complet" />
                            </div>
                        </div>


                        <div className="flex w-full justify-between  mt-3">
                            <div class="relative h-11 w-full  mr-2 ">
                                <label class="text-[18px]">
                                    Téléphone
                                </label>
                                <input value={tele} onChange={(e) => Settele(e.target.value)} className={`border p-3 mt-2 font-normal  outline-none focus:border w-full focus:border-orange-600 bg-transparent rounded-lg ${animationClasstele}`} placeholder="Prenom_complet" />
                            </div>
                        </div>
                        <div className="flex w-full justify-between  mt-14">
                            <div class="relative h-11 w-full  mr-2 ">
                                <label class="text-[18px]">
                                    Email
                                </label>
                                <input value={isEmail} onChange={(e) => SetisEmail(e.target.value)} className={`border p-3 mt-2 font-normal  outline-none focus:border w-full focus:border-orange-600 bg-transparent rounded-lg ${animationClassEmail}`} placeholder="Prenom_complet" />
                            </div>
                        </div>


                        <div className="flex w-full justify-between  mt-14">
                            <div class="relative h-11 w-full  mr-2 ">
                                <label class="text-[18px]">
                                    <p>Fonction: <span>{(isSelectValue) ? isSelectValue.value : fonction}</span></p>
                                </label>
                                <div className={`flex flex-col h-11 w-full relative border-b-2 responsive31_temoignSelect ${animationClassSelecteValue}`}>
                                    <Select
                                        onChange={SetisSelectValue}
                                        defaultValue={isSelectValue.value}
                                        options={fonctions}
                                        styles={customStyles}>
                                    </Select>

                                </div>
                            </div>
                        </div>

                        <div class="relative h-11 w-full transition    mt-14 mr-2 ">
                            <div className="inline-flex items-center mr-4 text-[18px] text-gray-500">Profil:</div>
                            <label htmlFor="profils" className={` transition-all p-1 bg-orange-400 hover:bg-orange-600 py-3 rounded cursor-pointer text-white ${animationClassProfil}`}>Selection le profile</label>
                        </div>

                        <div className="relative mt-6">
                            <input type="file" id='profils' name='file' hidden accept="image/*" onChange={handleFileChange} />
                            <div className={`animeBorder relative p-1 transition-all   top-4  rounded border-4 border-dotted border-orange-500 overflow-hidden w-full wima ${(file == null && photo == null) ? 'h-[0px] opacity-0 ' : 'h-[300px] opacity-100'}`}>


                                {file ? (
                                    <>
                                        {loading && <div className="absolute w-full h-full  inset-0"><img alt="" src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                        }
                                        <img onLoad={() => {
                                            setTimeout(() => {
                                                SetLoading(false)
                                            }, 1000)
                                        }} src={URL.createObjectURL(file)} alt="" className=' w-full h-full rounded object-contain' />

                                    </>) : (photo && (
                                        <>
                                            {loading && <div className="absolute w-full h-full  inset-0"><img alt="" src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                            }
                                            <img onLoad={() => {
                                                setTimeout(() => {
                                                    SetLoading(false)
                                                }, 1000)
                                            }} src={`https://irakoze.burundientempsreel.com/uploads/membres/${photo}`} alt="" className=' w-full h-full rounded object-contain' />

                                        </>
                                    ))}


                            </div>
                        </div>
                    </div>







                    {spinnerButton ? (
                        <>
                            <div className="flex justify-end items-center mt-10 relative pointer-events-none opacity-80">
                                <div className='absolute bg-transparent  pt-3  w-full h-full flex justify-center items-center z-50'>
                                    <FadeLoader
                                        color="rgb(255, 255, 255)"
                                        height={10}
                                        margin={-9}
                                        radius={100}
                                        speedMultiplier={1}
                                        width={1}
                                    /></div>
                                <input type="submit" id="send" value="Modifier" class="transition-all bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600"></input>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex justify-end items-center mt-10">
                                <label htmlFor='sende' className="w-max cursor-pointer  flex justify-end p-1 bg-orange-600 rounded  px-2 py-1 text-white">
                                    <input type="submit" className='pointer-events-none' id='sende' value="Modifier" />
                                    <i title="Modifier" class="bi bi-send ml-2 "></i>
                                </label>
                            </div>
                        </>
                    )}

                </form>
            </div>
        </div >
    )
}

export default ModifierMembres

