import React from 'react'
import AjouterMembres from './AjouterMembres'
function AjouterMembreComponent() {
  return (
    <>
      <AjouterMembres />
    </>
  )
}

export default AjouterMembreComponent
