import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FadeLoader } from 'react-spinners'

function NavBarsSlideInsp({ getThemes }) {
    const { pathname } = useLocation()
    let InspectEventAceuil = /^\/InspectAccueil.*/
    let InspectEvent = /^\/InspectEvent\/Evenement.*/
    let InspectTemoig = /^\/InspectTemoig\/Temoignage.*/


    let InspectEventAnnonces = /^\/InspectAnnonce\/Annonce.*/
    let InspectEventAnnoncesAjouter = /^\/InspectEventAnnonce\/AjouterAnnonce.*/



    let InspectEventRessources = /^\/InspectRessources\/Ressources.*/
    let InspectEventRessourcesAjouter = /^\/AdmiRessources\/AjouteRessources.*/


    const [mobile, SetMobile] = useState(window.innerWidth < 776);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 776)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const [isLoading, GetisLoading] = useState(true)
    const hundleLoading = () => {
        GetisLoading(false)
    }


    return (
        <div className={`w-[14em] h-full  border-r-2 border-orange-300  flex-col items-center ${mobile ? 'hidden' : 'flex'} `}>

            <div className="w-full h-[9em] flex justify-center items-center   relative  rounded overflow-hidden ">
                {isLoading && <div className={`absolute  w-full z-[60]  h-full ${getThemes == 'light' ? ' bg-[#f0eded]' : 'bg-[#242323]'}  top-0 left-0   flex items-center justify-center`}>
                    <FadeLoader
                        color="#36d7b7"
                        height={20}
                        width={5}
                    />
                </div>
                }
                <img onLoad={hundleLoading} src="https://irakoze.burundientempsreel.com/uploads/Photo/LENGA2.png" alt="" className='block w-[14em] h-[14em]   object-contain object-center' />
            </div>

            <div className="w-full h-[75%] overflow-x-hidden overflow-y-auto border-t-2 border-orange-500">
                <div className="w-full h-100% font-serif">
                    {InspectEventAceuil.test(pathname) || pathname == '/' ? (
                        <Link to='/InspectAccueil' className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Acceuil</p>
                        </Link>
                    ) : (
                        <Link to='/InspectAccueil' className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Acceuil</p>
                        </Link>
                    )}

                    {InspectEvent.test(pathname) ? (
                        <Link to='/InspectEvent/Evenement' className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2  bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-calendar4-event text-[20px] mr-4"></i>
                            <p className='sizeText'>Evénement</p>
                        </Link>

                    ) : (
                        <Link to='/InspectEvent/Evenement' className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-calendar4-event text-[20px] mr-4"></i>
                            <p className='sizeText'>Evénement</p>
                        </Link>
                    )}

                    {InspectTemoig.test(pathname) ? (
                        <Link to="/InspectTemoig/Temoignage" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600  transition-all text-fuchsia-50  hover:text-fuchsia-50 '>
                            <i class="bi bi bi-badge-tm  text-[20px] mr-4"></i>
                            <p className='sizeText'>Témoignage</p>
                        </Link>
                    ) : (
                        <Link to="/InspectTemoig/Temoignage" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi bi-badge-tm  text-[20px] mr-4"></i>
                            <p className='sizeText'>Témoignage</p>
                        </Link>
                    )}


                    {InspectEventAnnonces.test(pathname) || InspectEventAnnoncesAjouter.test(pathname) ? (
                        <Link to="/InspectAnnonce/Annonce" className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                            <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Annonce</p>
                        </Link>
                    ) : (
                        <Link to="/InspectAnnonce/Annonce" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-megaphone-fill text-[20px] mr-4"></i>
                            <p className='sizeText'>Annonce</p>
                        </Link>
                    )}


                    {InspectEventRessources.test(pathname) || InspectEventRessourcesAjouter.test(pathname) ? (
                        <Link to="/InspectRessources/Ressources" className='flex  justify-start items-center separeIcons p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50'>
                            <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                            <p className='sizeText'>Ressource</p>
                        </Link>
                    ) : (
                        <Link to="/InspectRessources/Ressources" className='flex   justify-start items-center separeIcons p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                            <i class="bi bi-currency-dollar text-[20px] mr-4"></i>
                            <p className='sizeText'>Ressource</p>
                        </Link>
                    )}

                </div>
            </div>

        </div>
    )
}

export default NavBarsSlideInsp
