/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { FadeLoader } from 'react-spinners'
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function AjouterAnnoncer({ getSizeMobille }) {
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const History = useNavigate()

    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    const [eledemmade, GeteleDemmande] = useState([])

    const [boutLoading, setboutLoading] = useState(false)
    const [radioValue, setRadioValue] = useState('');

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])






    const [blocs, setBlocs] = useState([
        { titre: '', date: '', photos: '', descript: '' }
    ]);

    const addBloc = () => {
        setBlocs([...blocs, { titre: '', date: '', photos: '', descript: '' }]);
    };
    const removeBloc = (index) => {
        const newBlocs = [...blocs];
        newBlocs.splice(index, 1);
        setBlocs(newBlocs);
    };
    const inputRefs = useRef([]);
    if (inputRefs.current.length !== blocs.length) {

        inputRefs.current = Array(blocs.length).fill({}).map((_, index) => ({
            titre: createRef(null),
            date: createRef(null),
            photos: createRef(null),
            descript: createRef(null),
        }));

    }



    const handleInputChange = (index, field, value, isFile = false) => {
        const newBlocs = [...blocs];

        newBlocs[index][field] = value;

        if (isFile) {
            if (value && value.length > 0) {
                const file = value[0];
                newBlocs[index] = { ...newBlocs[index], [field]: file };
            } else {
                delete newBlocs[index][field];
            }
        } else {
            newBlocs[index] = { ...newBlocs[index], [field]: value };
        }

        setBlocs(newBlocs);
        if (value && inputRefs.current[index][field].current) {
            inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
        }
    };


    const animateAndScrollToRef = (ref) => {
        if (ref && ref.current) {
            ref.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            setTimeout(() => {
                ref.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            }, 5000);
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            ref.current.focus();
        }
    };


    const validateBloc = (bloc, index) => {
        const requiredFields = ['titre', 'date', 'photos', 'descript'];

        //Validation pour les champs vide
        for (const field of requiredFields) {
            if (!bloc[field]) {
                toast.warning(
                    <div>Le <strong>
                        {field == 'titre' ? 'titre d\'annonce' :
                            field == 'date' ? 'date d\'annonce' :
                                field == 'photos' ? 'photos d\'annonce' :
                                    field == 'descript' ? 'description  d\'annonce' :
                                        null}</strong> est obligatoire d'annonc numero {index + 1}.</div>);
                if (inputRefs.current[index][field].current) {
                    inputRefs.current[index][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    setTimeout(() => {
                        inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    }, 3000);
                    inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    inputRefs.current[index][field].current.focus();
                    return false;
                }
            }
        }


        //Validation pour eviter de sousmettre deux block de meme tritre
        const isdepluqueNom = blocs.some((b, i) => i !== index && b.titre === bloc.titre);
        if (isdepluqueNom) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.titre === bloc.titre);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div>
                    Vous avez saisie le même nom de  <b className="font-bold">{bloc.titre}</b> à la block de <b className="font-bold"> {duplicateBlockNumber}</b> et  <b>{toRoman(index + 1)}</b>.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].titre);
            return false;
        }

        //Validation pour eviter de sousmettre deux block de meme  date
        const isdepluqueDate = blocs.some((b, i) => i !== index && b.date === bloc.date);
        if (isdepluqueDate) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.date === bloc.date);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div>
                    Vous avez saisie le même date de  <b className="font-bold">{bloc.date}</b> à la block de <b className="font-bold"> {duplicateBlockNumber}</b> et  <b>{toRoman(index + 1)}</b>.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].date);
            return false;
        }

        //Validation pour eviter de sousmettre deux block de meme  nom du photos
        const isdepluquePhotos = blocs.some((b, i) => i !== index && b.photos.name === bloc.photos.name);
        if (isdepluquePhotos) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.photos.name === bloc.photos.name);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div>
                    Vous avez saisie le même date de  <b className="font-bold">{bloc.photos.name}</b> à la block de <b className="font-bold"> {duplicateBlockNumber}</b> et  <b>{toRoman(index + 1)}</b>.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].photos);
            return false;
        }


        //Validation pour eviter de sousmettre deux block de meme  nom du photos
        const isdepluqueDescript = blocs.some((b, i) => i !== index && b.descript === bloc.descript);
        if (isdepluqueDescript) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.descript === bloc.descript);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div>
                    Vous avez saisie le même descriptin  à la block de <b className="font-bold"> {duplicateBlockNumber}</b> et  <b>{toRoman(index + 1)}</b>.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].descript);
            return false;
        }
        return true;
    };

    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }




    const formData = new FormData();
    blocs.forEach((bloc, index) => {
        formData.append(`[${index}]titre`, bloc.titre);
        formData.append(`[${index}]description`, bloc.descript);
        formData.append(`[${index}]date`, bloc.date);
        formData.append(`[${index}]file`, bloc.photos);

    });
    const handleSubmit = (e) => {
        e.preventDefault();
        for (let index = 0; index < blocs.length; index++) {
            if (!validateBloc(blocs[index], index)) {
                return false;
            }
        }

        axios.post(`https://irakoze.burundientempsreel.com/api/addannoce`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                toast.success('Enregistrement effectué avec succès');
                History('/AdminAnnonce/Annonce');
            })
            .catch((error) => {
                toast.error('Erreur lors de la configuration de la requête');

            });
    };

    const [mobiles, SetMbiles] = useState(window.innerWidth < 400)

    useEffect(() => {
        const HundleSize = () => {
            SetMbiles(window.innerWidth < 400)
        }

        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])



    return (
        <div className={`overflow-hidden `}>
            <div className="sm:p-4 p-2 min-h-screen">
                <div className='w-full sm:px-5 rounded-b-lg flex items-center mb-3 justify-between border-b border-gray-300 flex-row'>
                    <div className={`mb-1  ${mobiles ? 'text-[13px]' : 'text-[17px] '} rounded h-max w-max px-2 py-1`}>
                        Ajoute l'annoncement
                    </div>
                    <Link to='/AdminAnnonce/Annonce' className={`bg-orange-500 ${mobiles ? 'text-[13px]' : 'text-[17px]'}hover:text-white hover:no-underline text-white focus:text-white text-[12px] focus:no-underline sm:text-[17px]   my-1 rounded h-max w-max px-2 py-1`}>
                        Gerer l'annonce
                    </Link>
                </div>
                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500  w-[95%] sm:p-4">
                                {blocs.map((bloc, index) => (
                                    <div key={index} className="justify-center overflow-hidden rounded-xl w-full h-max p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                        <div className="w-full">
                                            <div className="h-max w-full">
                                                <div className="px-3  w-full">
                                                    <div className="w-full flex items_center justify-between">
                                                        <label className="block  mt-5 font-serif text-gray-500 first_letter:uppercase tracking_wide  text-lg  mb-1">
                                                            {toRoman(index + 1)}.Annonce
                                                        </label>
                                                        {index > 0 && (
                                                            <button
                                                                montant="button"
                                                                className="text-red-500 px-1 max-sm:text-xs py-2"
                                                                onClick={() => removeBloc(index)}
                                                            >
                                                                Supprimer
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2 h-max">
                                            <div className="rounded-xl p-2 w-full">
                                                <div className="flex flex-col">
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label
                                                                class="block  tracking-wide  text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Titre d'annonce
                                                            </label>
                                                            <input
                                                                ref={inputRefs.current[index].titre}
                                                                class="block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.titre}
                                                                placeholder=" Titre d'annonce"
                                                                onChange={(e) => handleInputChange(index, "titre", e.target.value)}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="relative h-full w-full">
                                                        <div className='flex items-center'>
                                                            <p className='text-red-500 text-[30px]'>⋆</p>
                                                            <div>Date d'annonce</div>
                                                        </div>
                                                        <input type="date" ref={inputRefs.current[index].date} className="border focus:border-orange-600 outline-none py-3 px-4 mb-3 w-full bg-transparent rounded-lg"
                                                            value={bloc.date}
                                                            onChange={(e) => handleInputChange(index, "date", e.target.value)} />
                                                    </div>
                                                    <div className="relative">
                                                        <div className="inline-flex items-center mr-4 text-[18px] ">Photos d'annonce:</div>
                                                        <label ref={inputRefs.current[index].photos} htmlFor={`photoss-${index}`} className='transition-all p-1 bg-orange-400 hover:bg-orange-600 rounded cursor-pointer text-white'>Selection le photose</label>
                                                        <input type="file" id={`photoss-${index}`} name='photo' hidden accept='image/*'
                                                            onChange={(e) => handleInputChange(index, 'photos', e.target.files, true)}
                                                        />
                                                        <div className={`animeBorder p-1 transition-all relative  my-4  rounded border-4 border-dotted border-orange-500  ${bloc.photos == '' ? 'h-[0px] opacity-0 pointer-events-none' : 'h-[12em] w-[12em] opacity-100 pointer-events-none'}`}>
                                                            {bloc.photos && (<img src={URL.createObjectURL(bloc.photos)} alt="" className='w-full h-full rounded object-cover' />)}
                                                        </div>
                                                    </div>

                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label
                                                                class="block  tracking-wide  text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Description d'annonce
                                                            </label>
                                                            <textarea
                                                                placeholder=" Description d'annonce"
                                                                ref={inputRefs.current[index].descript}
                                                                class="block w-full bg-transparent min-h-[15em] resize-y  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.descript}
                                                                onChange={(e) => handleInputChange(index, "descript", e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="w-full flex justify-end">
                                    <button montant="button" className="bg-blue-500 hover-bg-blue-700 text-white py-1 px-2  max-sm:text-xs sm:py-2 sm:px-4 rounded" onClick={addBloc}>
                                        Ajouter ressource
                                    </button>
                                </div>
                            </div>
                            <div className=" flex justify-end   w-[95%]">
                                {boutLoading ? (
                                    <>
                                        <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Soumettre' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (<>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Soumettre' className='cursor-pointer'></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </>)}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default AjouterAnnoncer;







