import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function ClassesPage() {
    const [classes, setClasses] = useState([]);
    const [selectedClasse, setSelectedClasse] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({ nom: '', anneescolaireId: '' });
    const [anneesScolaires, setAnneesScolaires] = useState([]);

    useEffect(() => {
        // Charger les classes et les années scolaires depuis l'API
        Promise.all([
            axios.get('https://irakoze.burundientempsreel.com/api/classes'),
            axios.get('https://irakoze.burundientempsreel.com/api/anneescolaires')
        ])
            .then(([classesResponse, anneesScolairesResponse]) => {
                setClasses(classesResponse.data);
                setAnneesScolaires(anneesScolairesResponse.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données :', error);
            });
    }, []);

    function handleModalOpen(classe) {
        setSelectedClasse(classe);
        setFormData({ nom: classe.nom, anneescolaireId: classe.anneescolaireId });
        setModalOpen(true);
    }

    function handleModalClose() {
        setSelectedClasse(null);
        setFormData({ nom: '', anneescolaireId: '' });
        setModalOpen(false);
    }

    function handleFormChange(event) {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    function handleSubmit(event) {
        event.preventDefault();

        axios.put(`https://irakoze.burundientempsreel.com/api/classes/${selectedClasse.id}`, formData)
            .then(response => {
                toast.success('Classe mise à jour avec succès');
                setClasses(prevState => prevState.map(item => (item.id === response.data.id ? response.data : item)));
                handleModalClose();
            })
            .catch(error => {
                toast.error('Erreur lors de la mise à jour de la classe');
            });
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <Link to="/AdminEnseignant" className="text-blue-500 block mb-4">Retour</Link>

            <h1 className="text-3xl font-bold mb-8">Liste des classes</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {classes.map(classe => (
                    <div key={classe.id} className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-xl font-bold mb-2">{classe.nom}</h2>
                        <p><strong>Année scolaire:</strong> {classe.anneescolaire && classe.anneescolaire.nom}</p>
                        <p className="text-sm text-gray-500">Créé le: {new Date(classe.createdAt).toLocaleDateString()}</p>
                        <p className="text-sm text-gray-500">Modifié le: {new Date(classe.updatedAt).toLocaleDateString()}</p>
                        <Link to="#" onClick={() => handleModalOpen(classe)} className="text-blue-500 mt-4 inline-block">Modifier</Link>
                    </div>
                ))}
            </div>

            {modalOpen && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-xl font-bold mb-4">Modifier la classe</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block mb-2">Nom:</label>
                                <input type="text" name="nom" value={formData.nom} onChange={handleFormChange} className="border border-gray-300 rounded-md py-2 px-4 w-full" />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2">Année scolaire:</label>
                                <select name="anneescolaireId" value={formData.anneescolaireId} onChange={handleFormChange} className="border border-gray-300 rounded-md py-2 px-4 w-full">
                                    <option value="">Sélectionnez une année scolaire</option>
                                    {anneesScolaires.map(annee => (
                                        <option key={annee.id} value={annee.id}>{annee.nom}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex justify-end">
                                <button type="button" onClick={handleModalClose} className="mr-2 bg-gray-300 hover:bg-gray-400 py-2 px-4 rounded-md">Annuler</button>
                                <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md">Enregistrer</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ClassesPage;
