/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function NavNarsAdminFonction() {
    const url = window.location.pathname;

    const [mobiles, SetMbiles] = useState(window.innerWidth < 400)

    useEffect(() => {
        const HundleSize = () => {
            SetMbiles(window.innerWidth < 400)
        }

        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])

    return (
        <div className='text-[20px]    flex justify-between items-end'>
            <Link className={`w-full ${mobiles ? 'text-[13px]' : ''} border-b-2 pl-2 ${(url == "/AdminFonction" || url == "/AdminFonction/modifier") && "border-fuchsia-600 hover:text-fuchsia-600 focus:text-fuchsia-600 text-fuchsia-600"}`} to='/AdminFonction'>Fonctions </Link>
            <Link className={`w-full ${mobiles ? 'text-[13px]' : ''} border-b-2 pl-2 ${(url == "/AdminFonction/ajoute") && "border-fuchsia-600 hover:text-fuchsia-600 focus:text-fuchsia-600 text-fuchsia-600"}`} to='/AdminFonction/ajoute'>Ajouter fonction</Link>
        </div>
    )
}

export default NavNarsAdminFonction