import React from 'react'

import DetaillRessourceAdmin from './DetaillRessourceAdmin'
function DetaillRessourceAdminComponent() {
  return (
    <>

      <DetaillRessourceAdmin />
    </>
  )
}

export default DetaillRessourceAdminComponent
