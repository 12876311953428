/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import { formatDate, chiffrement } from '../../../module';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types'
import EmpyServices from '../EmpyServeices/EmpyServeices';

function Annonce() {
    const [lastannonce, setLastannonce] = useState([]);
    const [isPending, setPending] = useState(true);

    useEffect(() => {
        axios
            .get('https://irakoze.burundientempsreel.com/api/getlastannonce')
            .then((response) => {
                setLastannonce(response.data);
                setTimeout(() => {
                    setPending(false);
                }, 100);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);


    const [mobile1, setMobille1] = useState(window.innerWidth < 1178);
    const [mobile2, setMobille2] = useState(window.innerWidth < 627);
    const [mobile3, setMobille3] = useState(window.innerWidth < 443);
    const [mobile4, setMobille4] = useState(window.innerWidth < 504);
    useEffect(() => {
        const hundleSize = () => {
            setMobille1(window.innerWidth < 1178)
            setMobille2(window.innerWidth < 627)
            setMobille3(window.innerWidth < 443)
            setMobille4(window.innerWidth < 504)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    })


    const [loadingImages, setLoadingImages] = useState([]);

    const handleImageLoad = (index) => {

        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            updatedLoadingImages[index] = true;
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 50000);
            return updatedLoadingImages;
        });

    };





    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };


    return (
        <>
            <div className="  w-[97%]  bg-gray-50 rounded-lg  flex flex-col mt-[20px]  justify-center items-center ">
                {isPending && <Loading />}
                <h2 className='text-[35px] font-serif text-gray-700 px-4 p-1 my-2 rounded'>Annonce</h2>
                <div className='h-[1px] mb-1  bg-gray-300 w-full'></div>
                <div className={`flex flex-wrap  justify-center  gap-2 p-4`}>


                    {lastannonce.length > 0 ? (
                        lastannonce.map((anno, index) => {
                            return (
                                <div className={`   bg-[#e4e4e4] border overflow-hidden ${mobile4 ? 'flex-auto' : ''} ${mobile2 ? 'w-[16em]' : 'w-[20em]'}  rounded-2xl bg-white     p-1`}>
                                    <div className='w-full flex justify-center '>
                                        <div className={`m-[2px] bg-white overflow-hidden ${mobile4 ? 'w-full min-h-[15em]' : ''} ${mobile2 ? 'h-[13em] w-[13em]' : mobile1 ? 'h-[18em] w-[18em]' : 'h-[18em] w-[18em]'}  relative rounded overflow-hidden `}>
                                            {anno.file && (() => {
                                                const extension = anno.file.split('.').pop();
                                                if (extension === 'pdf') {
                                                    return (
                                                        <div>PDF introuvable</div>
                                                    );
                                                } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                                                    return (

                                                        <>
                                                            <div key={anno.id} className='relative border h-full w-full '>

                                                                <ReactPlayer
                                                                    url={`https://irakoze.burundientempsreel.com/uploads/annonces/${anno.file}`}
                                                                    controls
                                                                    className=" inline object-cover object-center w-full h-full"

                                                                />


                                                            </div>
                                                        </>


                                                    );
                                                } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                                                    return (
                                                        <div key={anno.id} className='relative  h-full w-full '>
                                                            {loadingImages[index] ? (
                                                                <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                            ) :
                                                                (
                                                                    <img
                                                                        src={`https://irakoze.burundientempsreel.com/uploads/annonces/${anno.file}`}
                                                                        className="w-full h-full border-none object-cover"

                                                                    />
                                                                )}

                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })()}
                                        </div>
                                    </div>
                                    <div className="w-full mt-1 flex items-center flex-col p-3">
                                        <div className='font-semibold  w-full text-[20px] text-center'>{anno.titre}</div>

                                        <span className='font-sans text-[13px] w-full text-orange-700'>Le {formatDate(anno.createdAt)}</span>

                                        <div className={`min-h-[5em] w-full`}>
                                            <div className='w-full  text-left'>
                                                {anno.description.length > 100
                                                    ? anno.description.slice(0, 100) + '...'
                                                    :
                                                    anno.description
                                                }
                                            </div>

                                        </div>
                                        <div className={` ${mobile3 ? 'w-[80%] text-[14px]' : ' w-full text-[16px]'} h-max   flex justify-start`}>
                                            <Link to={`/Visit/Annonce/Detail/${chiffrement(((anno.id).toString()))}`} className=' transition-all  p-2  mt-2 rounded'>En savoir plus</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <div className='flex  flex-col items-center w-[50em] border border-orange-600 p-3 rounded-lg bg-slate-100 h-[50vh] widthsssssssss'>
                            <EmpyServices items="d'annonces" />
                            <div className='sm:text-[18px] font-sans text-gray-700'>
                                Les annonces dans les écoles secondaires sont un moyen essentiel de diffuser des informations importantes et actualisées aux élèves. Elles permettent de tenir les élèves informés des événements à venir, des dates importantes, des modifications d'emploi du temps, des nouvelles règles et politiques, et bien plus encore.
                                <br /><br />
                                Ces annonces jouent un rôle crucial dans la création d'un environnement scolaire organisé et bien géré. Elles aident à coordonner les activités, à minimiser les retards et à assurer une communication fluide entre les élèves, les enseignants et l'administration.

                            </div>
                        </div>
                    )}
                </div>
                <Link to='/Visit/Annonce' className={`bg-orange-400 ${mobile3 ? 'w-[80%] text-[15px]' : 'w-[20em] text-[20px]'}  mb-4  py-2  text-center rounded-3xl cursor-pointer text-white  hover:border-orange-600 hover:bg-white transition-all hover:text-gray-700 border border-white ${lastannonce.length <= 0 ? 'pointer-events-none opacity-60' : 'pointer-events-auto opacity-100'}`}>Plus de'annonces</Link>            </div>
        </>
    );
}

export default Annonce;
