import React from 'react'
import StandByDetail from './StandByDetail'
function StandBuyDetailComponent() {
    return (
        <>

            <StandByDetail />
        </>
    )
}

export default StandBuyDetailComponent
