import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function AnneesScolairesPage() {
    const [anneesScolaires, setAnneesScolaires] = useState([]);
    const [selectedAnnee, setSelectedAnnee] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({ nom: '', dateDebut: '', dateFin: '', slogan: '' });

    useEffect(() => {
        // Charger les années scolaires depuis l'API
        axios.get('https://irakoze.burundientempsreel.com/api/anneescolaires')
            .then(response => {
                setAnneesScolaires(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des années scolaires :', error);
            });
    }, []);

    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' });
    }

    function handleModalOpen(annee) {
        setSelectedAnnee(annee);
        // Formater les dates pour les afficher dans le formulaire
        const formattedDateDebut = annee.dateDebut ? new Date(annee.dateDebut).toISOString().split('T')[0] : '';
        const formattedDateFin = annee.dateFin ? new Date(annee.dateFin).toISOString().split('T')[0] : '';
        setFormData({ nom: annee.nom, dateDebut: formattedDateDebut, dateFin: formattedDateFin, slogan: annee.slogan });
        setModalOpen(true);
    }

    function handleModalClose() {
        setSelectedAnnee(null);
        setFormData({ nom: '', dateDebut: '', dateFin: '', slogan: '' });
        setModalOpen(false);
    }

    function handleFormChange(event) {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    function handleSubmit(event) {
        event.preventDefault();
        // Envoyer les données modifiées au backend
        axios.put(`https://irakoze.burundientempsreel.com/api/anneescolaires/${selectedAnnee.id}`, formData)
            .then(response => {
                toast.success('Année scolaire mise à jour avec succès');
                // Mettre à jour la liste des années scolaires après la modification
                setAnneesScolaires(prevState => prevState.map(item => (item.id === selectedAnnee.id ? response.data : item)));
                handleModalClose();
            })
            .catch(error => {
                toast.warn('Erreur lors de la mise à jour de l\'année scolaire');
            });
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <Link to="/AdminEnseignant" className="text-blue-500 block mb-4">Retour</Link>

            <h1 className="text-3xl font-bold mb-8">Liste des années scolaires</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {anneesScolaires.map(annee => (
                    <div key={annee.id} className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-xl font-bold mb-2">{annee.nom}</h2>
                        <p><strong>Date de début:</strong> {formatDate(annee.dateDebut)}</p>
                        <p><strong>Date de fin:</strong> {formatDate(annee.dateFin)}</p>
                        <p><strong>Slogan:</strong> {annee.slogan}</p>
                        <button onClick={() => handleModalOpen(annee)} className="text-blue-500 mt-4 inline-block">Modifier</button>
                    </div>
                ))}
            </div>

            {/* Modal de modification */}
            {modalOpen && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-xl font-bold mb-4">Modifier l'année scolaire</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block mb-2">Nom:</label>
                                <input type="text" name="nom" value={formData.nom} onChange={handleFormChange} className="border border-gray-300 rounded-md py-2 px-4 w-full" />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2">Date de début:</label>
                                <input type="date" name="dateDebut" value={formData.dateDebut} onChange={handleFormChange} className="border border-gray-300 rounded-md py-2 px-4 w-full" />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2">Date de fin:</label>
                                <input type="date" name="dateFin" value={formData.dateFin} onChange={handleFormChange} className="border border-gray-300 rounded-md py-2 px-4 w-full" />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2">Slogan:</label>
                                <textarea name="slogan" value={formData.slogan} onChange={handleFormChange} className="border border-gray-300 rounded-md py-2 px-4 w-full"></textarea>
                            </div>
                            <div className="flex justify-end">
                                <button type="button" onClick={handleModalClose} className="mr-2 bg-gray-300 hover:bg-gray-400 py-2 px-4 rounded-md">Annuler</button>
                                <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md">Enregistrer</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AnneesScolairesPage;
