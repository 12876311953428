/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
function AcceuiInspect() {
    useEffect(() => {
        const timeout = setTimeout(() => {
            console.log('La page a été actualisée après une seconde');
        }, 1000);

        return () => clearTimeout(timeout); // Annuler le timeout si le composant est démonté avant la fin de la seconde
    }, []);
    const [evenement, setEvenement] = useState('');
    const [temoignage, setTemoignage] = useState('');
    const [annonce, setannonce] = useState('');
    const [resource, setresource] = useState('');
    const [pending, setPending] = useState(true);

    useEffect(() => {
        axios.get('https://irakoze.burundientempsreel.com/api/inspcountevenement').then((response) => {
            setEvenement(response.data)
            setTimeout(() => {
                setPending(false)
            }, 1000)
        }).catch((err) => {
            console.log(err.message)
        })
        axios.get('https://irakoze.burundientempsreel.com/api/inspcountTemoignage').then((response) => {
            setTemoignage(response.data)
            setTimeout(() => {
                setPending(false)
            }, 1000)
        }).catch((err) => {
            console.log(err.message)
        })
        axios.get('https://irakoze.burundientempsreel.com/api/inspcountannonces').then((response) => {
            setannonce(response.data)
            setTimeout(() => {
                setPending(false)
            }, 1000)
        }).catch((err) => {
            console.log(err.message)
        })
        axios.get('https://irakoze.burundientempsreel.com/api/inspcountresource').then((response) => {
            setresource(response.data)
            setTimeout(() => {
                setPending(false)
            }, 1000)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [])




    return (
        <div className='w-full min-h-[87vh] '>
            <div className='md:text-[40px] sm:text-[30px] strockText mb-5 p-2'>Tableau de bord</div>
            <div className='flex max-sm:flex-1 max-sm:flex-wrap'>
                <div className='sm:w-1/4 w-[95%] max-sm:my-2 mx-2 rounded-md overflow-hidden border border-blue-600 bg-[#0000ff18]'>
                    <div className='text-[20px] p-3 text-blue-600 font-serif'>Evénement</div>
                    <div className='bg-[#0000ff8c] flex-col w-full text-white p-3 flex justify-between'>
                        <div className='w-full flex  justify-between'>
                            <div className='-rotate-[20deg]'>
                                <i class="bi bi-calendar4-event text-[40px]"></i>
                            </div>
                            <div className='text-[3em]'>{evenement.validCount + evenement.invalidCount}</div>
                        </div>
                        <div className='flex justify-between'>
                            <div className=''>
                                <div>{evenement.invalidCount}</div>
                                <i class="bi bi-x-circle-fill text-red-500"></i>
                            </div>
                            <div>
                                <div>{evenement.validCount}</div>
                                <i class="bi bi-check-circle-fill text-green-500"></i>
                            </div>
                        </div>
                    </div>

                </div>



                <div className='sm:w-1/4 w-[95%] max-sm:my-2 mx-2 rounded-md overflow-hidden border border-fuchsia-600 bg-[#ff00ff18]'>
                    <div className='text-[20px] p-3 text-fuchsia-600 font-serif'>Témoignage</div>
                    <div className='bg-[#ff00ff8c] flex-col w-full text-white p-3 flex justify-between'>
                        <div className='w-full flex  justify-between'>
                            <div className='-rotate-[20deg]'>
                                <i class="bi bi bi-badge-tm  text-[40px]"></i>
                            </div>
                            <div className='text-[3em]'>{temoignage.validCount + temoignage.invalidCount}</div>
                        </div>
                        <div className='flex justify-between'>
                            <div className=''>
                                <div>{temoignage.invalidCount}</div>
                                <i class="bi bi-x-circle-fill text-red-500"></i>
                            </div>
                            <div>
                                <div>{temoignage.validCount}</div>
                                <i class="bi bi-check-circle-fill text-green-500"></i>
                            </div>
                        </div>
                    </div>

                </div>



                <div className='sm:w-1/4 w-[95%] max-sm:my-2 mx-2 rounded-md overflow-hidden border border-green-600 bg-[#00800018]'>
                    <div className='text-[20px] p-3 text-green-600 font-serif'>Annonce</div>
                    <div className='bg-[#0080008c] flex-col w-full text-white p-3 flex justify-between'>
                        <div className='w-full flex  justify-between'>
                            <div className='-rotate-[20deg]'>
                                <i class="bi bi-megaphone-fill text-[40px]"></i>
                            </div>
                            <div className='text-[3em]'>{annonce.validCount + annonce.invalidCount}</div>
                        </div>
                        <div className='flex justify-between'>
                            <div className=''>
                                <div>{annonce.invalidCount}</div>
                                <i class="bi bi-x-circle-fill text-red-500"></i>
                            </div>
                            <div>
                                <div>{annonce.validCount}</div>
                                <i class="bi bi-check-circle-fill text-green-500"></i>
                            </div>
                        </div>
                    </div>

                </div>


                <div className='sm:w-1/4 w-[95%] max-sm:my-2 mx-2 rounded-md overflow-hidden border border-orange-600 bg-[#ffa60018]'>
                    <div className='text-[20px] p-3 text-orange-600 font-serif'>Ressource</div>
                    <div className='bg-[#ffa6008c] flex-col w-full text-white p-3 flex justify-between'>
                        <div className='w-full flex  justify-between'>
                            <div className='-rotate-[20deg]'>
                                <i class="bi bi-megaphone-fill text-[40px]"></i>
                            </div>
                            <div className='text-[3em]'>{resource.validCount + resource.invalidCount}</div>
                        </div>
                        <div className='flex justify-between'>
                            <div className=''>
                                <div>{resource.invalidCount}</div>
                                <i class="bi bi-x-circle-fill text-red-500"></i>
                            </div>
                            <div>
                                <div>{resource.validCount}</div>
                                <i class="bi bi-check-circle-fill text-green-500"></i>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default AcceuiInspect

