/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

function FooterVisitor() {
    return (
        <div className='w-full flex flex-col justify-center items-center  bg-white'>
            <svg className=" relative  " preserveAspectRatio="none" viewBox="0 0 100 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M0,10  C20,35 40,5 60,15 C80,25 90.5,0 100,15 L100,30 L0,30 Z" fill="orange"></path>
            </svg>
            <div className="bg-[orange] w-full h-max p-2 flex justify-around items-center z-10 font-medium responsive3">
                <div className="flex flex-col  p-2 items-start leading-[1.6em] max-w rounded responsive31">
                    <h2 className='font-bold font-sans underline cursor-default'>Contactez-nous</h2>
                    <button className='flex alignes w-full p-1 transition-all rounded '><b className='mr-2 text-left'>Email: </b>
                        <div className=""></div>
                        <a href="mailto:info@excellencengagaraschool.com" target='_blank' className=' text-left hover:underline text-blue-500 hover:text-blue-600'>info@excellencengagaraschool.com</a>
                    </button>


                    <button className=' alignes w-full p-1 transition-all rounded hidden'><b className='mr-2  text-left'>FaceBook: </b>
                        <div className=""></div>
                        <a href="#" target='_blank' className=' text-left hover:underline text-blue-500 hover:text-blue-600'>Lycée d'Exellence NGAGAR</a>

                    </button>

                </div>
            </div>
            <div className=" flexCopyRign p-4 bg-[rgba(10,1,67,0.93)] flex  w-full text-center justify-center items-center text-white">
                <div className="">Copy right ,developpée par la sociéte</div> <div className="w-[40px]  p-1 h-[40px] mx-2  border-4  border-double border-orange-600 rounded-full"><img src="https://irakoze.burundientempsreel.com/uploads/Photo/btr.png" alt="" className='w-full h-full object-cover' /></div> <div className="">Burundi en temps réel</div>
            </div>
        </div>
    )
}

export default FooterVisitor
