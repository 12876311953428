import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { encryptData, decryptData } from "../../../../encryptionModule";

function EditerEvent() {
    const { id } = useParams();
    const bId = decryptData(id);
    const history = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [eventData, setEventData] = useState({
        titre: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isFormValid = eventData.titre.trim() !== '';

        if (!isFormValid) {
            toast.error('Veuillez remplir le champ titre obligatoire.');
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await axios.put(`https://irakoze.burundientempsreel.com/api/updateEventTitle/${bId}`, eventData);
            toast.success(response.data);
            history(`/AdminEvent/Evenement/Detail/${bId}`);
        } catch (error) {
            console.error(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        axios.get(`https://irakoze.burundientempsreel.com/api/geteventById/${bId}`)
            .then((response) => {
                setEventData(response.data);
            })
            .catch((error) => {
                console.log(error.message);
            });
    }, [id]);

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="max-w-md w-full mx-auto  border border-fuchsia-600 rounded-md shadow-md overflow-hidden">
                <div className="p-6">
                    <Link to={`/AdminEvent/Evenement/Detail/${bId}`} className="text-blue-500 text-sm font-semibold mb-4">Retour</Link>
                    <h5 className="text-lg font-semibold mb-4">Éditer événement</h5>
                    <form onSubmit={handleSubmit}>
                        <div class="w-full mb-5">
                            <label
                                class="block  tracking-wide  text-lg  mb-1"
                                for="grid-Title"
                            >
                                Titre
                            </label>
                            <textarea
                                placeholder="Titre"
                                class={`block w-full bg-transparent h-[11em]  resize-none outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3`}

                                value={eventData.titre}
                                onChange={(e) => setEventData({ ...eventData, titre: e.target.value })}
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <svg
                                    className="animate-spin h-5 w-5 mr-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014.707 15H2v2h4v-4H4.707zM19.293 9H22V7h-4v4h2.293A7.962 7.962 0 0116 14.291V10h3.293zM20 12a8 8 0 01-8 8v-4c3.728 0 6.824-2.544 7.732-6H20zm-8-6v4H8c0-3.728 2.544-6.824 6-7.732V6H12z"
                                    ></path>
                                </svg>
                            ) : (
                                'Enregistrer'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditerEvent;