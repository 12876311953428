

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Radio, RadioGroup } from 'rsuite';
import axios from 'axios';
import { FadeLoader } from 'react-spinners'
import { Link, useNavigate } from "react-router-dom";

function RegisterAdmin({ getSizeMobille }) {
    const history = useNavigate()
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])




    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    const [eledemmade, GeteleDemmande] = useState([])

    const [boutLoading, setboutLoading] = useState(false)
    const [radioValue, setRadioValue] = useState('');

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])






    const [blocs, setBlocs] = useState([
        { nom_complet: '', numer_tele: '', email: '', passWorld: '', confirmePasd: '', genre: '', profil: '', role: '' }
    ]);


    const addBloc = () => {
        setBlocs([...blocs, { nom_complet: '', numer_tele: '', email: '', passWorld: '', confirmePasd: '', genre: '', profil: '' }]);
    };
    const removeBloc = (index) => {
        const newBlocs = [...blocs];
        newBlocs.splice(index, 1);
        setBlocs(newBlocs);
    };
    const inputRefs = useRef([]);
    if (inputRefs.current.length !== blocs.length) {

        inputRefs.current = Array(blocs.length).fill({}).map((_, index) => ({
            nom_complet: createRef(null),
            numer_tele: createRef(null),
            email: createRef(null),
            passWorld: createRef(null),
            confirmePasd: createRef(null),
            genre: createRef(null),
            profil: createRef(null),
            role: createRef(null),

        }));

    }


    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;


    const handleInputChange = (index, field, value, isFile = false) => {
        const newBlocs = [...blocs];

        newBlocs[index][field] = value;

        if (isFile) {
            if (value && value.length > 0) {
                const file = value[0];
                newBlocs[index] = { ...newBlocs[index], [field]: file };
            } else {
                delete newBlocs[index][field];
            }
        } else {
            newBlocs[index] = { ...newBlocs[index], [field]: value };
        }

        setBlocs(newBlocs);
        if (value && inputRefs.current[index][field].current) {
            inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
        }
    };


    const animateAndScrollToRef = (ref) => {
        if (ref && ref.current) {
            ref.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            setTimeout(() => {
                ref.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            }, 5000);
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            ref.current.focus();
        }
    };


    const validateBloc = (bloc, index) => {
        const requiredFields = ['nom_complet', 'numer_tele', 'email', 'passWorld', 'confirmePasd', 'genre', 'profil'];

        //Validation pour les champs vide
        for (const field of requiredFields) {
            if (!bloc[field]) {
                toast.warning(
                    <div>Le <strong>
                        {field == 'nom_complet' ? 'nom complet d\'Utilisateur' :
                            field == 'numer_tele' ? 'numéro de téléphone' :
                                field == 'email' ? 'email d\'utisateur' :
                                    field == 'passWorld' ? 'mot de passe d\'utisateur' :
                                        field == 'confirmePasd' ? 'confirmation de mot de passe d\'utisateur ' :
                                            field == 'genre' ? 'genre de l\'utisateur' :
                                                field == 'profil' ? 'photos de profil d\'utisateur' :
                                                    null}</strong> est obligatoire de l'Utilisateur numero {index + 1}.</div>);
                if (inputRefs.current[index][field].current) {
                    inputRefs.current[index][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    setTimeout(() => {
                        inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    }, 3000);
                    inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    inputRefs.current[index][field].current.focus();
                    return false;
                }
            }
        }


        //Validation pour eviter de sousmettre deux block de meme information

        const isdepluqueNom = blocs.some((b, i) => i !== index && b.nom_complet === bloc.nom_complet);
        const isdepluqueEmail = blocs.some((b, i) => i !== index && b.email === bloc.email);

        if (isdepluqueNom) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.nom_complet === bloc.nom_complet);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div>
                    Vous avez saisie le même nom de  <b className="font-bold">{bloc.nom_complet}</b> à la block de <b className="font-bold"> {duplicateBlockNumber}</b> et  <b>{toRoman(index + 1)}</b>.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].nom_complet);
            return false;
        }

        if (isdepluqueEmail) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.email === bloc.email);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div>
                    Vous avez saisie le même email de  <b className="font-bold">{bloc.email}</b> à la block de <b className="font-bold"> {duplicateBlockNumber}</b> et  <b>{toRoman(index + 1)}</b>.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].email);
            return false;
        }

        // Validation de l'email avec l'expression régulière
        if (!emailRegex.test(bloc.email)) {
            toast.warning(
                <div>
                    L'email de <b>{bloc.nom_complet}</b> est incorrect. Veuillez réessayer.
                </div>

            );
            animateAndScrollToRef(inputRefs.current[index].email);
            return false;
        }

        // Validation du mot de passe avec l'expression régulière
        if (!PasswordRegex.test(bloc.passWorld)) {
            toast.warning(
                <div>
                    Le mot de passe de <b>{bloc.nom_complet}</b>  doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].passWorld);
            return false;
        }

        // Comparaison des champs de mot de passe et de confirmation de mot de passe
        if (bloc.passWorld !== bloc.confirmePasd) {
            toast.warning(
                <>
                    La confirmation du mot de passe ne correspond pas au mot de passe saisi "<b>{bloc.passWorld}</b>".
                </>
            );
            animateAndScrollToRef(inputRefs.current[index].confirmePasd);
            return false;
        }
        if (!bloc.role) {
            toast.warning("Veuillez sélectionner un rôle pour l'Utilisateur.", { autoClose: 3000 });
            return false;
        }
        return true;
    };

    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }

    const [visiblePassword, SetvisiblePassword] = useState(false)

    const formData = new FormData();
    blocs.forEach((bloc, index) => {
        formData.append(`user[${index}][nom_complet]`, bloc.nom_complet);
        formData.append(`user[${index}][tel]`, bloc.numer_tele);
        formData.append(`user[${index}][email]`, bloc.email);
        formData.append(`user[${index}][password]`, bloc.passWorld);
        formData.append(`user[${index}][genre]`, bloc.genre);
        formData.append(`user[${index}][role]`, bloc.role);
        if (bloc.profil) {
            formData.append(`user[${index}][photo]`, bloc.profil);
        }

    });
    const handleSubmit = (e) => {
        e.preventDefault();
        for (let index = 0; index < blocs.length; index++) {
            if (!validateBloc(blocs[index], index)) {
                return false;
            }
        }
        setboutLoading(true)

        axios.post('https://irakoze.burundientempsreel.com/api/users/register', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
            if (response.data.error) {
                toast.warning(response.data.error, {
                    autoClose: 7000
                });
            } else {
                toast.success(response.data)
                history('/');
            }
        }).catch((error) => {
            console.log("Error message:", error.message);
            if (error.response) {
                toast.warning(error.response.data.error, {
                    autoClose: 2000
                });

            }
        }).finally(() => {
            setboutLoading(false);

        });


    }

    return (

        <div className={`overflow-hidden `}>
            <div className="sm:p-4 p-2">
                <div className='w-full px-5 rounded-b-lg flex justify-end border-b border-gray-300 flex-row'>
                    <Link to='/' className='bg-orange-500 text-white hover:no-underline focus:no-underline hover:text-white focus:text-white my-2 text-[13px] sm:text-[17px] rounded h-max w-max px-2 py-1'>
                        Aller à la page d'acceuil
                    </Link>
                </div>
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <div
                                class="sm:text-2xl max-sm:text-center font-bold  "
                            >
                                Enregistrement des Utilisateurs
                            </div>
                        </p>

                    </div>
                </div>

                <div className="w-full my-4 grid grid-cols-1">

                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">
                                {blocs.map((bloc, index) => (
                                    <div key={index} className="justify-center overflow-hidden rounded-xl w-full h-max p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                        <div className="w-full">
                                            <div className="h-max w-full">
                                                <div className="px-3  w-full">
                                                    <div className="w-full flex items_center justify-between">
                                                        <label className="block  mt-5 font-serif text-gray-500 first_letter:uppercase tracking_wide  text-lg  mb-1">
                                                            {toRoman(index + 1)}. Utilisateurs
                                                        </label>
                                                        {index > 0 && (
                                                            <button
                                                                montant="button"
                                                                className="text-red-500 px-1 max-sm:text-xs py-2"
                                                                onClick={() => removeBloc(index)}
                                                            >
                                                                Supprimer
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2 h-max">
                                            <div className="rounded-xl p-2 w-full">
                                                <div className="flex flex-col">
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label
                                                                class="block  tracking-wide  text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Nom complet d'Utilisateur
                                                            </label>
                                                            <input
                                                                ref={inputRefs.current[index].nom_complet}
                                                                class="block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.nom_complet}
                                                                placeholder=" Le nom complet d'Utilisateur"
                                                                onChange={(e) => handleInputChange(index, "nom_complet", e.target.value)}

                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label
                                                                class="block  tracking-wide  text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Numéro de téléphone
                                                            </label>
                                                            <input
                                                                placeholder="Numéro de téléphone"
                                                                ref={inputRefs.current[index].numer_tele}
                                                                class="block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.numer_tele}
                                                                onChange={(e) => handleInputChange(index, "numer_tele", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide  text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Email
                                                            </label>
                                                            <input
                                                                ref={inputRefs.current[index].email}
                                                                class=" block bg-transparent  outline-none  focus:border focus:border-fuchsia-500  w-full   border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.email}
                                                                onInput={(e) => handleInputChange(index, "email", e.target.value)}
                                                                placeholder="Email..."

                                                            />


                                                        </div>
                                                    </div>


                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide  text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Mot de passe
                                                            </label>
                                                            <div className=" border-none  relative flex">
                                                                <input
                                                                    ref={inputRefs.current[index].passWorld}
                                                                    class=" block bg-transparent  outline-none pr-8  focus:border focus:border-fuchsia-500  w-full   border border-red rounded py-3 px-4 mb-3"
                                                                    value={bloc.passWorld}
                                                                    type={visiblePassword ? 'text' : 'password'}
                                                                    onChange={(e) => handleInputChange(index, "passWorld", e.target.value)}
                                                                    placeholder="Mot de passe..."

                                                                />
                                                                <div className="w-6 cursor-pointer hover:bg-gray-400 rounded-md right-1 top-3 h-6 flex justify-center items-center absolute">
                                                                    {visiblePassword ? (
                                                                        <svg onClick={() => SetvisiblePassword(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill   bg-transparent w-full h-full absolute" viewBox="0 0 16 16">
                                                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                                                        </svg>

                                                                    ) : (
                                                                        <svg onClick={() => SetvisiblePassword(true)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill  bg-transparent w-full h-full absolute" viewBox="0 0 16 16">
                                                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                                                        </svg>
                                                                    )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide  text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Confirmez le mot de passe
                                                            </label>

                                                            <div className=" border-none  relative flex">
                                                                <input
                                                                    class=" block bg-transparent  outline-none pr-8  focus:border focus:border-fuchsia-500  w-full   border border-red rounded py-3 px-4 mb-3"
                                                                    ref={inputRefs.current[index].confirmePasd}
                                                                    type={visiblePassword ? 'text' : 'password'}
                                                                    value={bloc.confirmePasd}
                                                                    onChange={(e) => handleInputChange(index, "confirmePasd", e.target.value)}
                                                                    placeholder="Confirmez le mot de passe"

                                                                />
                                                                <div className="w-6 cursor-pointer hover:bg-gray-400 rounded-md right-1 top-3 h-6 flex justify-center items-center absolute">
                                                                    {visiblePassword ? (
                                                                        <svg onClick={() => SetvisiblePassword(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill   bg-transparent w-full h-full absolute" viewBox="0 0 16 16">
                                                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                                                        </svg>

                                                                    ) : (
                                                                        <svg onClick={() => SetvisiblePassword(true)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill  bg-transparent w-full h-full absolute" viewBox="0 0 16 16">
                                                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                                                        </svg>
                                                                    )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="inline-flex gap-10 responsivLogin ">
                                                        <p className="inline-flex items-center text-[18px] "> Votre genre:</p>
                                                        <div className="genre relative">
                                                            <RadioGroup inline name="radioList" onChange={(value) => handleInputChange(index, "genre", value)}                                                                     >
                                                                <Radio value="Masculin">Masculin</Radio>
                                                                <Radio value="Féminin">Féminin</Radio>
                                                            </RadioGroup>
                                                        </div>
                                                    </div>
                                                    <div className="relative">
                                                        <div className="inline-flex items-center mr-4 text-[18px] ">Profil:</div>
                                                        <label htmlFor={`profils-${index}`} className='transition-all p-1 bg-orange-400 hover:bg-orange-600 rounded cursor-pointer text-white'>Selection le profile</label>
                                                        <input type="file" id={`profils-${index}`} name='photo' hidden accept='image/*'
                                                            onChange={(e) => handleInputChange(index, 'profil', e.target.files, true)}
                                                            ref={inputRefs.current[index].profil}
                                                        />

                                                        <div className={`animeBorder p-1 transition-all relative  top-4 left-20 rounded border-4 border-dotted border-orange-500 w-[100px] ${bloc.profil == '' ? 'h-[0px] opacity-0 pointer-events-none' : 'h-[100px] opacity-100 pointer-events-none'}`}>
                                                            {bloc.profil && (<img src={URL.createObjectURL(bloc.profil)} alt="" className='w-full h-full rounded object-cover' />)}

                                                        </div>
                                                    </div>
                                                    <div className="relative">
                                                        <div className="inline-flex items-center mr-4 text-[18px] ">Rôle:</div>
                                                        <select
                                                            className="border border-gray-300 rounded p-1"
                                                            value={bloc.role}
                                                            onChange={(e) => handleInputChange(index, "role", e.target.value)}
                                                        >
                                                            <option value="">Sélectionner un rôle</option>
                                                            <option value="1">Admin</option>
                                                            <option value="2">Inspecteur</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="w-full flex justify-end">
                                    <button montant="button" className="bg-blue-500 hover-bg-blue-700 text-white py-1 px-2  max-sm:text-xs sm:py-2 sm:px-4 rounded" onClick={addBloc}>
                                        Ajouter un Utilisateur
                                    </button>
                                </div>
                            </div>

                            <div className=" flex justify-end  md:w-[40em] w-[95%]">

                                {boutLoading ? (
                                    <>
                                        <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Soumettre' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (<>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Soumettre' className='cursor-pointer'></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </>)}

                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    );
}


export default RegisterAdmin;

