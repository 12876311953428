import React from 'react'
import StandBuyAdmin from './StandBuyAdmin'
function StandBuyAdminComponent() {
    return (
        <>
            <StandBuyAdmin />
        </>

    )
}

export default StandBuyAdminComponent
