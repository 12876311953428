/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
import React, { createRef, useEffect, useRef } from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderBottom: '2px solid gray',
        borderRadius: '5px',
        overflow: 'hidden',
        backgroundColor: "transparent",
        top: '7px',
        with: '100%',
        color: 'red',
        boxShadow: state.isFocused ? '0 0 0 2px #ddd' : 'none',
        borderBottom: state.isFocused ? '2px solid blue' : '2px solid gray',
        '&:hover': {
            borderBottom: state.isFocused ? '2px solid blue' : '2px solid orange',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'orange' : 'transparent',
        color: state.isSelected ? 'white' : '#666',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
        position: 'static',
        zIndex: '10000',
    }),
};




function AjouterMembres({ getSizeMobille }) {
    const [fonctions, setFonctions] = useState([]);

    useEffect(() => {
        // Appel de l'API pour récupérer les noms de fonction
        axios.get('https://irakoze.burundientempsreel.com/api/fonctions')
            .then(response => {
                // Mappage des noms de fonction dans le bon format pour react-select
                const mappedFonctions = response.data.map(fonction => ({
                    value: fonction.nom,
                    label: fonction.nom
                }));
                setFonctions(mappedFonctions);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des fonctions :', error);
            });
    }, []);
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])
    const History = useNavigate()


    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    const [boutLoading, setboutLoading] = useState(false)


    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])


    const [blocs, setBlocs] = useState([
        { nom_complet: '', numer_tele: '', email: '', fonct: '', profil: '' }
    ]);

    const addBloc = () => {
        setBlocs([...blocs, { nom_complet: '', numer_tele: '', email: '', fonct: '', profil: '' }]);
    };
    const removeBloc = (index) => {
        const newBlocs = [...blocs];
        newBlocs.splice(index, 1);
        setBlocs(newBlocs);
    };
    const inputRefs = useRef([]);
    if (inputRefs.current.length !== blocs.length) {

        inputRefs.current = Array(blocs.length).fill({}).map((_, index) => ({
            nom_complet: createRef(null),
            numer_tele: createRef(null),
            email: createRef(null),
            fonct: createRef(null),
            profil: createRef(null),

        }));

    }

    const handleInputChange = (index, field, value, isFile = false) => {
        const newBlocs = [...blocs];
        newBlocs[index][field] = value;
        if (isFile) {
            if (value && value.length > 0) {
                const file = value[0];
                newBlocs[index] = { ...newBlocs[index], [field]: file };
            } else {
                delete newBlocs[index][field];
            }
        } else {
            newBlocs[index] = { ...newBlocs[index], [field]: value };
        }

        setBlocs(newBlocs);
        if (value && inputRefs.current[index][field].current) {
            inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
        }
    };


    const animateAndScrollToRef = (ref) => {
        if (ref && ref.current) {
            ref.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            setTimeout(() => {
                ref.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            }, 5000);
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            ref.current.focus();
        }
    };


    const validateBloc = (bloc, index) => {
        const requiredFields = ['nom_complet', 'numer_tele', 'email', 'fonct', 'profil'];

        //Validation pour les champs vide
        for (const field of requiredFields) {
            if (!bloc[field]) {
                toast.warning(
                    <div>Le <strong>
                        {field == 'nom_complet' ? 'nom complet d\'un membre' :
                            field == 'numer_tele' ? 'numéro de téléphone' :
                                field == 'email' ? 'email d\'un membre' :
                                    field == 'fonct' ? 'fonction du membre' :
                                        field == 'profil' ? 'photos de profil d\'un membre' :
                                            null}</strong> est obligatoire de l'utilisateur numero {index + 1}.</div>);

                if (inputRefs.current[index][field].current) {
                    inputRefs.current[index][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    setTimeout(() => {
                        inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    }, 3000);
                    inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    inputRefs.current[index][field].current.focus();
                    return false;
                }
            }
        }


        //Validation pour eviter de sousmettre deux block de meme information
        const isdepluqueNom = blocs.some((b, i) => i !== index && b.nom_complet === bloc.nom_complet);
        const isdepluqueEmail = blocs.some((b, i) => i !== index && b.email === bloc.email);
        const isdepluqueProfil = blocs.some((b, i) => i !== index && b.profil === bloc.profil);

        if (isdepluqueNom) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.nom_complet === bloc.nom_complet);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div>
                    Vous avez saisie le même nom de  <b className="font-bold">{bloc.nom_complet}</b> à la block de <b className="font-bold"> {duplicateBlockNumber}</b> et  <b>{toRoman(index + 1)}</b>.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].nom_complet);
            return false;
        }

        if (isdepluqueEmail) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.email === bloc.email);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div>
                    Vous avez saisie le même email de  <b className="font-bold">{bloc.email}</b> à la block de <b className="font-bold"> {duplicateBlockNumber}</b> et  <b>{toRoman(index + 1)}</b>.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].email);
            return false;
        }


        if (isdepluqueProfil) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.profil === bloc.profil);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div>
                    Vous avez utilise  le même email de  <b className="font-bold">photo de profil</b> de <b>{bloc.nom_complet}</b> en block <b className="font-bold"> {duplicateBlockNumber}</b> et  <b>{toRoman(index + 1)}</b>.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].email);
            return false;
        }


        const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
        // Validation de l'email avec l'expression régulière
        if (!emailRegex.test(bloc.email)) {
            toast.warning(
                <div>
                    L'email de <b>{bloc.nom_complet}</b> est incorrect. Veuillez réessayer.
                </div>

            );
            animateAndScrollToRef(inputRefs.current[index].email);
            return false;
        }

        return true;
    };

    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }




    const formData = new FormData();
    blocs.forEach((bloc, index) => {
        formData.append(`[${index}]nom_complet`, bloc.nom_complet);
        formData.append(`[${index}]tel`, bloc.numer_tele);
        formData.append(`[${index}]email`, bloc.email);
        formData.append(`[${index}]fonction`, bloc.fonct);
        formData.append(`[${index}]file`, bloc.profil);

    });


    const handleSubmit = (e) => {
        e.preventDefault();
        for (let index = 0; index < blocs.length; index++) {
            if (!validateBloc(blocs[index], index)) {
                return false;
            }
        }

        axios.post(`https://irakoze.burundientempsreel.com/api/addmember`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                toast.success('Enregistrement effectué avec succès');
                History('/AdminMembers/Members');
            })
            .catch((error) => {
                toast.error('Erreur lors de la configuration de la requête');

            });
    };

    const [mobiles, SetMbiles] = useState(window.innerWidth < 400)

    useEffect(() => {
        const HundleSize = () => {
            SetMbiles(window.innerWidth < 400)
        }

        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])


    return (

        <div className={``}>
            <div className="sm:p-4 p-2 min-h-screen">
                <div className='w-full px-1 sm:px-5 rounded-b-lg flex items-center justify-between border-b border-gray-300 flex-row'>
                    <div className={`my-2 ${mobiles ? ' text-[13px] hidden' : ' text-[17px]'} rounded h-max w-max px-2 py-1`}>
                        Enregistrement des membres
                    </div>
                    <Link to='/AdminMembers/Members' className={`bg-orange-500 hover:text-white hover:no-underline text-white focus:text-white text-[12px] focus:no-underline sm:text-[17px] my-2 ${mobiles ? ' text-[13px]' : ' text-[17px]'} rounded h-max w-max px-2 py-1`}>
                        Gere les membres
                    </Link>
                </div>
                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">
                                {blocs.map((bloc, index) => (
                                    <div key={index} className="justify-center rounded-xl w-full h-max p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                        <div className="w-full">
                                            <div className="h-max w-full">
                                                <div className="px-3  w-full">
                                                    <div className="w-full flex items_center justify-between">
                                                        <label className="block  mt-5 font-serif text-gray-500 first_letter:uppercase   text-lg  mb-1">
                                                            {toRoman(index + 1)}. membres
                                                        </label>
                                                        {index > 0 && (
                                                            <button
                                                                montant="button"
                                                                className="text-red-500 px-1 max-sm:text-xs py-2"
                                                                onClick={() => removeBloc(index)}
                                                            >
                                                                Supprimer
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2 h-max">
                                            <div className="rounded-xl p-2 w-full">
                                                <div className="flex flex-col">
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label
                                                                class="block  tracking-wide  text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Nom complet d'un membre
                                                            </label>
                                                            <input
                                                                ref={inputRefs.current[index].nom_complet}
                                                                class="block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.nom_complet}
                                                                placeholder=" Le nom complet d'utilisateur"
                                                                onChange={(e) => handleInputChange(index, "nom_complet", e.target.value)}

                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label
                                                                class="block  tracking-wide  text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Numéro de téléphone
                                                            </label>
                                                            <input
                                                                placeholder="Numéro de téléphone"
                                                                ref={inputRefs.current[index].numer_tele}
                                                                class="block w-full bg-transparent  outline-none  focus:border focus:border-fuchsia-500   border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.numer_tele}
                                                                onChange={(e) => handleInputChange(index, "numer_tele", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide  text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Email
                                                            </label>
                                                            <input
                                                                ref={inputRefs.current[index].email}
                                                                class=" block bg-transparent  outline-none  focus:border focus:border-fuchsia-500  w-full   border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.email}
                                                                onInput={(e) => handleInputChange(index, "email", e.target.value)}
                                                                placeholder="Email..."

                                                            />


                                                        </div>
                                                    </div>
                                                    <div className={`flex w-full  mb-5 text-red-700   ${mobile ? 'flex-col  items-start' : 'justify-between items-center'}   my-3`}>
                                                        <div ref={inputRefs.current[index].fonct} className={`flex flex-col h-11 w-full relative border-b-2  `}>
                                                            <Select
                                                                onChange={(e) => handleInputChange(index, 'fonct', e.value)}
                                                                options={fonctions}
                                                                defaultValue={bloc.fonct}
                                                                styles={customStyles}
                                                            />





                                                        </div>
                                                    </div>
                                                    <div className="relative">
                                                        <div className="inline-flex items-center mr-4 text-[18px] ">Profil:</div>
                                                        <label htmlFor={`profils-${index}`} className='transition-all p-1 bg-orange-400 hover:bg-orange-600 rounded cursor-pointer text-white'>Selection le profile</label>
                                                        <input type="file" id={`profils-${index}`} name='photo' hidden accept='image/*'
                                                            onChange={(e) => handleInputChange(index, 'profil', e.target.files, true)}
                                                            ref={inputRefs.current[index].profil}
                                                        />

                                                        <div className={`animeBorder p-1 transition-all relative  top-4 left-20 rounded border-4 border-dotted border-orange-500 w-[100px] ${bloc.profil == '' ? 'h-[0px] opacity-0 pointer-events-none' : 'h-[100px] opacity-100 pointer-events-none'}`}>
                                                            {bloc.profil && (<img src={URL.createObjectURL(bloc.profil)} alt="" className='w-full h-full rounded object-cover' />)}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="w-full flex justify-end">
                                    <button montant="button" className="bg-blue-500 hover-bg-blue-700 text-white py-1 px-2  max-sm:text-xs sm:py-2 sm:px-4 rounded" onClick={addBloc}>
                                        Ajouter un membre
                                    </button>
                                </div>
                            </div>

                            <div className=" flex justify-end  md:w-[40em] w-[95%]">

                                {boutLoading ? (
                                    <>
                                        <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Soumettre' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (<>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Soumettre' className='cursor-pointer'></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </>)}

                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    );
}
export default AjouterMembres;


