import React from 'react'
import Temoignage from './Temoignage'

function TemoignageComponent() {
    return (
        <>
            <Temoignage />
        </>
    )
}

export default TemoignageComponent
