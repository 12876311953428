import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Navbar, Sidenav } from 'rsuite'

function NavBarsContact() {
    const { pathname } = useLocation()
    let AdminContact = /^\/AdminContact\/Contact.*/
    let AdminContactReseauxSoci = /^\/AdminContact\/SocialContact.*/
    let AdminContactSuggestion = /^\/AdminContact\/SuggestionContact.*/

    const [mobile, SetMobile] = useState(window.innerWidth < 543);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 543)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])
    return (
        <div className='w-full  flex justify-around bg-inherit items-end border sticky top-0 z-10'>
            <Navbar className='flex justify-between  w-full'>
                {AdminContact.test(pathname) ? (
                    <Link to='/AdminContact/Contact' className='mx-2   p-1 w-max    bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                        <i class={`bi bi-geo-alt ${mobile ? ' w-[40px] flex justify-center items-center h-[30px]' : 'mr-4'} text-[20px] `}></i>
                        <p className={`sizeText ${mobile ? 'hidden' : 'block'}`}>Coordonner physique</p>
                    </Link>
                ) : (
                    <Link to='/AdminContact/Contact' className='mx-2   p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                        <i class={`bi bi-geo-alt ${mobile ? ' w-[40px] flex justify-center items-center h-[30px]' : 'mr-4'} text-[20px] `}></i>
                        <p className={`sizeText ${mobile ? 'hidden' : 'block'}`}>Coordonner physique</p>
                    </Link>
                )}
                {AdminContactReseauxSoci.test(pathname) ? (
                    <Link to='/AdminContact/SocialContact' className='mx-2   p-1 w-max    bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                        <i class={` bi bi-badge-4k  ${mobile ? ' w-[40px] flex justify-center items-center h-[30px]' : 'mr-4'} text-[20px] `}></i>
                        <p className={`sizeText  ${mobile ? 'hidden' : 'block'}`}>Réseaux sociaux</p>
                    </Link>
                ) : (
                    <Link to='/AdminContact/SocialContact' className='mx-2  p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                        <i class={` bi bi-badge-4k  ${mobile ? ' w-[40px] flex justify-center items-center h-[30px]' : 'mr-4'} text-[20px] `}></i>
                        <p className={`sizeText  ${mobile ? 'hidden' : 'block'}`}>Réseaux sociaux</p>
                    </Link>
                )}
                {AdminContactSuggestion.test(pathname) ? (
                    <Link to='/AdminContact/SuggestionContact' className='mx-2   p-1 w-max    bg-orange-600  text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                        <i class={`bi bi-emoji-frown ${mobile ? ' w-[40px] flex justify-center items-center h-[30px]' : 'mr-4'}`}></i>
                        <p className={`sizeText  ${mobile ? 'hidden' : 'block'}`}>Suggestion</p>
                    </Link>
                ) : (
                    <Link to='/AdminContact/SuggestionContact' className='mx-2  p-1 w-max hover:border-b hover:border-blue-500  hover:bg-orange-400 hover:text-white rounded-se-lg  rounded-tl-lg pb-2    transition-all  flex  justify-center items-center'>
                        <i class={`bi bi-emoji-frown ${mobile ? ' w-[40px] flex justify-center items-center h-[30px]' : 'mr-4'}`}></i>
                        <p className={`sizeText  ${mobile ? 'hidden' : 'block'}`}>Suggestion</p>
                    </Link>
                )}
            </Navbar>
        </div>
    )
}

export default NavBarsContact
